import BaseHistoryRecordModel from '../BaseHistoryRecordModel';
import { HistoryRecordDto } from '../../services/types';

export default class FormTypeHistoryModel extends BaseHistoryRecordModel {
    constructor(data: HistoryRecordDto) {
        super(data);
    }

    get objectLabel() {
        return 'Form Type';
    }
}

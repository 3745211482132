import { Button } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import { Observer } from 'mobx-react';
import React from 'react';
import { RulePreviewVisualStore } from '../../stores';
import ProjectPackageFilter from '../ProjectPackageFilter';
import { observer } from 'mobx-react-lite';

interface PackagePreviewRunControlsProps {
    store: RulePreviewVisualStore;
}

const PackagePreviewRunControls: React.FC<PackagePreviewRunControlsProps> = ({ store }) => {
    return (
        <Sider
            id="rule-preview-package-filter-container"
            data-id="rule-preview-package-filter-container"
            width={'100%'}
            style={{ height: 'calc(100% - 82px)' }}
        >
            <ProjectPackageFilter
                RulePreviewUI={store}
                style={{ background: 'white', overflow: 'auto', height: '100%', paddingLeft: 15 }}
            />
            <div className="rule-preview-run-container">
                <Observer>
                    {() => (
                        <Button
                            data-id="rule-preview-run-button"
                            type="primary"
                            size="large"
                            disabled={!store!.checkedPackages.length || store!.isExecuting}
                            onClick={() => store!.execute(store.rule)}
                        >
                            Run test
                        </Button>
                    )}
                </Observer>
            </div>
        </Sider>
    );
};

export default observer(PackagePreviewRunControls);

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Upload } from 'antd';
import security from '../../../common/services/SecurityService';
import PromptTemplateImportExportStore from '../../stores/PromptTemplateImportExportStore';
import './PromptTemplateUploader.less';

interface Props {
    importExportStore: PromptTemplateImportExportStore;
}

const PromptTemplateUploader: React.FC<Props> = ({ importExportStore }) => {
    if (!importExportStore.project) {
        return null;
    }

    return (
        <>
            <Upload
                name="file"
                className="headerButton prompt-template-uploader"
                maxCount={1}
                headers={{ Authorization: `Bearer ${security.token}` }}
                action={importExportStore.importAction}
                onChange={importExportStore.onImportChange}
            >
                <div data-id="button-Import">
                    <i className="alpha-icon xs arrow-down" />
                    <span>Import</span>
                </div>
            </Upload>
        </>
    );
};

export default observer(PromptTemplateUploader);

import appClient from '../../common/services/AppClient';
import { PromptTemplate } from '../types';
import UpdatePromptTemplateRequest from '../types/requests/UpdatePromptTemplateRequest';
import security from '../../common/services/SecurityService';
import { Utils } from '../../common/services/Utils';
import { PackageResult } from '../../common/types';

export default class PromptTemplateService {
    async getPromptTemplates(projectId: string) {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/prompt-templates`;

        const resp = await appClient.get<PromptTemplate[]>(url);

        return resp.unwrapOr([]);
    }

    async createPromptTemplate(
        data: Omit<PromptTemplate, 'id' | 'createdTime' | 'updatedTime' | 'createdBy' | 'updatedBy'>
    ) {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${data.projectId}/prompt-templates`;
        return appClient.post<PromptTemplate>(url, data);
    }

    async updatePromptTemplate(projectId: string, templateId: string, data: UpdatePromptTemplateRequest) {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/prompt-templates/${templateId}`;
        return appClient.update<PromptTemplate>(url, data);
    }

    async getPromptTemplate(projectId: string, id: string) {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/prompt-templates/${id}`;
        const response = await appClient.get<PromptTemplate>(url);
        return response.unwrapOr({} as PromptTemplate);
    }

    async deletePromptTemplate(projectId: string, id: string) {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/prompt-templates/${id}`;
        return appClient.delete(url);
    }

    async duplicateTemplate(projectId: string, templateId: string, newName: string): Promise<PromptTemplate | null> {
        try {
            const response = await appClient.post<PromptTemplate | null>(
                process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/prompt-templates/${templateId}/duplicate`,
                { newName }
            );
            return response.unwrapOr(null);
        } catch (error) {
            console.error('Error duplicating prompt template:', error);
            throw error;
        }
    }

    async exportPromptTemplate(projectId: string, templateId: string) {
        const mapForm = document.createElement('form');

        mapForm.setAttribute('id', 'exportPromptTemplateForm');
        mapForm.target = '_blank';
        mapForm.method = 'POST';

        await security.invoke(token => {
            const t = '?access_token=' + encodeURIComponent(token);
            const url =
                process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/prompt-templates/${templateId}/export${t}`;
            Utils.downloadFile(url, mapForm, 'exportPromptTemplateForm');
            return Promise.resolve();
        });
    }

    async getDependentPackages(projectId: string, templateId: string) {
        const url =
            process.env.REACT_APP_MANAGE_URL +
            `projects/${projectId}/prompt-templates/${templateId}/dependent-packages`;

        const response = await appClient.get<PackageResult[]>(url);
        return response.unwrapOr([]);
    }
}

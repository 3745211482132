import RulesStore from './RulesStore';
import RulesListVisualStore from './RulesListVisualStore';
import { STORE_ERRORS, STORE_ROUTER, STORE_PROJECTS_ROOT_UI } from '../../common/constants';
import RuleEditVisualStore from './RuleEditVisualStore';
import RuleNewVisualStore from './RuleNewVisualStore';
import ConnectionEditorStore from './ConnectionEditorStore';
import { InferenceModelsService, RulesService } from '../services';
import { RootStores } from '../../common/stores';
// eslint-disable-next-line max-len
import {
    STORE_RULES,
    STORE_RULES_LIST,
    STORE_RULE_EDIT,
    STORE_RULES_NEW,
    STORE_RULES_CONNECTIONS,
    STORE_RULES_COPIED_PIPELINE,
    STORE_RULES_IMPORT,
    STORE_TAGS_GROUP,
    STORE_TAG_PREVIEW
} from '../constants';
import CopiedPipelineVisualStore from './CopiedPipelineVisualStore';
import RulesImportVisualStore from './RulesImportVisualStore';
import BusinessRulesService from '../../iota_applications/services/BusinessRulesService';
import TagsGroupVisualStore from './TagsGroupVisualStore';
import { ApplicationDefinitionsService } from '../../iota_applications/services';
import TagPreviewVisualStore from './TagPreviewVisualStore';

export { default as ConnectionEditorStore } from './ConnectionEditorStore';
export { default as PipelineVisualStore } from './PipelineVisualStore';
export { default as RuleEditVisualStore } from './RuleEditVisualStore';
export { default as RuleNewVisualStore } from './RuleNewVisualStore';
export { default as RulePreviewVisualStore } from './RulePreviewVisualStore';
export { default as RulesListVisualStore } from './RulesListVisualStore';
export { default as RulesPipelineVisualStore } from './RulesPipelineVisualStore';
export { default as CopiedPipelineVisualStore } from './CopiedPipelineVisualStore';
export { default as RulesImportVisualStore } from './RulesImportVisualStore';

export { default as RulesStore } from './RulesStore';

export function injectRulesStores(obj: RootStores) {
    const service = new RulesService();
    const appDefService = new ApplicationDefinitionsService();
    const bussinesRuleServive = new BusinessRulesService();
    const rulesStore = new RulesStore(
        service,
        bussinesRuleServive,
        obj[STORE_ERRORS],
        obj[STORE_PROJECTS_ROOT_UI],
        appDefService
    );
    const modelService = new InferenceModelsService();

    obj[STORE_RULES] = rulesStore;
    obj[STORE_RULE_EDIT] = new RuleEditVisualStore(
        rulesStore,
        obj[STORE_PROJECTS_ROOT_UI],
        obj[STORE_ROUTER],
        modelService
    );
    obj[STORE_RULES_NEW] = new RuleNewVisualStore(rulesStore, obj[STORE_ROUTER], obj[STORE_RULE_EDIT]);
    obj[STORE_RULES_COPIED_PIPELINE] = new CopiedPipelineVisualStore(
        rulesStore,
        obj[STORE_ROUTER],
        obj[STORE_RULE_EDIT]
    );
    obj[STORE_TAGS_GROUP] = new TagsGroupVisualStore(rulesStore, obj[STORE_ROUTER]);
    obj[STORE_RULES_CONNECTIONS] = new ConnectionEditorStore(rulesStore, obj[STORE_ERRORS]);
    obj[STORE_RULES_IMPORT] = new RulesImportVisualStore(rulesStore, obj[STORE_TAGS_GROUP], obj[STORE_ERRORS]);
    obj[STORE_RULES_LIST] = new RulesListVisualStore(rulesStore, obj[STORE_ROUTER], obj[STORE_RULES_IMPORT]);
    obj[STORE_TAG_PREVIEW] = new TagPreviewVisualStore(
        service,
        obj[STORE_PROJECTS_ROOT_UI],
        obj[STORE_RULES],
        obj[STORE_ERRORS]
    );
}

export type RulesStores = {
    [STORE_RULES_LIST]?: RulesListVisualStore;
    [STORE_RULE_EDIT]?: RuleEditVisualStore;
    [STORE_RULES_NEW]?: RuleNewVisualStore;
    [STORE_RULES_CONNECTIONS]?: ConnectionEditorStore;
    [STORE_RULES_COPIED_PIPELINE]?: CopiedPipelineVisualStore;
    [STORE_RULES_IMPORT]?: RulesImportVisualStore;
    [STORE_TAGS_GROUP]?: TagsGroupVisualStore;
    [STORE_TAG_PREVIEW]?: TagPreviewVisualStore;
    [STORE_RULES]?: RulesStore;
};

import { Select } from 'antd';
import * as React from 'react';

export enum TestProjectFilterValue {
    ALL = 'all',
    HIGH_PERFORMING = 'high_performing',
    NEEDS_IMPROVEMENT = 'needs_improvement',
    REQUIRES_REVIEW = 'requires_review'
}

type Props = {
    value: TestProjectFilterValue;
    onChange: (value: TestProjectFilterValue) => void;
};

const TestProjectFilter: React.FC<Props> = ({ value, onChange }) => {
    const filterOptions = [
        { value: TestProjectFilterValue.ALL, label: 'Show all' },
        { value: TestProjectFilterValue.HIGH_PERFORMING, label: 'High-performing (≥90%)' },
        { value: TestProjectFilterValue.NEEDS_IMPROVEMENT, label: 'Needs improvement (80% – 90%)' },
        { value: TestProjectFilterValue.REQUIRES_REVIEW, label: 'Requires review (<80%)' }
    ];

    return (
        <Select
            data-id="test-projects-filter"
            value={value}
            onChange={onChange}
            options={filterOptions}
            style={{ width: 220 }}
            placeholder="Filter by performance"
        />
    );
};

export default TestProjectFilter;

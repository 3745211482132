import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Layout, Button, Form, Input } from 'antd';
import LayoutHeader from '../../../../components/LayoutHeader';
import { Content } from 'antd/lib/layout/layout';
import { Utils } from '../../../common/services/Utils';
import PromptTemplateStore from '../../stores/PromptTemplatesStore';
import TextArea from 'antd/lib/input/TextArea';
import './PromptTemplate.less';
import { validateJson } from '../../misc';
import JsonEditor from './JsonEditor';

type Props = {
    store: PromptTemplateStore | undefined;
};

const CreatePromptTemplate: React.FC<Props> = ({ store }) => {
    const [form] = Form.useForm();

    const [editorValue, setEditorValue] = React.useState<string>('');
    const [jsonIsInvalid, setJsonIsInvalid] = React.useState(false);

    if (!store) {
        return null;
    }

    const handleValueChange = (value: string) => {
        setJsonIsInvalid(false);
        setEditorValue(value);
    };

    const handleSubmit = async () => {
        try {
            const jsonIsValid = validateJson(editorValue);

            if (!jsonIsValid) {
                setJsonIsInvalid(true);
                return;
            }
            const values = await form.validateFields();
            await store.createTemplate(values.title, values.description, values.body, editorValue);
        } catch (error) {
            console.log('Form validation failed');
        }
    };

    return (
        <Layout className="prompt-template-layout">
            <LayoutHeader
                subtitle={Utils.getSubtitle(store.currentProject)}
                helpMessage=""
                title="Create Prompt Template"
                buttons={[
                    <Button
                        key="prompt-templates-go-back"
                        data-id="button-go-to-list"
                        className="light"
                        size="large"
                        onClick={() => store.navigateToList()}
                    >
                        Go back
                    </Button>,
                    <Button
                        key="prompt-templates-submit"
                        data-id="button-submit"
                        type="primary"
                        size="large"
                        onClick={handleSubmit}
                    >
                        Create Template
                    </Button>
                ]}
            />
            <Layout>
                <Content className="prompt-template-content">
                    <div style={{ padding: '24px' }}>
                        <Form form={form} layout="vertical">
                            <Form.Item
                                name="title"
                                label="Title"
                                rules={[
                                    { required: true, message: 'Please enter a template title' },
                                    { whitespace: true, message: 'Template title cannot be empty' }
                                ]}
                            >
                                <Input placeholder="Enter template name" />
                            </Form.Item>
                            <Form.Item name="description" label="Description">
                                <Input placeholder="Enter template description" />
                            </Form.Item>
                            <Form.Item
                                name="body"
                                label="Body"
                                rules={[
                                    { required: true, message: 'Please enter a template body' },
                                    { whitespace: true, message: 'Template body cannot be empty' }
                                ]}
                            >
                                <TextArea autoSize={{ minRows: 18 }} placeholder="Enter prompt template text" />
                            </Form.Item>
                            <JsonEditor
                                value={editorValue}
                                onChange={handleValueChange}
                                jsonIsInvalid={jsonIsInvalid}
                            />
                        </Form>
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};

export default observer(CreatePromptTemplate);

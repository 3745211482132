import React from 'react';
import { observer } from 'mobx-react-lite';
import { Form, Input, Tooltip } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { InfoCircleOutlined } from '@ant-design/icons';
import { DefaultWorkflowParameter } from '../../../instruct_workflows/types';
import { ParameterKeyValue } from '../../../rules/types';
import './WorkflowParameterField.less';

type Props = {
    field: FormListFieldData;
    fieldIndex: number;
    fieldClassName?: string;
    hideFieldTooltip?: boolean;
    overridenWorkflowParametersName: string;
    overrideWorkflowParameterName: string;
    defaultWorkflowParameter?: DefaultWorkflowParameter;
    removeField: () => void;
};

const WorkflowParameterField: React.FC<Props> = ({
    field,
    fieldIndex,
    fieldClassName,
    hideFieldTooltip,
    overridenWorkflowParametersName,
    overrideWorkflowParameterName,
    defaultWorkflowParameter,
    removeField
}) => {
    const { key, name, fieldKey, ...restField } = field;

    const validateKeyUniqueness = async (value: string, parameters: ParameterKeyValue[]) => {
        const keyExists = parameters.some(
            (parameter, index) => parameter.key.toLowerCase() === value.toLowerCase() && index < fieldIndex
        );

        if (keyExists) {
            return Promise.reject(new Error('Key already exists'));
        }

        return Promise.resolve();
    };

    return (
        <Form.Item key={field.key} shouldUpdate noStyle>
            {({ getFieldValue, getFieldError }) => {
                const overrideEnabled = getFieldValue(overrideWorkflowParameterName);

                const keyNamePath = [overridenWorkflowParametersName, name, 'key'];
                const valueNamePath = [overridenWorkflowParametersName, name, 'value'];

                const currentKey = getFieldValue(keyNamePath);
                const currentValue = getFieldValue(valueNamePath);

                const keyErrors = getFieldError(keyNamePath);
                const valueErrors = getFieldError(valueNamePath);
                const errors = keyErrors.concat(valueErrors);

                return (
                    <React.Fragment key={key}>
                        <div
                            className={`workflow-parameter-row ${fieldClassName} ${errors.length > 0 ? 'error-row' : ''}`}
                        >
                            <Tooltip title={!hideFieldTooltip && currentKey ? currentKey : null}>
                                <div className="form-item-wrapper">
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'key']}
                                        fieldKey={[fieldKey, 'key']}
                                        noStyle
                                        className="form-item"
                                        rules={[
                                            { required: true, message: 'Key is required' },
                                            {
                                                validator: (_, value) => {
                                                    return validateKeyUniqueness(
                                                        value,
                                                        getFieldValue(overridenWorkflowParametersName)
                                                    );
                                                }
                                            }
                                        ]}
                                    >
                                        <Input
                                            placeholder="Enter key"
                                            disabled={defaultWorkflowParameter ? true : !overrideEnabled}
                                            className={`input-field ${keyErrors.length > 0 ? 'input-error' : ''}`}
                                        />
                                    </Form.Item>
                                </div>
                            </Tooltip>

                            <Tooltip title={!hideFieldTooltip && currentValue ? currentValue : null}>
                                <div className="form-item-wrapper">
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'value']}
                                        fieldKey={[fieldKey, 'value']}
                                        rules={[{ required: true, message: 'Value is required' }]}
                                        className="form-item"
                                        noStyle
                                    >
                                        <Input
                                            placeholder="Enter value"
                                            className={`input-field ${valueErrors.length > 0 ? 'input-error' : ''}`}
                                            disabled={!overrideEnabled}
                                        />
                                    </Form.Item>
                                </div>
                            </Tooltip>

                            <div className="icon-container">
                                {!defaultWorkflowParameter && (
                                    <i
                                        onClick={overrideEnabled ? () => removeField() : undefined}
                                        className="alpha-icon md delete-icon"
                                    />
                                )}

                                {defaultWorkflowParameter && defaultWorkflowParameter.description && (
                                    <Tooltip title={defaultWorkflowParameter.description}>
                                        <InfoCircleOutlined className="info-icon" />
                                    </Tooltip>
                                )}
                            </div>
                        </div>

                        {errors.length > 0 && (
                            <div className="workflow-parameter-row-error-messages">{errors.join(', ')}</div>
                        )}
                    </React.Fragment>
                );
            }}
        </Form.Item>
    );
};

export default observer(WorkflowParameterField);

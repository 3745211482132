import { Col, Empty, Input, Row, Spin } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React from 'react';
import SortableTree, { SearchData, TreeItem } from 'react-sortable-tree-patch-react-17';
import { RulePreviewVisualStore } from '../../stores';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';

interface TreeViewContainerProps {
    store: RulePreviewVisualStore;
    selectedRowContent: string | undefined;
    selectedRowType: string;
    searchFocusIndex: number | undefined;
    treeData: TreeItem[];
    setTreeData: (data: unknown) => void;
    searchCallback: (matches: object[]) => void;
    highlightBlock: (rowId: number, pkgId: string, selected: boolean | undefined, scrollToPos?: boolean) => void;
    setExpandedCollapsedNodes: (collapsedNodes: string[], expandedNodes: string[]) => void;
    findRootNode: (dataId: string) => TreeItem | undefined;
    collapsedNodes: string[];
    expandedNodes: string[];
}

const TreeViewContainer: React.FC<TreeViewContainerProps> = ({
    store,
    searchFocusIndex,
    treeData,
    setTreeData,
    searchCallback,
    highlightBlock,
    findRootNode,
    setExpandedCollapsedNodes,
    collapsedNodes,
    expandedNodes
}) => {
    if (!store.previewResults?.length && !store.isExecuting) {
        return (
            <Content className="rule-results-container">
                <Empty style={{ marginTop: 50 }} />
            </Content>
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleNodeClick = (e: React.MouseEvent, rowInfo: any) => {
        if (
            e.target &&
            (e.target as Element).className.includes &&
            !(e.target as Element).className.includes('Button')
        ) {
            const element = e.target as Element;
            const rowId = element.getAttribute('data-rowid');
            const pkgId = element.getAttribute('data-pkgid');
            const selected = element.getAttribute('data-selected') === 'true';
            if (rowId && pkgId && selected !== null) {
                highlightBlock(Number(rowId), pkgId, !selected);
                var nodes = document.getElementsByClassName('tree-selectable-node');
                for (let i = 0; i < nodes.length; i++) {
                    const node = nodes.item(i);
                    if (node) {
                        node.setAttribute('data-selected', 'false');
                    }
                }
                element.setAttribute('data-selected', String(!selected));
            }
        } else if (e.target && rowInfo.node) {
            const node = rowInfo.node.title as React.ReactElement;
            if (node && node.props) {
                const expanded = (e.target as Element).getAttribute('aria-label') === 'Expand';
                const dataId = node.props['data-id'] as string;
                if (expanded && dataId) {
                    const expandedNodesNew: string[] = [dataId];
                    const rootNode = findRootNode(dataId);

                    if (rootNode) {
                        const expandedChildNodes = getExpandedChildNodes(rootNode);
                        expandedNodesNew.push(...expandedChildNodes);

                        rootNode.children?.forEach((childNode: TreeItem) => {
                            const childNodeId = childNode.title?.props['data-id'];

                            if (childNodeId && expandedNodesNew.includes(childNodeId)) {
                                childNode.expanded = true;
                            }
                        });
                    }

                    setExpandedCollapsedNodes(
                        _.uniq([...expandedNodes, ...expandedNodesNew]),
                        collapsedNodes.filter(n => !expandedNodesNew.includes(n))
                    );
                } else {
                    setExpandedCollapsedNodes(
                        expandedNodes.filter(n => n !== dataId),
                        [...collapsedNodes, dataId]
                    );
                }
            }
        }
    };

    const getExpandedChildNodes = (rootNode: TreeItem) => {
        const packageId = rootNode.title?.props['package-id'];
        const data = store.filteredPreviewResults.find(p => p.packageId === packageId);

        if (!data) {
            return [];
        }

        return data.previewResult.entries.reduce<string[]>((acc, entry) => {
            const hasTags = entry.tag.values.some(v => v.length > 0);
            const hasText = entry.field.normalizedText.length > 0;

            if (hasTags && hasText) {
                acc.push(`entry-${entry.field.packageId}-${entry.field.rowId}`);
            }

            return acc;
        }, []);
    };

    const searchNode = (searchData: SearchData) => {
        const { node } = searchData;
        let searchText = store.inputValue || '';
        if (searchText && searchText.length) {
            const title = node.title;
            const titleText = getNodeTitleText(title as React.ReactElement);
            try {
                var regex = new RegExp(searchText);
                return regex.test(titleText.toLowerCase());
            } catch {
                return false;
            }
        } else {
            return false;
        }
    };

    const getNodeTitleText = (title: React.ReactElement): string => {
        if (title.props && title.props.children && typeof title.props.children !== 'string') {
            return getNodeTitleText(title.props.children);
        }
        return title.props ? title.props.children : '';
    };

    return (
        <Content className="rule-results-container">
            <div style={{ width: '100%', height: '100%' }}>
                {store!.isExecuting ? (
                    <Spin tip="Loading..." />
                ) : (
                    <div style={{ height: 'calc(100% - 63px)' }}>
                        <Row style={{ whiteSpace: 'nowrap' }}>
                            <Col span={24}>
                                <Input.Search
                                    style={{ margin: '20px 15px', width: 'calc(100% - 30px)' }}
                                    className="alpha-search-input"
                                    allowClear
                                    placeholder="Search..."
                                    value={store.inputValue || ''}
                                    onChange={v => {
                                        store.setInputValue(v.currentTarget.value);
                                    }}
                                />
                            </Col>
                        </Row>
                        <SortableTree
                            isVirtualized
                            scaffoldBlockPxWidth={24}
                            canDrag={false}
                            canDrop={() => {
                                return false;
                            }}
                            className="alpha-rst"
                            searchFocusOffset={searchFocusIndex}
                            searchFinishCallback={searchCallback}
                            searchQuery={store.inputValue || ''}
                            searchMethod={searchNode}
                            reactVirtualizedListProps={{
                                rowHeight: 32
                            }}
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            generateNodeProps={(rowInfo: any) => ({
                                className: 'alpha-sortable-tree-node',
                                onClick: (e: React.MouseEvent) => handleNodeClick(e, rowInfo),
                                data_id: `node-${rowInfo.node.title.props['data-id']}`
                            })}
                            treeData={treeData}
                            onChange={(changedTreeData: TreeItem[]) => setTreeData(changedTreeData)}
                            style={{ paddingLeft: 24, height: 'calc(100% - 24px)' }}
                        />
                    </div>
                )}
            </div>
        </Content>
    );
};

export default observer(TreeViewContainer);

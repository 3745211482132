import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Project, ProjectState } from '../../modules/common/models';
import { ProjectsRootVisualStore, RouterStore, UserProfileStore } from '../../modules/common/stores';
import { ProjectPinToggle } from '..';
import './ProjectTitle.less';

interface Props {
    project: Project;
    routerStore: RouterStore;
    projectsRootStore: ProjectsRootVisualStore;
    userProfileStore: UserProfileStore;
}

const ProjectTitle: React.FC<Props> = ({ project, routerStore, userProfileStore, projectsRootStore }: Props) => {
    const isProjectCurrent = React.useMemo(() => {
        return projectsRootStore.currentProject?.id === project.id;
    }, [projectsRootStore.currentProject, project.id]);

    return (
        <div
            className="project-title-container"
            style={project.state === ProjectState.Ready ? undefined : { color: '#9BA0AA' }}
            onClick={() => !isProjectCurrent && projectsRootStore.navigateToTagsPage(project)}
        >
            <div className="left-container">
                <Tooltip placement="right" title={project.title}>
                    <div className="project-icon-container">
                        {project.state === ProjectState.Ready ? (
                            <span className={`project-icon ${project.color}`} />
                        ) : (
                            <LoadingOutlined spin />
                        )}
                    </div>
                </Tooltip>
                <span className="project-title-label">{project.title}</span>
            </div>

            <div className="right-container">
                <ProjectPinToggle project={project} routerStore={routerStore} userProfileStore={userProfileStore} />
            </div>
        </div>
    );
};

export default observer(ProjectTitle);

import * as React from 'react';
import { AutoComplete, Input } from 'antd';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import './AutoCompleteTextArea.less';

const quoteChars = ["'", '"'];

interface AutoCompleteOption {
    label: string;
    value: string;
}

interface Props {
    value: string;
    options: AutoCompleteOption[];
    onChange: (value: string) => void;
}

const AutoCompleteTextArea: React.FC<Props> = ({ value, options, onChange }: Props) => {
    const [filteredOptions, setFilteredOptions] = React.useState<AutoCompleteOption[]>([]);
    const [optionsVisible, setOptionsVisible] = React.useState<boolean>(false);

    const backgroundRef = React.useRef<HTMLDivElement>(null);
    const textAreaRef = React.useRef<TextAreaRef>(null);

    const optionValues = React.useMemo(() => options.map(option => option.value), [options]);

    const handleSearch = (searchValue: string) => {
        onChange(searchValue);

        // Check if '@' exists before the cursor position
        const cursorPosition = textAreaRef.current?.resizableTextArea?.textArea.selectionStart ?? 0;
        const beforeCursor = searchValue.slice(0, cursorPosition);
        const lastWord = beforeCursor.split(' ').pop() || '';
        const atIndex = lastWord.lastIndexOf('@');

        if (atIndex === -1) {
            setOptionsVisible(false);
            return;
        }

        // Find the indexes of quotes within the search value
        const quoteIndexes = lastWord.split('').reduce<number[]>((indexes, char, index) => {
            if (quoteChars.includes(char)) {
                indexes.push(index);
            }
            return indexes;
        }, []);

        const unmatchedQuotes = quoteIndexes.length % 2 !== 0;

        // Case 1: '@' is entered without quotes
        if (!unmatchedQuotes) {
            return handleUnquotedSearchCase(lastWord, atIndex);
        }

        // Case 2: '@' is entered within quotes
        handleQuotedSearchCase(lastWord, quoteIndexes, cursorPosition, atIndex);
    };

    const handleUnquotedSearchCase = (lastWord: string, atIndex: number): void => {
        const filterText = lastWord.slice(atIndex + 1).toLowerCase();
        const filtered = options.filter(option => option.label.toLowerCase().includes(filterText));
        setFilteredOptions(filtered);
        setOptionsVisible(true);
    };

    const handleQuotedSearchCase = (
        lastWord: string,
        quoteIndexes: number[],
        cursorPosition: number,
        atIndex: number
    ): void => {
        const quotePos = quoteIndexes.filter(index => index < cursorPosition).pop() ?? -1;

        if (quotePos === -1 || quotePos < atIndex) {
            setOptionsVisible(false);
            return;
        }

        const filterText = lastWord.slice(quotePos + 1, cursorPosition).toLowerCase();
        const filtered = options.filter(option => option.label.toLowerCase().includes(filterText));
        setFilteredOptions(filtered);
        setOptionsVisible(true);
    };

    const handleSelect = (selectedValue: string) => {
        const cursorPosition = textAreaRef.current?.resizableTextArea?.textArea.selectionStart ?? 0;
        const textAreaValue = textAreaRef.current?.resizableTextArea?.textArea.value ?? '';

        const beforeCursor = textAreaValue.slice(0, cursorPosition);
        const afterCursor = textAreaValue.slice(cursorPosition);

        // Find the last '@' before the cursor position
        const atIndex = beforeCursor.lastIndexOf('@');

        if (atIndex === -1) {
            return; // No '@' found, do nothing
        }

        // Replace text from '@' to the cursor position with the selected value
        const newBeforeCursor = beforeCursor.slice(0, atIndex) + selectedValue.replace(/\n/g, ' ');
        const newValue = newBeforeCursor + afterCursor;

        onChange(newValue);
        setOptionsVisible(false);
        setFilteredOptions([]);

        // Set the cursor position to the end of the inserted value
        const newCursorPosition = newBeforeCursor.length;
        setTimeout(() => {
            textAreaRef.current?.resizableTextArea?.textArea.setSelectionRange(newCursorPosition, newCursorPosition);
        }, 0);
    };

    const getHighlightedText = (text: string): string => {
        const escaped = text.replace(/</g, '&lt;').replace(/>/g, '&gt;');
        const regex = new RegExp(`(${optionValues.join('|')})`, 'gi');
        return escaped.replace(regex, '<span class="highlighted-label">$1</span>');
    };

    return (
        <div className="auto-complete-text-area-container">
            <div
                ref={backgroundRef}
                className="highlight-background"
                dangerouslySetInnerHTML={{ __html: getHighlightedText(value) }}
            />

            <AutoComplete
                options={filteredOptions}
                open={optionsVisible}
                onSearch={handleSearch}
                onSelect={handleSelect}
                style={{ width: '100%' }}
                value={value}
                dropdownAlign={{
                    points: ['bl', 'tl']
                }}
            >
                <Input.TextArea
                    ref={textAreaRef}
                    style={{ background: 'transparent' }}
                    placeholder="Enter text"
                    autoSize={{ minRows: 1 }}
                    onChange={e => {
                        const newValue = e.target.value;
                        onChange(newValue);
                        handleSearch(newValue);
                    }}
                />
            </AutoComplete>
        </div>
    );
};

export default AutoCompleteTextArea;

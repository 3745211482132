import { action, computed, observable, runInAction } from 'mobx';
import { ProjectsRootVisualStore } from '../../common/stores';
import { message } from 'antd';
import { PackageResult } from '../../common/types';
import { AdministrationService } from '../../project_management/services/AdministrationService';
import PromptTemplateService from '../services/PromptTemplatesService';
import EditPromptTemplateStore from './EditPromptTemplatesStore';

export default class PropmtDependentPackagesStore {
    @observable
    isLoadingDependentPackages: boolean = false;

    @observable
    dependentPackages: PackageResult[] = [];

    constructor(
        private readonly projectsStore: ProjectsRootVisualStore,
        private readonly promptTemplateEditStore: EditPromptTemplateStore,
        private readonly service: PromptTemplateService,
        private readonly administrationService: AdministrationService
    ) {}

    @computed
    get project() {
        return this.projectsStore.currentProject;
    }

    @computed
    get selectedTemplate() {
        return this.promptTemplateEditStore.selectedTemplate;
    }

    @computed
    get dependentPackageCount() {
        return this.dependentPackages.length;
    }

    @computed
    get dependentPackageIds() {
        return this.dependentPackages.map(p => p.id);
    }

    @computed
    get isLoading() {
        return this.promptTemplateEditStore.isLoadingTemplate || this.isLoadingDependentPackages;
    }

    @computed
    get reparseDisabled() {
        return this.isLoading || !this.dependentPackageCount;
    }

    @action.bound
    async getDependentPackages() {
        if (!this.project || !this.selectedTemplate) {
            return;
        }

        runInAction(() => {
            this.isLoadingDependentPackages = true;
        });

        const dependentPackages = await this.service.getDependentPackages(this.project.id, this.selectedTemplate.id);

        runInAction(() => {
            this.dependentPackages = dependentPackages;
            this.isLoadingDependentPackages = false;
        });
    }

    async reparseDependentPackages(dependentPackageIds: string[]) {
        if (!dependentPackageIds.length) {
            return;
        }

        message.info('Initiated reparse of dependent packages');

        const resp = await this.administrationService.parsePackagesBatch(dependentPackageIds);

        if (resp.isErr()) {
            message.error('Failed to reparse dependent packages');
            return;
        }

        message.success('Started reparse of dependent packages');
    }

    dispose() {
        runInAction(() => {
            this.dependentPackages = [];
            this.isLoadingDependentPackages = false;
        });
    }
}

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Checkbox, Form, Select, Tooltip, Input, DatePicker } from 'antd';
import { debounce } from 'lodash';
import moment, { Moment } from 'moment';
import { HistoryStore } from '../../stores';
import { InfoCircleOutlined } from '@ant-design/icons';

interface Props {
    store: HistoryStore;
}

const HistoryFilters: React.FC<Props> = ({ store }) => {
    const debouncedSearch = React.useMemo(
        () =>
            debounce(() => {
                store.performSearch();
            }, 500),
        [store]
    );

    React.useEffect(() => {
        if (store.searchTerm.trim().length) {
            debouncedSearch();
        } else {
            store.performSearch();
        }
    }, [store, store.searchTerm, debouncedSearch]);

    return (
        <Form className="history-filter-form" layout="inline">
            <Form.Item>
                <Select
                    allowClear
                    style={{ width: 300 }}
                    placeholder="Select area..."
                    value={store.selectedAreaOption}
                    onChange={(value?: string) => {
                        store.setSelectedAreaOption(value);
                        store.performSearch();
                    }}
                    options={store.historyAreaOptions}
                />
            </Form.Item>

            <Form.Item>
                <Input.Search
                    style={{ width: 300 }}
                    value={store.searchTerm}
                    placeholder="Search by entity name..."
                    onChange={e => store.setSearchTerm(e.target.value)}
                    onSearch={value => {
                        store.setSearchTerm(value);
                        store.performSearch();
                    }}
                    allowClear
                />
            </Form.Item>

            <Form.Item>
                <Select
                    className="user-select"
                    placeholder="Select user"
                    style={{ width: 300 }}
                    value={store.userId}
                    onChange={(value?: string) => {
                        store.setUserId(value);
                        store.performSearch();
                    }}
                    options={store.users.map(user => ({ value: user.id, label: user.userName, title: user.userName }))}
                    filterOption={(input, option) =>
                        option?.title && option.title.toLowerCase().includes(input.toLowerCase())
                    }
                    loading={store.usersLoading}
                    showSearch
                    allowClear
                />
            </Form.Item>

            <Form.Item>
                <DatePicker.RangePicker
                    className="date-picker"
                    value={[
                        store.dateFrom ? moment.utc(store.dateFrom).startOf('day') : null,
                        store.dateTo ? moment.utc(store.dateTo).endOf('day') : null
                    ]}
                    onChange={(dateRange: [Moment, Moment]) => {
                        let dateFrom: string | undefined;
                        let dateTo: string | undefined;

                        if (dateRange) {
                            dateFrom = moment.utc(dateRange[0]).startOf('day').toISOString();
                            dateTo = moment.utc(dateRange[1]).endOf('day').toISOString();
                        }

                        store.setDateFrom(dateFrom);
                        store.setDateTo(dateTo);
                        store.performSearch();
                    }}
                />
            </Form.Item>

            <Form.Item>
                <Checkbox
                    checked={store.searchOnlyProjectRelated}
                    onChange={e => {
                        store.setSearchOnlyProjectRelated(e.target.checked);
                        store.performSearch();
                    }}
                >
                    Project changes only
                    <Tooltip
                        title="Selecting the 'project changes only' option will hide any modifications made within commonly shared areas,
                            such as Reference Tables, that are utilized by multiple projects. 
                            This option ensures that only changes specific to the current project are displayed."
                        className="history-filter-tooltip"
                    >
                        <InfoCircleOutlined />
                    </Tooltip>
                </Checkbox>
            </Form.Item>
        </Form>
    );
};

export default observer(HistoryFilters);

import { action, computed, observable, reaction, runInAction } from 'mobx';
import { DataCrafterTemplatesVisualStore } from '.';
import { message } from 'antd';
import {
    DataCrafterTemplate,
    DataCrafterTemplateColumn,
    DataCrafterInputColumn,
    CreateDataCrafterTemplateRequest,
    UpdateDataCrafterTemplateRequest
} from '../types';
import { Package } from '../../common/models';
import { DataCrafterTemplatesService } from '../services';
import { MLService } from '../../ml_storage/services';
import { MLModel } from '../../ml_storage/types';
import { groupBy } from 'lodash';

type PackageColumn = {
    key: string;
    name: string;
    worksheetName: string;
    headerCoordinates: string;
    packageId: string;
};

interface CompileProgress {
    step: number;
    total_steps: number;
    message: string;
    status: string;
    duration: number;
    step_details?: {
        model_output?: string;
        observations?: string;
        action_output?: string | null;
        tool_calls?: Array<any>;
    };
}

export default class DataCrafterTemplateFormStore {
    @observable
    selectedInputPackages: string[] = [];

    @observable
    selectedOutputPackage: string | null = null;

    @observable
    inputColumns: PackageColumn[] = [];

    @observable
    outputColumns: DataCrafterTemplateColumn[] = [];

    @observable
    isLoadingInputColumns: boolean = false;

    @observable
    isLoadingOutputColumns: boolean = false;

    @observable
    selectedTemplate: DataCrafterTemplate | null = null;

    @observable
    isLoadingTemplate: boolean = false;

    @observable
    isLoadingTemplateScript: boolean = false;

    @observable
    templateScript: string | null = null;

    @observable
    templateScriptError: string | undefined = undefined;

    @observable
    mlModels: MLModel[] = [];

    @observable
    isLoadingMlModels: boolean = false;

    @observable
    templateName: string = '';

    @observable
    selectedPlannerModelId: string | undefined = undefined;

    @observable
    selectedCoderModelId: string | undefined = undefined;

    @observable
    selectedInputPackageId: string | undefined = undefined;

    @observable
    compilationProgress: CompileProgress | null = null;

    constructor(
        private visualStore: DataCrafterTemplatesVisualStore,
        private dataCrafterTemplatesService: DataCrafterTemplatesService,
        private mlService: MLService
    ) {
        reaction(
            () => this.inputColumnGroups,
            inputColumnGroups => {
                this.selectedInputPackageId = Object.keys(inputColumnGroups)[0];
            }
        );
    }

    @computed
    get canSubmit(): boolean {
        return (
            this.selectedOutputPackage !== null &&
            this.outputColumns.length > 0 &&
            this.outputColumns.some(col => col.mappingRule) &&
            !!this.templateName &&
            !!this.selectedPlannerModelId &&
            !!this.selectedCoderModelId
        );
    }

    @computed
    get canCompileScript(): boolean {
        return (
            this.selectedInputPackages.length > 0 &&
            this.selectedOutputPackage !== null &&
            this.outputColumns.length > 0 &&
            this.outputColumns.some(col => col.mappingRule) &&
            !!this.selectedPlannerModelId &&
            !!this.selectedCoderModelId
        );
    }

    @computed
    get canPreviewScript(): boolean {
        return this.canCompileScript && !!this.templateScript;
    }

    @computed
    get packages(): Package[] {
        return this.visualStore.packages;
    }

    @computed
    get isLoadingPackages(): boolean {
        return this.visualStore.loadingPackages;
    }

    @computed
    get isEditMode(): boolean {
        return this.selectedTemplate !== null;
    }

    @computed
    get hasMlModels(): boolean {
        return this.mlModels.length > 0;
    }

    @computed
    get inputColumnGroups() {
        return groupBy(this.inputColumns, 'packageId');
    }

    static getColumnLabelAndValue(column: PackageColumn) {
        const label = `${column.worksheetName}:${column.name}:${column.headerCoordinates}`.replace(/\n/g, ' ');
        return { label, value: `@'${label}'` };
    }

    @action.bound
    setTemplateName(name: string) {
        this.templateName = name;
    }

    @action.bound
    setSelectedPlannerModelId(modelId: string | undefined) {
        this.selectedPlannerModelId = modelId;
    }

    @action.bound
    setSelectedCoderModelId(modelId: string | undefined) {
        this.selectedCoderModelId = modelId;
    }

    @action.bound
    setSelectedInputPackageId(selectedInputPackageId?: string) {
        this.selectedInputPackageId = selectedInputPackageId;
    }

    @action.bound
    async initializeWithTemplate(templateId: string) {
        if (!this.visualStore.currentProject) {
            return;
        }

        try {
            runInAction(() => {
                this.isLoadingTemplate = true;
            });

            const template = (await this.dataCrafterTemplatesService.getTemplateById(
                templateId,
                this.visualStore.currentProject.id
            )) as DataCrafterTemplate | null;

            if (template && this.isValidTemplate(template)) {
                runInAction(() => {
                    this.selectedTemplate = template;
                    this.selectedOutputPackage = template.outputPackageId;
                    this.selectedInputPackages = template.inputPackageIds ?? [];
                    this.outputColumns = template.columns.map(
                        (col: DataCrafterTemplateColumn) =>
                            new DataCrafterTemplateColumn(
                                col.name,
                                col.worksheetName,
                                col.headerCoordinates,
                                col.packageId,
                                col.mappingRule
                            )
                    );
                    this.inputColumns =
                        template.inputColumns?.map((col: DataCrafterInputColumn, index: number) => ({
                            key: `${col.worksheetName}-${col.name}-${index}`,
                            name: col.name,
                            worksheetName: col.worksheetName,
                            headerCoordinates: col.headerCoordinates,
                            packageId: col.packageId
                        })) ?? [];
                    this.templateScript = template.code ?? null;

                    this.templateName = template.name;
                    this.selectedPlannerModelId = template.plannerModelId;
                    this.selectedCoderModelId = template.coderModelId;
                });

                await this.fetchOutputColumns();

                // Load ML models for the current project
                await this.loadMlModels();
            }
        } catch (error) {
            console.error('Failed to load template:', error);
            message.error('Failed to load template');
        } finally {
            runInAction(() => {
                this.isLoadingTemplate = false;
            });
        }
    }

    @action.bound
    async initialize() {
        if (this.visualStore.currentProject) {
            await this.loadMlModels();
        }
    }

    @action.bound
    setSelectedInputPackages(packageIds: string[]) {
        this.selectedInputPackages = packageIds;
        this.inputColumns = [];
    }

    @action.bound
    setSelectedOutputPackage(packageId: string | null) {
        this.selectedOutputPackage = packageId;
        this.outputColumns = [];
    }

    @action.bound
    async loadPackages() {
        await this.visualStore.loadAllProjectPackages();
    }

    @action.bound
    async loadMlModels() {
        if (!this.visualStore.currentProject) {
            return;
        }

        try {
            runInAction(() => {
                this.isLoadingMlModels = true;
            });

            const models = await this.mlService.getMLModelsForProject(this.visualStore.currentProject.id);

            runInAction(() => {
                this.mlModels = models;
            });
        } catch (error) {
            console.error('Failed to load ML models:', error);
            message.error('Failed to load ML models');
        } finally {
            runInAction(() => {
                this.isLoadingMlModels = false;
            });
        }
    }

    @action.bound
    async fetchInputColumns() {
        if (!this.selectedInputPackages.length) {
            return;
        }

        try {
            runInAction(() => {
                this.isLoadingInputColumns = true;
            });

            const response = await this.fetchPackagesColumns(this.selectedInputPackages);

            runInAction(() => {
                this.inputColumns = response.map((col, index) => ({
                    key: `${col.worksheetName}-${col.name}-${index}`,
                    name: col.name,
                    worksheetName: col.worksheetName,
                    headerCoordinates: col.headerCoordinates,
                    packageId: col.packageId
                }));
            });
        } catch (error) {
            console.error('Failed to fetch input columns:', error);
            message.error('Failed to fetch input package columns');
        } finally {
            runInAction(() => {
                this.isLoadingInputColumns = false;
            });
        }
    }

    @action.bound
    async fetchOutputColumns() {
        if (!this.selectedOutputPackage) {
            return;
        }

        try {
            runInAction(() => {
                this.isLoadingOutputColumns = true;
            });

            const response = await this.fetchPackagesColumns([this.selectedOutputPackage]);
            const existingMappings = new Map(
                this.outputColumns.map(col => [`${col.worksheetName}-${col.name}`, col.mappingRule])
            );

            if (response.length === 0) {
                return;
            }

            runInAction(() => {
                this.outputColumns = response.map(col => {
                    const key = `${col.worksheetName}-${col.name}`;
                    const existingMapping = existingMappings.get(key) || '';
                    return new DataCrafterTemplateColumn(
                        col.name,
                        col.worksheetName,
                        col.headerCoordinates,
                        col.packageId,
                        existingMapping
                    );
                });
            });
        } catch (error) {
            console.error('Failed to fetch output columns:', error);
            message.error('Failed to fetch output package columns');
        } finally {
            runInAction(() => {
                this.isLoadingOutputColumns = false;
            });
        }
    }

    @action.bound
    navigateToList() {
        this.visualStore.navigateToList();
        this.reset();
    }

    @action.bound
    async createTemplate(
        templateName: string,
        plannerModelId?: string,
        coderModelId?: string
    ): Promise<DataCrafterTemplate | null> {
        if (!this.canSubmit || !this.visualStore.currentProject) {
            return null;
        }

        try {
            this.templateName = templateName;
            this.selectedPlannerModelId = plannerModelId;
            this.selectedCoderModelId = coderModelId;

            const request: CreateDataCrafterTemplateRequest = {
                name: this.templateName,
                outputPackageId: this.selectedOutputPackage!,
                columns: this.outputColumns.filter(col => col.mappingRule),
                inputPackageIds: this.selectedInputPackages,
                inputColumns: this.inputColumns.map(col => ({
                    name: col.name,
                    worksheetName: col.worksheetName,
                    headerCoordinates: col.headerCoordinates,
                    packageId: col.packageId
                })),
                code: this.templateScript || '',
                plannerModelId: this.selectedPlannerModelId,
                coderModelId: this.selectedCoderModelId
            };

            const result = await this.dataCrafterTemplatesService.createTemplate(
                this.visualStore.currentProject.id,
                request
            );

            if (result) {
                message.success('Template created successfully');
                await this.visualStore.loadTemplates();
                this.reset();
                this.navigateToList();
            } else {
                message.error('Failed to create template');
            }

            return result;
        } catch (error) {
            console.error('Failed to create template:', error);
            message.error('Failed to create template');
            return null;
        }
    }

    @action.bound
    async updateTemplate(
        templateName: string,
        plannerModelId?: string,
        coderModelId?: string
    ): Promise<DataCrafterTemplate | null> {
        if (!this.canSubmit || !this.visualStore.currentProject || !this.selectedTemplate) {
            return null;
        }

        try {
            this.templateName = templateName;
            this.selectedPlannerModelId = plannerModelId;
            this.selectedCoderModelId = coderModelId;

            const request: UpdateDataCrafterTemplateRequest = {
                name: this.templateName,
                outputPackageId: this.selectedOutputPackage!,
                columns: this.outputColumns.filter(col => col.mappingRule),
                inputPackageIds: this.selectedInputPackages,
                inputColumns: this.inputColumns.map(col => ({
                    name: col.name,
                    worksheetName: col.worksheetName,
                    headerCoordinates: col.headerCoordinates,
                    packageId: col.packageId
                })),
                code: this.templateScript || '',
                status: this.selectedTemplate.status,
                plannerModelId: this.selectedPlannerModelId,
                coderModelId: this.selectedCoderModelId
            };

            const result = await this.dataCrafterTemplatesService.updateTemplate(
                this.visualStore.currentProject.id,
                this.selectedTemplate.id,
                request
            );

            if (result) {
                message.success('Template updated successfully');
                await this.visualStore.loadTemplates();
                this.reset();
                this.navigateToList();
            } else {
                message.error('Failed to update template');
            }

            return result;
        } catch (error) {
            console.error('Failed to update template:', error);
            message.error('Failed to update template');
            return null;
        }
    }

    @action.bound
    reset() {
        this.selectedTemplate = null;
        this.selectedInputPackages = [];
        this.selectedOutputPackage = null;
        this.inputColumns = [];
        this.outputColumns = [];
        this.templateScript = null;
        this.templateScriptError = undefined;
        this.mlModels = [];
        this.compilationProgress = null;

        this.templateName = '';
        this.selectedPlannerModelId = undefined;
        this.selectedCoderModelId = undefined;
        this.selectedInputPackageId = undefined;
    }

    @action.bound
    setTemplateScriptError(error: string | undefined) {
        this.templateScriptError = error;
    }

    @action.bound
    async compileTemplateScript() {
        if (!this.canCompileScript) {
            return null;
        }

        try {
            runInAction(() => {
                this.isLoadingTemplateScript = true;
            });
            this.setTemplateScriptError(undefined);

            const response = await this.dataCrafterTemplatesService.compileTemplateScript(
                this.selectedInputPackages,
                this.selectedOutputPackage!,
                this.outputColumns.filter(col => col.mappingRule),
                this.selectedPlannerModelId || null,
                this.selectedCoderModelId || null
            );

            runInAction(() => {
                this.templateScript = response.code;
            });

            if (response.error) {
                this.setTemplateScriptError('Failed to compile template script: ' + response.error);
            }

            return response;
        } catch (error) {
            console.error('Failed to compile template script:', error);
            message.error('Failed to compile template script');
            this.setTemplateScriptError('Failed to compile template script: ' + error.message);

            return null;
        } finally {
            runInAction(() => {
                this.isLoadingTemplateScript = false;
            });
        }
    }

    @action.bound
    async runTemplateScript(): Promise<void> {
        if (!this.canPreviewScript) {
            return;
        }

        try {
            const blob = await this.dataCrafterTemplatesService.runTemplateScript(
                this.selectedInputPackages,
                this.selectedOutputPackage!,
                this.templateScript!
            );

            // Create a download link and trigger the download
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'preview.xlsx';
            document.body.appendChild(a);
            a.click();

            // Cleanup
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error('Failed to run template script:', error);
            message.error('Failed to run template script');
            this.setTemplateScriptError('Failed to run template script: ' + error.message);
        }
    }

    @action.bound
    async compileTemplateScriptWithProgress(): Promise<void> {
        if (!this.canCompileScript) {
            return;
        }

        try {
            runInAction(() => {
                this.isLoadingTemplateScript = true;
                this.compilationProgress = {
                    step: 1,
                    total_steps: 1,
                    message:
                        'Initializing code generation...\nProcessing template configuration and preparing initial prompt.',
                    status: 'in_progress',
                    duration: 0
                };
                this.templateScriptError = undefined;
            });

            await this.dataCrafterTemplatesService.compileTemplateScriptStream(
                this.selectedInputPackages,
                this.selectedOutputPackage!,
                this.outputColumns.filter(col => col.mappingRule),
                this.selectedPlannerModelId || null,
                this.selectedCoderModelId || null,
                {
                    onProgress: progress => {
                        runInAction(() => {
                            this.compilationProgress = progress;
                        });
                    },
                    onComplete: code => {
                        runInAction(() => {
                            this.templateScript = code;
                            this.isLoadingTemplateScript = false;
                            this.compilationProgress = null;
                        });
                    },
                    onError: error => {
                        runInAction(() => {
                            this.templateScriptError = `Failed to compile template script: ${error.message}`;
                            this.isLoadingTemplateScript = false;
                            this.compilationProgress = null;
                        });
                    }
                }
            );
        } catch (error) {
            console.error('Failed to compile template script:', error);
            runInAction(() => {
                this.templateScriptError = `Failed to compile template script: ${error.message}`;
                this.isLoadingTemplateScript = false;
                this.compilationProgress = null;
            });
        }
    }

    @action.bound
    private async fetchPackagesColumns(packageIds: string[]) {
        try {
            const response = await this.dataCrafterTemplatesService.fetchPackagesColumns(packageIds);
            return response.columns;
        } catch (error) {
            console.error('Failed to fetch packages columns:', error);
            message.error('Failed to fetch packages columns');
            return [];
        }
    }

    // Type guard to ensure template has required properties
    private isValidTemplate(template: unknown): template is DataCrafterTemplate {
        if (!template || typeof template !== 'object') {
            return false;
        }

        const requiredProps = ['id', 'projectId', 'name', 'createdBy', 'outputPackageId', 'columns'];
        const templateObj = template as Record<string, unknown>;

        return requiredProps.every(prop => prop in templateObj) && Array.isArray(templateObj.columns);
    }
}

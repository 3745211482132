import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Table, Tooltip, Button, Popconfirm } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { ColumnProps } from 'antd/lib/table';
import { Utils } from '../../../common/services/Utils';
import InstructWorkflowCreateEditStore from '../../stores/InstructWorkflowCreateEditStore';
import InstructWorkflowImportExportStore from '../../stores/InstructWorkflowImportExportStore';
import InstructWorkflowModel from '../../model/InstructWorkflowModel';
import useTableScrollHeight from '../../../common/hooks/useTableScrollHeight';
import './WorkflowsList.less';

interface Props {
    store: InstructWorkflowCreateEditStore;
    importExportStore: InstructWorkflowImportExportStore;
}

const WorkflowsList: React.FC<Props> = ({ store, importExportStore }) => {
    const [sorter, setSorter] = React.useState<SorterResult<InstructWorkflowModel>>({
        columnKey: '',
        order: undefined
    });

    const getSorterOrder = (columnKey: string) => {
        if (!sorter.order || sorter.columnKey !== columnKey) {
            return;
        }

        return sorter.order;
    };

    const columns: ColumnProps<InstructWorkflowModel>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sortOrder: getSorterOrder('name'),
            sorter: (a: InstructWorkflowModel, b: InstructWorkflowModel) =>
                a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
            render: (name: string) => (
                <span data-id-cells="name" className="cell-limited-max-width name">
                    {name}
                </span>
            )
        },
        {
            title: 'Workflow Type',
            dataIndex: 'workflowType',
            key: 'workflowType',
            sortOrder: getSorterOrder('workflowType'),
            sorter: (a: InstructWorkflowModel, b: InstructWorkflowModel) => {
                if (!a.workflowType || !b.workflowType) {
                    return 0;
                }

                return a.workflowType.type.toLowerCase().localeCompare(b.workflowType.type.toLowerCase());
            },
            render: (_, workflow: InstructWorkflowModel) => {
                const label =
                    store.workflowTypeSelectOptions.find(o => o.value === workflow.workflowType?.type)?.label ?? '';
                return <span data-id-cells="workflowType">{label}</span>;
            }
        },
        {
            title: 'Create Date',
            dataIndex: 'createDate',
            key: 'createDate',
            sortOrder: getSorterOrder('createDate'),
            sorter: (a: InstructWorkflowModel, b: InstructWorkflowModel) =>
                Utils.safeDateCompare(a.createDate, b.createDate),
            render: (createDate: string) => {
                return {
                    children: Utils.formatDateStringShort(createDate)
                };
            }
        },
        {
            title: 'Update Date',
            dataIndex: 'updateDate',
            key: 'updateDate',
            sortOrder: getSorterOrder('updateDate'),
            sorter: (a: InstructWorkflowModel, b: InstructWorkflowModel) =>
                Utils.safeDateCompare(a.updateDate, b.updateDate),
            render: (updateDate: string | null) => {
                return {
                    children: Utils.formatDateStringShort(updateDate)
                };
            }
        },
        {
            key: 'actions',
            dataIndex: 'actions',
            title: '',
            render: (_, workflow: InstructWorkflowModel) => (
                <div className="row-actions-wrapper" onClick={e => e.stopPropagation()} style={{ minWidth: 100 }}>
                    <Tooltip title="Export workflow">
                        <Button
                            type="link"
                            size="small"
                            data-id="export-workflow"
                            icon={<i className="alpha-icon md arrow-up" />}
                            style={{ marginRight: 8 }}
                            onClick={() => importExportStore.exportWorkflow(workflow.id!)}
                        />
                    </Tooltip>

                    <Tooltip title="Edit workflow">
                        <Button
                            type="link"
                            size="small"
                            data-id="edit-workflow"
                            icon={<i className="alpha-icon lg edit-icon" />}
                            style={{ marginRight: 8 }}
                            onClick={() => {
                                workflow.setIsEditable(true);
                                store.setCreateWorkflowDialogVisible(true);
                            }}
                        />
                    </Tooltip>

                    <Popconfirm
                        title="Are you sure you want to delete this workflow?"
                        onConfirm={() => workflow.delete()}
                    >
                        <Tooltip title="Delete workflow">
                            <Button
                                type="link"
                                size="small"
                                data-id="delete-workflow"
                                icon={<i className="alpha-icon lg delete-icon" />}
                            />
                        </Tooltip>
                    </Popconfirm>
                </div>
            )
        }
    ];

    const { scrollHeight } = useTableScrollHeight(200);

    return (
        <Table
            className="alpha-portal-table workflows-list"
            loading={store.isLoading}
            dataSource={store.workflows
                .filter(f => !f.isNew && f.workflowType)
                .sort((a, b) => Utils.safeDateCompare(b.createDate, a.createDate))}
            columns={columns}
            pagination={false}
            scroll={store.workflows.length ? { y: scrollHeight } : undefined}
            rowKey={(workflow: InstructWorkflowModel) => workflow.id + workflow.workflowTypeId}
            onChange={(_, __, sorterData: SorterResult<InstructWorkflowModel>) => setSorter(sorterData)}
        />
    );
};

export default observer(WorkflowsList);

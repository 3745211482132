/* eslint-disable react/no-deprecated */
import * as React from 'react';
import { IReactionDisposer, reaction } from 'mobx';
import { Observer, observer } from 'mobx-react';
import { Input, Select, Checkbox, List, Pagination } from 'antd';
import PackageContextMenu from '../../common/components/PackageContextMenu';
import { RulePreviewVisualStore } from '../stores';
import { STORE_RULES_PREVIEW } from '../constants';
import { SelectWhenScroll } from '../../common/components/SelectWhenScroll';

const Option = Select.Option;

type Props = { [STORE_RULES_PREVIEW]: RulePreviewVisualStore; className?: string; style?: React.CSSProperties };

class ProjectPackageFilter extends React.Component<Props, object> {
    private disposer: IReactionDisposer;

    constructor(props: Props) {
        super(props);
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    componentDidMount() {
        const store = this.props.RulePreviewUI!;

        store.onComponentLoad();
        store.subscribeToPackageChanges();

        this.disposer = reaction(
            () => store.docNameInputValue,
            inputValue => {
                if (!inputValue.length) {
                    store.setCheckAllKeys(false);
                }
            }
        );
    }

    componentWillUnmount() {
        this.props.RulePreviewUI!.unsubscribeFromPackageChanges();
        this.disposer();
    }

    componentDidUpdate() {
        this.props.RulePreviewUI!.syncPackageIds();
    }

    handlePageChange(page: number) {
        const store = this.props.RulePreviewUI!;
        store.setPackagesListPage(page);
        store.loadProjectPackages();
    }

    render() {
        const store = this.props.RulePreviewUI!;
        const tags = store.tags;

        return (
            <div className={this.props.className} style={this.props.style}>
                <Input.Search
                    data-id="rule-preview-package-filter-input"
                    className="alpha-search-input"
                    style={{ margin: '20px 15px 15px 0', width: '98%' }}
                    allowClear
                    placeholder="Filter results..."
                    value={store.docNameInputValue || ''}
                    onChange={v => {
                        store.setDocNameInputValue(v.currentTarget.value);
                        store.debouncedSearchDocuments(v.currentTarget.value);
                    }}
                />
                <SelectWhenScroll
                    data-id="rule-preview-tag-SelectWhenScroll"
                    style={{ margin: '0px 10px 10px 0', width: '98%' }}
                    mode="tags"
                    placeholder="Tags..."
                    value={store.currentTags}
                    onChange={(val: string[]) => {
                        store.setTags(val);
                        store.saveFilteredTagsInStorage(val);
                        store.loadProjectPackages();
                    }}
                >
                    {tags.map((k, i) => (
                        <Option key={`kw-${i}`} value={k}>
                            {k}
                        </Option>
                    ))}
                </SelectWhenScroll>
                <Pagination
                    pageSize={store.pageSize}
                    current={store.packagesListPage}
                    onChange={this.handlePageChange}
                    showSizeChanger={false}
                    total={store.totalPackagesCount}
                    size="small"
                    className="rule-preview-form-project-package-filter-pagination"
                />
                <Checkbox
                    style={{ margin: 0, paddingTop: 10, marginBottom: 20, width: '100%', background: 'white' }}
                    indeterminate={store.selectAllIndeterminate}
                    checked={store.checkAllKeys}
                    onChange={e => {
                        store.setCheckAllKeys(e.target.checked);
                    }}
                    disabled={!store.packages || !store.packages.length}
                >
                    <span style={{ marginLeft: 4 }}>Select all</span>
                </Checkbox>

                <Observer>
                    {() => (
                        <List
                            loading={store.isLoadingPackages}
                            data-id="rule-preview-package-list"
                            size="small"
                            bordered={false}
                            dataSource={store.readyPackages}
                            renderItem={item => (
                                <List.Item
                                    className="rule-preview-packages-tree-node"
                                    style={{
                                        whiteSpace: 'nowrap',
                                        padding: '4px 0px',
                                        border: 'none',
                                        justifyContent: 'start'
                                    }}
                                    key={item.id}
                                    data-id-type="document-name"
                                    data-id-name={item.name}
                                >
                                    <Observer>
                                        {() => (
                                            <div
                                                className={`${store.selectedPackage?.id === item.id ? 'selected' : ''}`}
                                            >
                                                <Checkbox
                                                    checked={store.checkedKeys.includes(item.id)}
                                                    style={{ marginRight: 8 }}
                                                    onChange={e => {
                                                        store.handlePackageCheck(item.id, e.target.checked);
                                                    }}
                                                />
                                                <span onClick={() => store.selectPackage(item.id)}>
                                                    <PackageContextMenu pkg={item} />
                                                </span>
                                            </div>
                                        )}
                                    </Observer>
                                </List.Item>
                            )}
                        />
                    )}
                </Observer>
            </div>
        );
    }
}

export default observer(ProjectPackageFilter);

/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable dot-notation  */
type NameMeta = {
    name: string;
};

export default class HistoryHelpers {
    static hasNameMeta(meta: unknown): meta is NameMeta {
        return !!(meta && typeof meta === 'object' && 'name' in meta);
    }

    static formatFieldName(value?: string) {
        return value ? value.replace(/([a-z])([A-Z])/g, '$1 $2') : '';
    }

    static parseJson(value: unknown) {
        return typeof value === 'string' ? JSON.parse(value) : '';
    }

    static valueGetter(value: unknown) {
        // Mongo driver for C# may add "_t" and "_v" for some data types
        if (value && typeof value === 'object' && '_t' in value && '_v' in value) {
            return value['_v'];
        }

        return value;
    }
}

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Divider } from 'antd';
import { DataCrafterTemplateFormStore } from '../../stores';
import { InputPackageColumnsSelector, OutputPackageColumnsSelector } from '..';
import './PackageColumnsSelectors.less';

interface Props {
    formStore: DataCrafterTemplateFormStore;
}

const PackageColumnsSelectors: React.FC<Props> = ({ formStore }) => {
    return (
        <div className="package-columns-selectors-container">
            <InputPackageColumnsSelector createStore={formStore} />
            <Divider className="columns-divider" type="vertical" />
            <OutputPackageColumnsSelector createStore={formStore} />
        </div>
    );
};

export default observer(PackageColumnsSelectors);

import type { UserModel } from '../../administration/types/UserModel';
import appClient from './AppClient';

export default class UserProfileService {
    updateUserProfile(data: FormData) {
        const url = process.env.REACT_APP_USERS_URL + 'userProfile';
        return appClient.post(url, data);
    }

    async getAvatarIcon(fileId: string): Promise<Blob | undefined> {
        const url = `${process.env.REACT_APP_USERS_URL}userProfile/icon/${fileId}`;
        const resp = await appClient.get<Blob | undefined>(url, 'blob');
        return resp.mapErr(err => console.log(err)).unwrapOr(undefined);
    }

    getUserByName(name: string) {
        const url = `${process.env.REACT_APP_USERS_URL}${name}`;
        return appClient.get<UserModel>(url);
    }

    async updatePinnedProjects(pinnedProjectIds: string[]) {
        const url = process.env.REACT_APP_USERS_URL + 'userProfile/update-pinned-projects';
        return appClient.post(url, { pinnedProjectIds });
    }
}

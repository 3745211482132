import React from 'react';
import { Form } from 'antd';
import Editor from 'react-simple-code-editor';
import Prism from 'prismjs';
import 'prismjs/components/prism-json';

interface JsonEditorProps {
    value: string;
    jsonIsInvalid: boolean;
    onChange: (val: string) => void;
}

const JsonEditor: React.FC<JsonEditorProps> = ({ value, jsonIsInvalid, onChange }) => {
    const [isFocused, setIsFocused] = React.useState(false);

    const handleValueChange = (val: string) => {
        onChange(val);
    };

    const getEditorClassName = () => {
        let className = 'json-editor ';

        if (jsonIsInvalid) {
            className += 'border-invalid';
        } else if (isFocused) {
            className += 'border-focused';
        } else {
            className += 'border-default';
        }

        return className;
    };

    return (
        <Form.Item name="scheme" className="structured-output-field" label={'Structured Output'}>
            <Editor
                className="json-editor-container"
                textareaClassName={getEditorClassName()}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                value={value}
                onValueChange={handleValueChange}
                highlight={val => Prism.highlight(val ?? '', Prism.languages.json, 'json')}
                padding={10}
            />

            {jsonIsInvalid ? <span style={{ color: 'red' }}>JSON is invalid</span> : null}
        </Form.Item>
    );
};

export default JsonEditor;

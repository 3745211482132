import * as React from 'react';
import { Layout } from 'antd';
import { Route, Switch, Redirect } from 'react-router';
import ErrorNotification from '../modules/common/components/ErrorNotification';
import { inject, observer, Observer } from 'mobx-react';
import ProjectsSideMenu from './ProjectsSideMenu';
import DevTools from 'mobx-react-devtools';
import MLStorageRoutes from '../modules/ml_storage/routes';
import ProjectRoutes, { ProjectPagesNavigation } from '../modules/project_management/routes';
import LabelsRoutes from '../modules/list_labels/routes';
import ViewerRoutes, { ViewerPagesNavigation } from '../modules/viewer_base/routes';
import InteractiveRoutes from '../modules/interactive_labels/routes';
import AppRoutes from '../modules/iota_applications/routes';
import ConnectionsRoutes from '../modules/iota_connections/routes';
import SessionsRoutes from '../modules/iota_sessions/routes';
import RulesRoutes from '../modules/rules/routes';
import ReferenceDataRoutes from '../modules/iota_reference_data/routes';
import IotaAuditRoutes from '../modules/iota_audit/routes';
import FieldBindingsRoutes from '../modules/field_bindings/routes';
import FormTypesRoutes from '../modules/form_types/routes';
import ProjectSettingsRoutes from '../modules/project_settings/routes';
import TestProjectsRoutes from '../modules/test_projects/routes';
import HistoryRoutes from '../modules/history/routes';
import InstructWorkflowsRoutes from '../modules/instruct_workflows/routes';
import SchemaGeneratorRoutes from '../modules/schema_generator/routes';
import DataCrafterTemplatesRoutes from '../modules/data_crafter_templates/routes';
import PromptTemplatesRoutes from '../modules/prompt_templates/routes';

import { RootStores, ProjectsRootVisualStore } from '../modules/common/stores';
import DocumentViewerPage from '../modules/viewer_base/screens/DocumentViewerPage';
import { STORE_ROUTER, STORE_PROJECTS_ROOT_UI } from '../modules/common/constants';
import {
    CodeSnippetsPage,
    GlobalAdministrationPage,
    ProjectAssignmentPage,
    RolesManagementPage,
    SecurityPage,
    AlphaConfigPage,
    TechnicalInformationPage
} from '../modules/administration/screens';
import { PushServiceStatusBar } from '../modules/common/components/PushServiceStatusBar';
import { SecurityPagesNavigation } from '../modules/administration/routes';
import AdministrationSideMenu from './AdministrationSideMenu';
import SecuritySideMenu from './SecuritySideMenu';
import { HasPermission } from '../modules/authorization/components/HasPermission';
import { AppPermissions } from '../modules/authorization/Permissions';

const { Content, Sider } = Layout;

export interface AppProps {}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ProjectManagementContent: React.FC<any> = ({ router, color }) => {
    return (
        <>
            <Layout>
                <Observer>
                    {() => (
                        <Sider
                            className={`alpha-side-menu ${color} ${router.sideCollapsed ? 'collapsed' : ''}`}
                            theme="light"
                            width={router.sideCollapsed ? 56 : 270}
                            style={{ zIndex: 1000 }}
                        >
                            <>
                                <span
                                    className="sider-collapser"
                                    onClick={() => {
                                        router.setSideCollapsed(!router.sideCollapsed);
                                    }}
                                >
                                    <i className="alpha-icon lg close-panel-icon" />
                                </span>
                                <Route path={ProjectPagesNavigation.ProjectsPage} component={ProjectsSideMenu} />
                            </>
                        </Sider>
                    )}
                </Observer>

                <Layout id="project-layout-wrapper" style={{ background: 'white', position: 'relative' }}>
                    <Content
                        // eslint-disable-next-line max-len
                        style={{
                            background: '#fff',
                            padding: 0,
                            margin: '12 24',
                            minHeight: 280,
                            position: 'relative'
                        }}
                    >
                        <Observer>
                            {() => (
                                <Switch location={router.location}>
                                    <React.Fragment>
                                        {ProjectRoutes()}
                                        {LabelsRoutes()}
                                        {ViewerRoutes()}
                                        {InteractiveRoutes()}
                                        {MLStorageRoutes()}
                                        {AppRoutes()}
                                        {ConnectionsRoutes()}
                                        {SessionsRoutes()}
                                        {RulesRoutes()}
                                        {ReferenceDataRoutes()}
                                        {IotaAuditRoutes()}
                                        {FieldBindingsRoutes()}
                                        {FormTypesRoutes()}
                                        {ProjectSettingsRoutes()}
                                        {TestProjectsRoutes()}
                                        {HistoryRoutes()}
                                        {InstructWorkflowsRoutes()}
                                        {SchemaGeneratorRoutes()}
                                        {DataCrafterTemplatesRoutes()}
                                        {PromptTemplatesRoutes()}
                                    </React.Fragment>
                                </Switch>
                            )}
                        </Observer>
                    </Content>
                    <ErrorNotification />
                </Layout>
            </Layout>
        </>
    );
};

// const RulesManagementContent: React.SFC<Partial<RootStores>> = ({ router }) => {
//   const location = router!.location;

//   return (
//     <Layout>
//       <Layout style={{ padding: '0px' }}>
//         <Content
// eslint-disable-next-line
//           style={{ background: '#fff', padding: 0, margin: '12 24', minHeight: 280, position: 'relative' }}
//         >
//           <Observer>{() => (
//             <Switch location={location}>
//               <React.Fragment>
//                 {RulesRoutes()}
//               </React.Fragment>
//             </Switch>
//           )}
//           </Observer>
//         </Content>
//         <ErrorNotification/>
//       </Layout>
//     </Layout>
//   );
// };

const AdministrationContent: React.FC<Partial<RootStores>> = ({ router }) => {
    const location = router!.location;
    return (
        <Layout>
            <Observer>
                {() => (
                    <Sider
                        className={`alpha-side-menu default ${router!.sideCollapsed ? 'collapsed' : ''}`}
                        theme="light"
                        width={router!.sideCollapsed ? 56 : 270}
                        style={{ zIndex: 1000 }}
                    >
                        <>
                            <span
                                className="sider-collapser"
                                onClick={() => {
                                    router!.setSideCollapsed(!router!.sideCollapsed);
                                }}
                            >
                                <i className="alpha-icon lg close-panel-icon" />
                            </span>
                            <Route
                                path={ProjectPagesNavigation.GlobalAdministrationPage}
                                component={AdministrationSideMenu}
                            />
                        </>
                    </Sider>
                )}
            </Observer>

            <Layout style={{ background: 'white' }}>
                <Content
                    style={{ background: '#fff', padding: 0, margin: '12 24', minHeight: 280, position: 'relative' }}
                >
                    <Observer>
                        {() => (
                            <Switch location={location}>
                                <Route
                                    component={GlobalAdministrationPage}
                                    path={ProjectPagesNavigation.IndexingPage}
                                />
                                <Route component={CodeSnippetsPage} path={ProjectPagesNavigation.CodeSnippetsPage} />
                                <Route component={AlphaConfigPage} path={ProjectPagesNavigation.AlphaConfigPage} />
                                <Route
                                    component={TechnicalInformationPage}
                                    path={ProjectPagesNavigation.TechnicalInformationPage}
                                />
                            </Switch>
                        )}
                    </Observer>
                </Content>
                <ErrorNotification />
            </Layout>
        </Layout>
    );
};

const SecurityContent: React.FC<Partial<RootStores>> = ({ router }) => {
    const location = router!.location;
    return (
        <Layout>
            <Observer>
                {() => (
                    <Sider
                        className={`alpha-side-menu default ${router!.sideCollapsed ? 'collapsed' : ''}`}
                        theme="light"
                        width={router!.sideCollapsed ? 56 : 270}
                        style={{ zIndex: 1000 }}
                    >
                        <>
                            <span
                                className="sider-collapser"
                                onClick={() => {
                                    router!.setSideCollapsed(!router!.sideCollapsed);
                                }}
                            >
                                <i className="alpha-icon lg close-panel-icon" />
                            </span>
                            <Route path={SecurityPagesNavigation.SecurityPage} component={SecuritySideMenu} />
                        </>
                    </Sider>
                )}
            </Observer>
            <Layout style={{ background: 'white' }}>
                <Content
                    style={{ background: '#fff', padding: 0, margin: '12 24', minHeight: 280, position: 'relative' }}
                >
                    <Observer>
                        {() => (
                            <HasPermission
                                permissionClaim={AppPermissions.CanAccessAdministration}
                                yes={() => (
                                    <Switch location={location}>
                                        <Route component={SecurityPage} path={SecurityPagesNavigation.UserManagement} />
                                        <Route
                                            component={ProjectAssignmentPage}
                                            path={SecurityPagesNavigation.ProjectAssignment}
                                        />
                                        <Route
                                            component={RolesManagementPage}
                                            path={SecurityPagesNavigation.RolesManagement}
                                        />
                                    </Switch>
                                )}
                            />
                        )}
                    </Observer>
                </Content>
                <ErrorNotification />
            </Layout>
        </Layout>
    );
};

const ProjectManagementContentObservable = inject(STORE_ROUTER)(observer(ProjectManagementContent));
const SecurityContentObservable = inject(STORE_ROUTER)(observer(SecurityContent));
const AdministrationContentObservable = inject(STORE_ROUTER)(observer(AdministrationContent));

@inject(STORE_PROJECTS_ROOT_UI, STORE_ROUTER)
@observer
export default class App extends React.Component<AppProps, object> {
    componentDidMount() {
        if (process.env.REACT_APP_DISABLE_ANIMATIONS === 'true') {
            document.body.className = 'no-anims';
        }
    }

    render() {
        // const routerStore = this.props[STORE_ROUTER] as RouterStore;
        const projectsVisual = this.props[STORE_PROJECTS_ROOT_UI] as ProjectsRootVisualStore;
        const disableDebug = process.env.REACT_APP_DEBUG_MODE_DISABLED === 'true';
        // const switchMenu = (p: string) => {
        //   if (p === 'Tags') {
        //     routerStore.push(RulesPagesNavigation.RulesListPage);
        //   } else if (p === 'Administration') {
        //     routerStore.push(ProjectPagesNavigation.GlobalAdministrationPage);
        //   } else if (p === 'Security') {
        //       routerStore.push(SecurityPagesNavigation.SecurityPage);
        //   } else {
        //     routerStore.push(ProjectPagesNavigation.ProjectsPage);
        //   }
        // };
        return (
            <>
                {!projectsVisual.isPushServiceConnected ? <PushServiceStatusBar /> : <></>}
                <Switch>
                    <Route exact path="/" render={() => <Redirect to="/projects" />} />
                    <Route path={ViewerPagesNavigation.DocumentViewerPage + '/:path'} component={DocumentViewerPage} />
                    <Route
                        path={'/projects'}
                        render={() => {
                            return (
                                <Layout style={{ height: '100vh' }}>
                                    {/* <Header className="page-header">
                    <div className="logo" />
                    <HeaderMenu onMenuItemSelected={switchMenu} />
                    <UserProfileControls/>
                  </Header> */}
                                    <Observer>
                                        {() => (
                                            <ProjectManagementContentObservable
                                                color={projectsVisual.currentProjectColor}
                                            />
                                        )}
                                    </Observer>
                                </Layout>
                            );
                        }}
                    />
                    {/* <Route
            path={'/rules'}
            render={() => {
              return (
                <Layout style={{ height: '100vh' }}>
                  <Header className="header">
                    <div className="logo" />
                    <HeaderMenu onMenuItemSelected={switchMenu} />
                  </Header>
                  <RulesManagementContentObservable />
                </Layout>
              );
            }}
          /> */}
                    <Route
                        path={'/admin'}
                        render={() => {
                            return (
                                <Layout style={{ height: '100vh' }}>
                                    {/* <Header className="page-header">
                    <div className="logo" />
                    <HeaderMenu onMenuItemSelected={switchMenu} />
                    <UserProfileControls/>
                  </Header> */}
                                    <AdministrationContentObservable />
                                </Layout>
                            );
                        }}
                    />
                    <Route
                        path={'/members'}
                        render={() => {
                            return (
                                <Layout style={{ height: '100vh' }}>
                                    {/* <Header className="page-header">
                    <div className="logo" />
                    <HeaderMenu onMenuItemSelected={switchMenu} />
                    <UserProfileControls/>
                  </Header> */}
                                    <SecurityContentObservable />
                                </Layout>
                            );
                        }}
                    />
                </Switch>
                {disableDebug ? null : <DevTools />}
            </>
        );
    }
}

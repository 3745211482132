export enum AppPermissions {
    CanAddEditDeleteAssignProject = 'project/manage',
    CanEditGroupsTags = 'rules/tags/groups/update',
    CanEditImportExportRules = 'rules/manage',
    CanEditImportExportLabels = 'labels/manage',
    CanEditIotaConnections = 'iota/connections/update',
    CanEditProcessDocuments = 'package/manage',
    CanEditMlStorage = 'mlStorage/update',
    CanManageUsers = 'administration/users/manage',
    CanAccessPortal = 'web/portal/read',
    CanAccessAdministration = 'administration/open',
    CanAccessClientApp = 'Can Access ClientApp',
    CanEditRefDataConnections = 'rules/refDataConnections/update',
    CanEditImportExportRefDataTables = 'rules/refDataTables/manage',
    CanEditIotaApplications = 'iota/applications/update',
    CanAccessAllEntities = 'web/global/readAllEntities',
    CanManageProjectFields = 'projectFields/manage',
    CanManageTestProjects = 'testProjects/manage',
    CanManageFormTemplates = 'formTemplates/manage',
    CanManageDataCrafterTemplates = 'dataCrafterTemplates/manage',
    CanManagePromptTemplates = 'promptTemplates/manage',
    // Client Permissions
    CanSeeAnalysisPagePersonal = 'analysis/read',
    CanSeeAnalysisPageAll = 'analysis/readAll',
    CanOpenSessions = 'sessions/read',
    CanManageSessionsAndDocuments = 'sessions/manage',
    CanAccessArchive = 'archive/read',
    CanMoveToArchive = 'archive/update'
}

export enum AppRoles {
    admin = 'admin',
    user = 'user',
    editor = 'editor',
    client_app = 'client_app',
    auth_level_1 = 'auth_level_1',
    auth_level_2 = 'auth_level_2',
    auth_level_3 = 'auth_level_3',
    regulatory_affairs_manager = 'regulatory_affairs_manager',
    quality_affairs_manager = 'quality_affairs_manager',
    isolated_user = 'isolated_user'
}

const permissions = {
    admin: [
        AppPermissions.CanAccessPortal,
        AppPermissions.CanEditGroupsTags,
        AppPermissions.CanEditImportExportRules,
        AppPermissions.CanEditImportExportLabels,
        AppPermissions.CanEditIotaConnections,
        AppPermissions.CanEditRefDataConnections,
        AppPermissions.CanEditImportExportRefDataTables,
        AppPermissions.CanEditIotaApplications,
        AppPermissions.CanEditProcessDocuments,
        AppPermissions.CanAddEditDeleteAssignProject,
        AppPermissions.CanEditMlStorage,
        AppPermissions.CanManageUsers,
        AppPermissions.CanAccessAdministration,
        AppPermissions.CanSeeAnalysisPagePersonal,
        AppPermissions.CanSeeAnalysisPageAll,
        AppPermissions.CanOpenSessions,
        AppPermissions.CanManageSessionsAndDocuments,
        AppPermissions.CanAccessArchive,
        AppPermissions.CanMoveToArchive,
        AppPermissions.CanAccessAllEntities,
        AppPermissions.CanManageProjectFields,
        AppPermissions.CanManageTestProjects,
        AppPermissions.CanManageFormTemplates,
        AppPermissions.CanManageDataCrafterTemplates
    ],
    editor: [
        AppPermissions.CanAccessPortal,
        AppPermissions.CanEditGroupsTags,
        AppPermissions.CanEditImportExportRules,
        AppPermissions.CanEditImportExportLabels,
        AppPermissions.CanEditIotaConnections,
        AppPermissions.CanEditRefDataConnections,
        AppPermissions.CanEditImportExportRefDataTables,
        AppPermissions.CanEditMlStorage,
        AppPermissions.CanEditIotaApplications,
        AppPermissions.CanEditProcessDocuments,
        AppPermissions.CanSeeAnalysisPagePersonal,
        AppPermissions.CanSeeAnalysisPageAll,
        AppPermissions.CanOpenSessions,
        AppPermissions.CanManageSessionsAndDocuments,
        AppPermissions.CanAccessArchive,
        AppPermissions.CanMoveToArchive,
        AppPermissions.CanAccessAllEntities,
        AppPermissions.CanManageProjectFields,
        AppPermissions.CanManageTestProjects,
        AppPermissions.CanManageFormTemplates,
        AppPermissions.CanManageDataCrafterTemplates
    ],

    user: [
        AppPermissions.CanAccessPortal,
        AppPermissions.CanSeeAnalysisPagePersonal,
        AppPermissions.CanSeeAnalysisPageAll,
        AppPermissions.CanOpenSessions,
        AppPermissions.CanManageSessionsAndDocuments,
        AppPermissions.CanAccessArchive,
        AppPermissions.CanMoveToArchive,
        AppPermissions.CanAccessAllEntities
    ],
    client_app: [
        AppPermissions.CanAccessClientApp,
        AppPermissions.CanSeeAnalysisPagePersonal,
        AppPermissions.CanSeeAnalysisPageAll,
        AppPermissions.CanOpenSessions,
        AppPermissions.CanManageSessionsAndDocuments,
        AppPermissions.CanAccessArchive,
        AppPermissions.CanMoveToArchive,
        AppPermissions.CanAccessAllEntities
    ],
    auth_level_1: [],
    auth_level_2: [],
    auth_level_3: [],
    regulatory_affairs_manager: [],
    quality_affairs_manager: [],
    isolated_user: [
        AppPermissions.CanAccessPortal,
        AppPermissions.CanSeeAnalysisPagePersonal,
        AppPermissions.CanSeeAnalysisPageAll,
        AppPermissions.CanOpenSessions,
        AppPermissions.CanManageSessionsAndDocuments,
        AppPermissions.CanAccessArchive,
        AppPermissions.CanMoveToArchive,
        AppPermissions.CanEditProcessDocuments
    ]
};

export default permissions;

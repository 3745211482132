import * as React from 'react';
import { Select, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { MLModel } from '../../../ml_storage/types';
import './MLModelSelector.less';

interface Props {
    label?: string;
    models: MLModel[];
    isLoading: boolean;
    value?: string;
    onChange?: (modelId: string | undefined) => void;
    placeholder?: string;
    style?: React.CSSProperties;
    disabled?: boolean;
}

/**
 * Component for selecting ML models with search functionality
 *
 * @param label - Optional label to display for the selector (use Form.Item label instead when used in a form)
 * @param models - List of ML models to display
 * @param isLoading - Whether the models are currently loading
 * @param value - Currently selected model ID
 * @param onChange - Function to call when selection changes
 * @param placeholder - Optional placeholder text
 * @param style - Optional additional styles
 * @param disabled - Whether the selector is disabled
 */
const MLModelSelector: React.FC<Props> = ({
    label,
    models,
    isLoading,
    value,
    onChange,
    placeholder = 'Select a model',
    style,
    disabled = false
}) => {
    // Convert models to options format for Select component
    const options = React.useMemo(() => {
        return models.map((model, index) => ({
            value: model.id || `model-${index}`,
            label: model.name
        }));
    }, [models]);

    // Handle selection change
    const handleChange = (modelId: string | undefined) => {
        if (onChange) {
            onChange(modelId);
        }
    };

    const selectComponent = (
        <Select
            showSearch
            allowClear
            style={{ width: '100%' }}
            placeholder={placeholder}
            optionFilterProp="label"
            onChange={handleChange}
            filterOption={(input, option) => (option?.label as string).toLowerCase().indexOf(input.toLowerCase()) >= 0}
            value={value}
            loading={isLoading}
            notFoundContent={isLoading ? <Spin size="small" /> : 'No models found'}
            disabled={disabled || isLoading}
            options={options}
        />
    );

    // If a label is provided, wrap the select in a div with the label
    if (label) {
        return (
            <div className="ml-model-selector" style={style}>
                <div className="ml-model-selector-label">{label}</div>
                {selectComponent}
            </div>
        );
    }

    // Otherwise, just return the select component
    return selectComponent;
};

export default observer(MLModelSelector);

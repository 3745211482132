import { v4 as uuidv4 } from 'uuid';
import { toString } from 'lodash';
import { HistoryFieldDto, HistoryAction } from '../services/types';
import { HistoryHelpers } from '../misc';

export default class HistoryFieldModel {
    readonly key: string;
    readonly name: string;
    readonly value: unknown;
    readonly prevValue?: unknown;
    readonly action?: HistoryAction;
    readonly serialized?: boolean;

    constructor(data: HistoryFieldDto) {
        this.key = uuidv4();
        this.name = data.name;
        this.value = data.value;
        this.prevValue = data.prevValue;
        this.action = data.action;
        this.serialized = data.serialized;
    }

    get stringValue() {
        return toString(HistoryHelpers.valueGetter(this.value)) ?? '';
    }

    get prevStringValue() {
        return toString(HistoryHelpers.valueGetter(this.prevValue)) ?? '';
    }
}

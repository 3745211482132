import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Alert, Space, Skeleton, Progress } from 'antd';
import { CodeOutlined, EyeOutlined, CopyOutlined, ExpandAltOutlined, ShrinkOutlined } from '@ant-design/icons';
import { DataCrafterTemplateFormStore } from '../../stores';
import './DataCrafterTemplateScript.less';
import SyntaxHighlighter from 'react-syntax-highlighter';

interface DataCrafterTemplateScriptProps {
    store: DataCrafterTemplateFormStore;
}

const DataCrafterTemplateScript = ({ store }: DataCrafterTemplateScriptProps) => {
    const [isPreviewLoading, setIsPreviewLoading] = React.useState(false);
    const [isExpanded, setIsExpanded] = React.useState(false);
    const [isCopied, setIsCopied] = React.useState(false);

    const handleCompile = () => {
        store.compileTemplateScriptWithProgress();
    };

    const handlePreview = async () => {
        setIsPreviewLoading(true);
        try {
            await store.runTemplateScript();
        } finally {
            setIsPreviewLoading(false);
        }
    };

    const handleCopy = () => {
        if (store.templateScript) {
            navigator.clipboard.writeText(store.templateScript).then(() => {
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 2000);
            });
        }
    };

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const renderLoadingState = () => {
        if (!store.isLoadingTemplateScript) return null;

        if (store.compilationProgress) {
            const { step, message } = store.compilationProgress;

            return (
                <div className="script-loading">
                    <Progress percent={100} status="active" showInfo={false} />
                    <div className="compilation-message">
                        <p className="step">Step {step}</p>
                        <p className="message">{message}</p>
                    </div>
                </div>
            );
        }

        return (
            <div className="script-loading">
                <Skeleton active />
            </div>
        );
    };

    return (
        <div className="data-crafter-template-script">
            <div className="script-header">
                <div className="action-buttons">
                    <Space>
                        <Button
                            type="primary"
                            icon={<CodeOutlined />}
                            onClick={handleCompile}
                            loading={store.isLoadingTemplateScript}
                            disabled={!store.canCompileScript}
                        >
                            Compile Script
                        </Button>
                        <Button
                            icon={<EyeOutlined />}
                            onClick={handlePreview}
                            loading={isPreviewLoading}
                            disabled={!store.canPreviewScript}
                        >
                            Preview Template
                        </Button>
                    </Space>
                </div>
                {store.templateScript && !store.isLoadingTemplateScript && (
                    <div className="utility-buttons">
                        <Space>
                            <Button
                                size="small"
                                icon={isExpanded ? <ShrinkOutlined /> : <ExpandAltOutlined />}
                                onClick={toggleExpand}
                            >
                                {isExpanded ? 'Collapse' : 'Expand'}
                            </Button>
                            <Button
                                size="small"
                                icon={<CopyOutlined />}
                                onClick={handleCopy}
                                type={isCopied ? 'text' : 'default'}
                            >
                                {isCopied ? 'Copied!' : 'Copy'}
                            </Button>
                        </Space>
                    </div>
                )}
            </div>
            {store.templateScriptError && (
                <Alert message={store.templateScriptError} type="error" showIcon className="script-error" />
            )}
            {renderLoadingState()}
            {!store.isLoadingTemplateScript && store.templateScript && (
                <div className={`script-container ${isExpanded ? 'expanded' : ''}`}>
                    <SyntaxHighlighter language="python" className="script-content">
                        {store.templateScript}
                    </SyntaxHighlighter>
                </div>
            )}
        </div>
    );
};

export default observer(DataCrafterTemplateScript);

import { action, computed, observable, runInAction } from 'mobx';
import { ProjectsRootVisualStore } from '../../common/stores';
import PromptTemplateService from '../services/PromptTemplatesService';
import { message } from 'antd';
import PromptTemplateStore from './PromptTemplatesStore';
import { PromptTemplate } from '../types';

export default class EditPromptTemplateStore {
    @observable
    selectedTemplate: PromptTemplate | null = null;

    @computed
    get currentProject() {
        return this.projectsRootStore.currentProject;
    }
    @observable
    isLoadingTemplate: boolean = false;

    constructor(
        private projectsRootStore: ProjectsRootVisualStore,
        private promptTemplatesUi: PromptTemplateStore,
        private service: PromptTemplateService
    ) {}

    @action.bound
    async initializeWithTemplate(templateId: string) {
        if (!this.promptTemplatesUi.currentProject) {
            return;
        }

        try {
            runInAction(() => {
                this.isLoadingTemplate = true;
            });

            const template = await this.service.getPromptTemplate(this.promptTemplatesUi.currentProject.id, templateId);

            if (template) {
                runInAction(() => {
                    this.selectedTemplate = template;
                });
            }
        } catch (error) {
            console.error('Failed to load template:', error);
            message.error('Failed to load template');
        } finally {
            runInAction(() => {
                this.isLoadingTemplate = false;
            });
        }
    }

    dispose() {
        runInAction(() => {
            this.selectedTemplate = null;
        });
    }
}

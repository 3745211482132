import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Form } from 'antd';
import { DefaultWorkflowParameter } from '../../../instruct_workflows/types';
import WorkflowParameterField from './WorkflowParameterField';

type Props = {
    fieldClassName?: string;
    hideFieldTooltip?: boolean;
    overridenWorkflowParametersName: string;
    overrideWorkflowParameterName: string;
    defaultWorkflowParameters: DefaultWorkflowParameter[];
};

const WorkflowParametersList: React.FC<Props> = ({
    fieldClassName,
    hideFieldTooltip,
    overridenWorkflowParametersName,
    overrideWorkflowParameterName,
    defaultWorkflowParameters
}) => {
    return (
        <Form.List name={overridenWorkflowParametersName}>
            {(fields, { add, remove }) => (
                <>
                    {fields.map((field, index) => {
                        const defaultWorkflowParameter = defaultWorkflowParameters.find((_, i) => i === index);
                        return (
                            <WorkflowParameterField
                                key={field.key}
                                field={field}
                                fieldIndex={index}
                                fieldClassName={fieldClassName}
                                hideFieldTooltip={hideFieldTooltip}
                                overridenWorkflowParametersName={overridenWorkflowParametersName}
                                overrideWorkflowParameterName={overrideWorkflowParameterName}
                                defaultWorkflowParameter={defaultWorkflowParameter}
                                removeField={() => remove(field.name)}
                            />
                        );
                    })}

                    <Form.Item shouldUpdate>
                        {({ getFieldValue }) => (
                            <Button
                                onClick={() => add()}
                                disabled={!getFieldValue(overrideWorkflowParameterName)}
                                block
                            >
                                + Add Custom Parameter
                            </Button>
                        )}
                    </Form.Item>
                </>
            )}
        </Form.List>
    );
};

export default observer(WorkflowParametersList);

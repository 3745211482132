import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react-lite';
import { Form, Input, Button, message } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { Parameter } from '../../types';
import { RulesPipelineVisualStore, RulePreviewVisualStore } from '../../stores';
import { PromptEditorModal } from '..';
import PipelineStep from '../../../pipeline_base/models/PipelineStep';
import PipelineStepType from '../../types/PipelineStepType';
import PipelineVisualStore from '../../stores/PipelineVisualStore';
import './ParametersPromptEditor.less';

type Props<T extends PipelineVisualStore<TPipelineStepType>, TPipelineStepType extends string> = {
    param: Parameter;
    form: FormInstance;
    toolTip: JSX.Element;
    step: PipelineStep<TPipelineStepType>;
    projectId: string;
    store: T;
    stepKey: number;
};

const ParametersPromptEditor: <T extends PipelineVisualStore<S>, S extends string>(
    props: Props<T, S>
) => React.ReactElement<Props<T, S>> = props => {
    const { param, form, toolTip, step, store, stepKey } = props;

    const localStore = useLocalStore(() => ({
        promptModalVisible: false,
        previewStore: null as RulePreviewVisualStore | null,
        setPromptModalVisible(promptModalVisible: boolean) {
            localStore.promptModalVisible = promptModalVisible;
        },
        setPreviewStore(previewStore: RulePreviewVisualStore) {
            localStore.previewStore = previewStore;
        }
    }));

    const handleSubmit = async () => await store.validateForm(form, step, stepKey, false);

    if (!store.isFormSubmissionExists(stepKey)) {
        store.setFormSubmision(handleSubmit, stepKey);
    }

    const handlePromptEditorClose = () => localStore.setPromptModalVisible(false);

    const handlePromptEditorApplyChanges = (prompt: unknown) => {
        if (typeof prompt !== 'string') {
            return;
        }

        const promptParameter = { [param.name]: prompt };
        form.setFieldsValue(promptParameter);
        store.addParameters(promptParameter, step, false);

        handlePromptEditorClose();
    };

    const handlePromptEditorPreview = (prompt: unknown) => {
        if (typeof prompt !== 'string' || !localStore.previewStore) {
            return;
        }

        if (!form.getFieldValue('instruct_workflow_id')) {
            message.error('Please select instruct workflow');
            return;
        }

        localStore.previewStore.executeStepPreview({
            stepIndex: localStore.previewStore.rule.pipeline.indexOf(step as PipelineStep<PipelineStepType>),
            parameterName: param.name,
            parameterValue: prompt,
            includePreviousSteps: true
        });
    };

    React.useEffect(() => {
        const pipelineStore = store instanceof RulesPipelineVisualStore ? (store as RulesPipelineVisualStore) : null;

        if (pipelineStore) {
            localStore.setPreviewStore(
                new RulePreviewVisualStore(
                    pipelineStore.rule,
                    pipelineStore.rulesStore,
                    pipelineStore.rulesStore.rootStore
                )
            );
        }

        return () => {
            if (localStore.previewStore) {
                localStore.previewStore.dispose();
            }
        };
    }, [store, step, localStore]);

    return (
        <div className="parameters-prompt-editor-container">
            {store.currentProject && localStore.previewStore && (
                <PromptEditorModal
                    previewPromptFormId={`preview-prompt-form-${step.internalId}`}
                    modalVisible={localStore.promptModalVisible}
                    previewStore={localStore.previewStore}
                    projectId={store.currentProject.id}
                    initialPrompt={step.parameters.get(param.name)}
                    handleApplyChanges={handlePromptEditorApplyChanges}
                    handlePromptPreview={handlePromptEditorPreview}
                    handleClose={handlePromptEditorClose}
                />
            )}

            <Button
                className="open-editor-button"
                type="primary"
                size="small"
                onClick={() => localStore.setPromptModalVisible(true)}
            >
                Open editor
            </Button>

            <Form.Item
                label={toolTip}
                name={param.name}
                initialValue={param.defaultValue}
                rules={[
                    {
                        required: !step.isDisabled && param.required,
                        message: 'Please input prompt!'
                    }
                ]}
            >
                <Input.TextArea autoSize={{ minRows: 4 }} />
            </Form.Item>
        </div>
    );
};

export default observer(ParametersPromptEditor);

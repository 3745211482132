import { action, computed, observable } from 'mobx';
import { message } from 'antd';
import { ProjectsRootVisualStore } from '../../common/stores';
import { UploadedPackagesVisualStore } from '.';
import { ProjectsService } from '../../common/services';
import { PackageResult } from '../../common/types';

export default class UpdatePackagesFeatureFlagsStore {
    @observable
    modalVisible: boolean = false;

    constructor(
        private readonly projectsStore: ProjectsRootVisualStore,
        private readonly packagesStore: UploadedPackagesVisualStore,
        private readonly service: ProjectsService
    ) {}

    @action
    setModalVisible(modalVisible: boolean) {
        this.modalVisible = modalVisible;
    }

    @action
    async updatePackagesFeatureFlags(featureFlags: Record<string, string> | null) {
        const packageIds = [...this.packagesToUpdate];

        if (!packageIds.length) {
            return;
        }

        this.packagesStore.resetPackagesSelection();

        message.info('Feature flags update started');

        const resp = await this.service.updatePackagesFeatureFlags(packageIds, featureFlags);

        if (resp.isErr()) {
            message.error('Failed to update feature flags');
            return;
        }

        message.success('Feature flags updated. Started reparsing');
        resp.map(updatedPackages => this.applyUpdatedFeatureFlags(updatedPackages));
    }

    @computed
    get project() {
        return this.projectsStore.currentProject;
    }

    @computed
    get projectFeatureFlags() {
        return this.project?.storedFeatureFlags;
    }

    @computed
    get packagesToUpdate() {
        return this.packagesStore.selectedPackages.filter(id => !this.packagesStore.protectedPackageIds.includes(id));
    }

    @computed
    get menuOptionVisible() {
        return this.packagesToUpdate.length > 0;
    }

    private applyUpdatedFeatureFlags(updatedPackages: PackageResult[]) {
        updatedPackages.forEach(updatedPackage => {
            const existingPackage = this.packagesStore.currentProjectPackages.find(p => p.id === updatedPackage.id);

            if (existingPackage) {
                existingPackage.updateFeatureFlags(updatedPackage.featureFlags);
            }
        });

        this.packagesStore.refreshCurrentProjectPackages();
    }
}

import { Button, Col, Input, Row, Select, message } from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import { inject } from 'mobx-react';
import { observer, useLocalStore } from 'mobx-react-lite';
import * as React from 'react';
import { RuleEditGeneralSettings, PromptEditorModal, WorkflowParametersRenderer } from '..';
import { STORE_RULE_EDIT } from '../../constants';
import SmartIndexRule from '../../models/SmartIndexRule';
import { RuleEditVisualStore, RulePreviewVisualStore, RulesStore } from '../../stores';
import RuleType from '../../types/RuleType';
import { FormFieldsUtils } from '../../misc';
import { InstructWorkflowParametersStore } from '../../../instruct_workflows/stores';
import './RuleEditSmartIndexForm.less';

type Props = {
    store: RuleEditVisualStore;
    rulesStore: RulesStore;
    selectType?: (type: RuleType) => void;
    form: FormInstance;
};

const RuleEditSmartIndexForm: React.FC<Props> = ({ store, rulesStore, selectType, form }) => {
    const rule = store.editableRule as SmartIndexRule;

    const localStore = useLocalStore(() => ({
        promptModalVisible: false,
        previewStore: null as RulePreviewVisualStore | null,
        instructWorkflowStore: new InstructWorkflowParametersStore(store.currentProject?.id),
        setPromptModalVisible(promptModalVisible: boolean) {
            this.promptModalVisible = promptModalVisible;
        },
        setPreviewStore(previewStore: RulePreviewVisualStore) {
            this.previewStore = previewStore;
        }
    }));

    const onValuesChange = (changedValues: Record<string, unknown>) => {
        if ('instructWorkflowId' in changedValues) {
            form.setFieldsValue({ outputSchemeName: undefined });

            localStore.instructWorkflowStore.setSelectedWorkflowId(
                changedValues.instructWorkflowId as string | undefined
            );

            form.setFieldsValue({
                overriddenWorkflowParameters: localStore.instructWorkflowStore.getMergedWorkflowParameters({})
            });
        }
    };

    const getInitialValues = React.useCallback(() => {
        return {
            name: rule.name,
            tag: rule.tag,
            instructWorkflowId: rule.instructWorkflowId,
            priority: rule.priority,
            description: rule.description,
            overridePriority: rule.overridePriority,
            prompt: rule.prompt,
            outputSchemeName: rule.outputSchemeName,
            overrideWorkflowParameters: rule.overrideWorkflowParameters
        };
    }, [rule]);

    const setInitialWorkflowParameters = React.useCallback(() => {
        form.setFieldsValue({
            overriddenWorkflowParameters: localStore.instructWorkflowStore.getMergedWorkflowParameters(
                rule.overriddenWorkflowParameters
            )
        });
    }, [form, localStore.instructWorkflowStore, rule.overriddenWorkflowParameters]);

    React.useEffect(() => {
        if (!localStore.instructWorkflowStore.loading) {
            setInitialWorkflowParameters();
        }
    }, [localStore.instructWorkflowStore.loading, setInitialWorkflowParameters]);

    React.useEffect(() => {
        const initialValues = getInitialValues();
        form.setFieldsValue(initialValues);
        localStore.instructWorkflowStore.setSelectedWorkflowId(initialValues.instructWorkflowId);
    }, [form, localStore.instructWorkflowStore, rule, getInitialValues]);

    React.useEffect(() => {
        localStore.instructWorkflowStore.load();
    }, [localStore.instructWorkflowStore]);

    const handlePromptEditorClose = () => localStore.setPromptModalVisible(false);

    const handlePromptEditorApplyChanges = (prompt: unknown) => {
        if (typeof prompt === 'string') {
            form.setFieldsValue({ prompt });
        }

        handlePromptEditorClose();
    };

    const handlePromptEditorPreview = (prompt: unknown) => {
        if (typeof prompt !== 'string' || !store.rule || !localStore.previewStore) {
            return;
        }

        const requiredFields = [
            { label: 'name', value: form.getFieldValue('name') },
            { label: 'tag', value: form.getFieldValue('tag') },
            { label: 'instruct workflow', value: form.getFieldValue('instructWorkflowId') }
        ] as const;

        const missingRequiredFields = requiredFields.filter(f => !f.value).map(f => f.label);

        if (missingRequiredFields.length) {
            message.error(`Please fill in required fields: ${missingRequiredFields.join(', ')}`);
            return;
        }

        const formFields = FormFieldsUtils.transform({ ...form.getFieldsValue(), prompt });
        const ruleCopy = store.transformFieldsToRule(rule, formFields);
        ruleCopy.pipeline = [];
        localStore.previewStore.execute(ruleCopy);
    };

    React.useEffect(() => {
        if (store.rule) {
            localStore.setPreviewStore(new RulePreviewVisualStore(store.rule, rulesStore, rulesStore.rootStore));
        }

        return () => {
            if (localStore.previewStore) {
                localStore.previewStore.dispose();
            }
        };
    }, [store.rule, rulesStore, localStore]);

    return (
        <>
            {store.currentProject && localStore.previewStore && (
                <PromptEditorModal
                    previewPromptFormId="rule-edit-preview-prompt-form"
                    modalVisible={localStore.promptModalVisible}
                    previewStore={localStore.previewStore}
                    projectId={store.currentProject.id}
                    initialPrompt={form.getFieldValue('prompt')}
                    handleApplyChanges={handlePromptEditorApplyChanges}
                    handlePromptPreview={handlePromptEditorPreview}
                    handleClose={handlePromptEditorClose}
                />
            )}

            {store.error.map((e, i) => (
                <h4 key={e + i} style={{ color: 'red' }}>
                    {e}
                </h4>
            ))}
            <Form
                form={form}
                layout="vertical"
                className="rule-edit-form"
                data-id="rule-edit-form"
                onValuesChange={onValuesChange}
            >
                <RuleEditGeneralSettings store={store} selectType={selectType} form={form} />
                <Row gutter={16}>
                    <Col className="gutter-row" span={24}>
                        <Form.Item
                            label="Instruct Wokflow"
                            name="instructWorkflowId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input Instruct Wokflow!'
                                }
                            ]}
                            data-id="rule-query-input"
                        >
                            <Select
                                className={`instruct-workflow-select ${localStore.instructWorkflowStore.loading ? 'loading' : ''}`}
                                loading={localStore.instructWorkflowStore.loading}
                                allowClear
                            >
                                {localStore.instructWorkflowStore.workflows.map(f => (
                                    <Select.Option key={f.id} value={f.id}>
                                        {f.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" span={24}>
                        <Button
                            className="open-editor-button"
                            type="primary"
                            size="small"
                            onClick={() => localStore.setPromptModalVisible(true)}
                        >
                            Open editor
                        </Button>

                        <Form.Item
                            label="Prompt"
                            name="prompt"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input Prompt!'
                                }
                            ]}
                            data-id="rule-query-input"
                        >
                            <Input.TextArea style={{ height: 'unset' }} rows={4} />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" span={24}>
                        <WorkflowParametersRenderer
                            loading={localStore.instructWorkflowStore.loading}
                            overridenWorkflowParametersName="overriddenWorkflowParameters"
                            overrideWorkflowParameterName="overrideWorkflowParameters"
                            defaultWorkflowParameters={localStore.instructWorkflowStore.defaultWorkflowParameters}
                            hideFieldTooltip
                        />
                    </Col>

                    <Col className="gutter-row" span={24}>
                        <Form.Item label="Output Scheme" name="outputSchemeName" data-id="rule-query-input">
                            <Select allowClear>
                                {localStore.instructWorkflowStore.workflow?.outputSchemes.map((s, i) => (
                                    <Select.Option key={i + s.name} value={s.name}>
                                        {s.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default inject(STORE_RULE_EDIT)(observer(RuleEditSmartIndexForm));

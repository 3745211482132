import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { HistoryHelpers } from '../../misc';
import { HistoryStore } from '../../stores';
import { BaseHistoryRecordModel, HistoryFieldModel } from '../../models';
import HistorySelectedFieldModal from './HistorySelectedFieldModal';
import './HistoryGrid.less';

interface Props {
    store: HistoryStore;
    historyRecord: BaseHistoryRecordModel;
}

const HistoryRecordExpandedRow: React.FC<Props> = ({ store, historyRecord }) => {
    const [selectedField, setSelectedField] = React.useState<HistoryFieldModel | null>(null);

    const columns: ColumnsType<HistoryFieldModel> = React.useMemo(() => {
        return [
            {
                title: 'Field',
                dataIndex: 'name',
                key: 'name',
                width: '25%',
                render: (value: string) => HistoryHelpers.formatFieldName(value)
            },
            {
                title: 'Value',
                dataIndex: 'value',
                key: 'value',
                width: '25%',
                render: (_: unknown, record: HistoryFieldModel) => (
                    <div className="expanded-row-value">
                        <a onClick={() => setSelectedField(record)}>{record.stringValue}</a>
                    </div>
                )
            },
            {
                title: 'Previous Value',
                dataIndex: 'prevValue',
                key: 'prevValue',
                width: '25%',
                render: (_: unknown, record: HistoryFieldModel) => (
                    <div className="expanded-row-value">
                        <a onClick={() => setSelectedField(record)}>{record.prevStringValue}</a>
                    </div>
                )
            },
            {
                title: 'Action',
                dataIndex: 'action',
                key: 'action',
                width: '25%'
            }
        ];
    }, []);

    return (
        <>
            <HistorySelectedFieldModal
                store={store}
                historyField={selectedField}
                onClose={() => setSelectedField(null)}
            />

            <Table
                className="nested-history-table"
                rowKey={r => r.name}
                dataSource={historyRecord.fields}
                columns={columns}
                pagination={false}
            />
        </>
    );
};

export default observer(HistoryRecordExpandedRow);

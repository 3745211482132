export const STORE_PROMPT_TEMPLATES = 'promptTemplatesUI';
export type STORE_PROMPT_TEMPLATES = typeof STORE_PROMPT_TEMPLATES;

export const STORE_PROMPT_TEMPLATE_EDIT = 'promptTemplateEditUI';
export type STORE_PROMPT_TEMPLATE_EDIT = typeof STORE_PROMPT_TEMPLATE_EDIT;

export const STORE_PROMPT_TEMPLATES_IMPORT_EXPORT = 'promptTemplatesImportExportUI';
export type STORE_PROMPT_TEMPLATES_IMPORT_EXPORT = typeof STORE_PROMPT_TEMPLATES_IMPORT_EXPORT;

export const STORE_PROMPT_DEPENDENT_PACKAGES = 'propmtDependentPackagesStore';
export type STORE_PROMPT_DEPENDENT_PACKAGES = typeof STORE_PROMPT_DEPENDENT_PACKAGES;

import * as React from 'react';
import { inject, observer } from 'mobx-react';
import EditPromptTemplate from '../components/CreatePromptTemplate/EditPromptTemplate';
import { PromptTemplateStores } from '../stores';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import {
    STORE_PROMPT_TEMPLATE_EDIT,
    STORE_PROMPT_TEMPLATES,
    STORE_PROMPT_DEPENDENT_PACKAGES
} from '../stores/constants';

const EditPromptTemplateScreen: React.FC<PromptTemplateStores> = ({
    promptTemplatesUI: store,
    promptTemplateEditUI: editStore,
    propmtDependentPackagesStore
}) => {
    return (
        <HasPermission permissionClaim={AppPermissions.CanManagePromptTemplates}>
            <EditPromptTemplate
                store={store!}
                editStore={editStore!}
                propmtDependentPackagesStore={propmtDependentPackagesStore!}
            />
        </HasPermission>
    );
};

export default inject(
    STORE_PROMPT_TEMPLATES,
    STORE_PROMPT_TEMPLATE_EDIT,
    STORE_PROMPT_DEPENDENT_PACKAGES
)(observer(EditPromptTemplateScreen));

import * as React from 'react';
import { runInAction } from 'mobx';
import { observer, useLocalStore } from 'mobx-react-lite';
import { Select } from 'antd';
import Form from 'antd/lib/form';
import PipelineVisualStore from '../../stores/PipelineVisualStore';
import { MLService } from '../../../ml_storage/services';
import MLModel from '../../../ml_storage/models/MLModel';
import useDisplayConditions from '../../hooks/useDisplayConditions';
import { Props } from './types';
import './ParametersCustomModelSelect.less';

const ParametersCustomModelSelect: <T extends PipelineVisualStore<S>, S extends string>(
    props: Props<T, S> & { modelType: 'embedding' | 'cross-encoder' }
) => React.ReactElement<Props<T, S>> = props => {
    const { param, form, toolTip, step, projectId, store, stepKey, modelType } = props;

    const handleSubmit = async () => {
        await store.validateForm(form, step, stepKey, false);
    };

    if (!store.isFormSubmissionExists(stepKey)) {
        store.setFormSubmision(handleSubmit, stepKey);
    }

    const localState = useLocalStore(() => ({
        loading: false,
        service: new MLService(),
        models: new Array<MLModel>(),
        async load() {
            runInAction(() => {
                localState.loading = true;
            });

            const mlModels = await this.service.getMLModelsForProject(projectId);

            runInAction(() => {
                localState.models = mlModels.filter(m => m.type === modelType);
                localState.loading = false;
            });
        }
    }));

    React.useEffect(() => {
        localState.load();
    }, [localState]);

    const { isVisible } = useDisplayConditions({ param, form, store, step });

    return (
        <div className={`custom-model-select-container ${!isVisible ? 'hidden' : ''}`}>
            <Form.Item label={toolTip} name={param.name} initialValue={param.defaultValue}>
                <Select className={`${localState.loading ? 'loading' : ''}`} loading={localState.loading} allowClear>
                    {localState.models.map(m => (
                        <Select.Option key={m.id!} value={m.id!}>
                            {m.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        </div>
    );
};

export default observer(ParametersCustomModelSelect);

/* eslint-disable dot-notation */
/* eslint-disable @typescript-eslint/member-ordering */
import { computed, action, observable, runInAction, reaction } from 'mobx';
import PipelineStep from '../../pipeline_base/models/PipelineStep';
import { OnHitEventArgs } from 'react-drag-drop-container';
import PipelineVisualStore, { UserDefinedParams } from './PipelineVisualStore';
import { EditorModel } from '../types';
import RulesStore from './RulesStore';
import type { RuleTypes } from '../../rules/models';
import type PipelineStepType from '../types/PipelineStepType';
import { getFullSnippetPath } from '../../common/Utils';
import { CompileDummyCodeType } from '../types';
import { FormInstance } from 'antd/lib/form';
import { TagModel } from '../models/TagModel';

export interface CurrentUiState {
    [key: number]: string;
}

export default class RulesPipelineVisualStore extends PipelineVisualStore<PipelineStepType> {
    @computed
    get pipeline() {
        return this.rule.pipeline;
    }

    @computed
    get currentProject() {
        return this.rulesStore.currentProject;
    }

    @observable
    currentStep: PipelineStep<PipelineStepType>;

    @observable
    error: CurrentUiState = {};

    @observable
    description: CurrentUiState = {};

    @observable
    isLoading: boolean = false;

    @observable
    rule: RuleTypes;

    swapPipelineStepIndex: number;

    editorModel: EditorModel<PipelineStepType>;

    @observable
    emptyParams: string[] = [];

    @computed
    get rules() {
        return this.rulesStore.rules;
    }

    @computed
    get ruleTags() {
        return this.rulesStore.ruleTags;
    }

    @computed
    get tagsWithRules() {
        let tagList: { tag: TagModel; rules: RuleTypes[] }[] = [];
        if (this.ruleTags && this.rules) {
            for (let tag of this.ruleTags) {
                tagList.push({ tag: tag, rules: this.rules.filter(r => r.tagId === tag.id) });
            }
            return tagList;
        }

        return undefined;
    }

    constructor(
        public readonly ruleToEdit: RuleTypes,
        private onChange: () => void,
        public readonly rulesStore: RulesStore
    ) {
        super();
        this.setRule(ruleToEdit);
    }

    @action.bound
    setRule(rule: RuleTypes) {
        this.rule = rule;
    }

    @action.bound
    newStep() {
        this.currentStep = new PipelineStep('Get strings after', 'GetStringsAfter', {});
        this.rule.pipeline.push(this.currentStep);
        this.onChange();
    }

    @action.bound
    removeStep(step: PipelineStep<PipelineStepType>) {
        console.log('removing step...', step);
        var index = this.rule.pipeline.indexOf(step);
        if (index > -1) {
            this.removeFormSubmission(index);
            this.rule.pipeline.splice(index, 1);
            this.onChange();
        }
    }

    @action.bound
    swapPipeLineSteps(e: OnHitEventArgs, index: number) {
        this.pipelineFormSubmissions = {};
        this.rule.pipeline.splice(this.swapPipelineStepIndex, 1);
        this.rule.pipeline.splice(index, 0, e.dragData.pipelineStep.props.step);
        this.onChange();
    }

    @action.bound
    setName(step: PipelineStep<PipelineStepType>, name: string) {
        step.setName(name);
        this.onChange();
    }

    @action.bound
    setType(step: PipelineStep<PipelineStepType>, type: PipelineStepType) {
        step.setType(type);
        this.onChange();
    }

    @action.bound
    addParameters(
        parameters: UserDefinedParams | Object,
        step: PipelineStep<PipelineStepType>,
        isUserDefined: boolean,
        stepKey?: number
    ) {
        if (isUserDefined) {
            const paramCount = Object.keys(parameters).length / 2;
            [...Array(paramCount).keys()].forEach(i => {
                step.addParameter(
                    (<UserDefinedParams>parameters)[`name${i}`],
                    (<UserDefinedParams>parameters)[`value${i}`]
                );
            });
            this.removeEmptyParamForUserDefinedCard(stepKey!);
        } else {
            const keysList = Object.getOwnPropertyNames(parameters);
            // @ts-ignore
            keysList.forEach(key => step.addParameter(key, parameters[key]));
        }
    }

    @action.bound
    removeParameter(step: PipelineStep<PipelineStepType>, key: string) {
        step.parameters.delete(key);
        this.onChange();
    }

    @action.bound
    setDescription(key: number, type: PipelineStepType) {
        if (this.editorModel) {
            this.description[key] = this.editorModel[type]!.description;
        } else {
            reaction(
                () => this.editorModel,
                (m, r) => {
                    this.setDescription(key, type);
                    r.dispose();
                }
            );
        }
    }

    @action.bound
    async getEditorModels() {
        this.isLoading = true;
        this.editorModel = (await this.rulesStore.getEditorModels())!;
        runInAction(() => {
            this.isLoading = false;
        });
    }

    async validateForm(
        form: FormInstance,
        step: PipelineStep<PipelineStepType>,
        stepKey: number,
        isUserDefined: boolean
    ) {
        return form
            .validateFields()
            .then(() => {
                const parameters = form.getFieldsValue();
                this.addParameters(parameters, step, isUserDefined, stepKey);
                this.setValidationFailed(stepKey, false);
            })
            .catch(() => {
                this.setValidationFailed(stepKey, true);
            });
    }

    isFormSubmissionExists(stepKey: number) {
        return !!this.pipelineFormSubmissions[stepKey];
    }

    @action.bound
    async executeBusinessRuleCode(code: string, compileDummyCodeType?: CompileDummyCodeType) {
        return this.rulesStore.executeBusinessRuleCode(code, compileDummyCodeType);
    }

    @action.bound
    async executeBusinessRuleQuery(connectionId: string, query: string) {
        return this.rulesStore.executeBusinessRuleQuery(connectionId, query);
    }

    @action.bound
    async validateRegexPatternValue(
        value: string,
        regex: string,
        stepType: PipelineStepType,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        parameters: Map<string, any>
    ) {
        return this.rulesStore.validateRegexPatternValue(value, regex, stepType, parameters, this.rule.id!);
    }

    @action.bound
    async loadCodeSnippets() {
        var result = await this.rulesStore.getCodeSnippets();
        if (result) {
            runInAction(() => {
                this.codeSnippets = result!;
                this.createFullCodeSnippetPaths();
            });
        }
    }

    @action.bound
    async loadCodeSnippetGroups() {
        var result = await this.rulesStore.getCodeSnippetGroups();
        if (result) {
            runInAction(() => {
                this.codeSnippetGroups = result!;
                this.createFullCodeSnippetPaths();
            });
        }
    }

    @action.bound
    createFullCodeSnippetPaths() {
        if (this.codeSnippets.length && this.codeSnippetGroups.length) {
            for (let snippet of this.codeSnippets) {
                snippet.fullSnippetGroupPath = getFullSnippetPath(snippet.groupId!, this.codeSnippetGroups);
            }
        }
    }
}

import { Alert, Button, Collapse, InputNumber, Layout, Skeleton, Table, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { uniqBy } from 'lodash';
import { Utils } from '../../common/services/Utils';
import LayoutHeader from '../../../components/LayoutHeader';
import { TestProjectPackageActions } from '.';
import { TestProjectDashboardStore } from '../stores';
import { TestRunTopic } from '../types';
import { ColumnType } from 'antd/lib/table';
import TestProjectSortingActions from './TestProjectSortingActions/TestProjectSortingActions';
import { Constants } from '../misc';
import TextArea from 'antd/lib/input/TextArea';
import { CopyOutlined } from '@ant-design/icons';

type Props = {
    store: TestProjectDashboardStore;
};

const TestProjectRunResultList: React.FC<Props> = ({ store }) => {
    const [packageSortDirection, setPackageSortDirection] = React.useState<'ascend' | 'descend'>('ascend');
    const [packageSortField, setPackageSortField] = React.useState<string>(Constants.PACKAGE_SORT_FIELD_NAME);
    const [isEditModeOn, setIsEditModeOn] = React.useState(false);
    const [openPanelKeys, setOpenPanelKeys] = React.useState<string[]>([]);

    React.useEffect(() => {
        if (store.currentTestRunId) {
            store.loadTestRunResults();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.currentTestRunId]);

    React.useEffect(() => {
        const readyToLoad = store.currentProject && store.currentTestProjectId;
        const shouldLoadTestProject = !store.testProject || store.testProject.id !== store.currentTestProjectId;

        if (readyToLoad && shouldLoadTestProject) {
            store.loadTestProject();
        }

        if (readyToLoad) {
            store.loadTestProjectBaselines();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.currentProject, store.currentTestProjectId]);

    const packageListItems = React.useMemo(() => {
        const testResults = store.currentTestRunResults ? store.currentTestRunResults.topics.slice() : [];

        const uniqField = testResults.every(t => t.packageId != null) ? 'packageId' : 'packageName';
        let items = uniqBy(testResults, uniqField).map(t => ({ packageId: t.packageId, packageName: t.packageName }));

        if (packageSortField === Constants.PACKAGE_SORT_FIELD_NAME) {
            items = items.sort((a, b) => a.packageName.localeCompare(b.packageName));
        }

        // Don't sort in case sort by date is needed, because package ids are stored in the same order as they were loaded

        if (packageSortDirection === 'descend') {
            items = items.reverse();
        }

        return items;
    }, [store.currentTestRunResults, packageSortField, packageSortDirection]);

    if (!store.currentTestRunResults) {
        return null;
    }

    const getTitle = () => {
        if (store.testResultIsLoading || !store.testProject?.name || !store.currentTestRunResults) {
            return <Skeleton active paragraph={{ rows: 0 }} />;
        }

        return `${store.testProject?.name} - ${Utils.formatDateStringShort(store.currentTestRunResults.runTime)} run results`;
    };

    const loadingContent = <Skeleton active paragraph={{ rows: 4 }} title={{ style: { display: 'none' } }} />;

    const getRunResultButtons = (topic: TestRunTopic) => {
        const viewInDocumentButton = (
            <Tooltip title="View results in document">
                <Button
                    type="link"
                    icon={
                        <i
                            className="alpha-icon xs form-anchor"
                            style={{ backgroundSize: 'contain', verticalAlign: 'middle' }}
                        />
                    }
                    onClick={() =>
                        store.openPreviewTopicResultPreviewWindow(
                            topic.packageId,
                            `${topic.packageId}-${topic.name}`,
                            topic.metaFields
                        )
                    }
                />
            </Tooltip>
        );

        return (
            <div className="topic-result-actions">
                <Tooltip title="Copy result to clipboard">
                    <Button
                        type="link"
                        size="small"
                        icon={<CopyOutlined />}
                        onClick={() => copyResultToClipboard(topic.result)}
                    />
                </Tooltip>
                {topic.metaFields != null && topic.metaFields.length > 0 && viewInDocumentButton}
            </div>
        );
    };

    const copyResultToClipboard = (result: string) => {
        navigator.clipboard.writeText(result);
    };

    const handleFocus = (
        e: React.FocusEvent<HTMLTextAreaElement> | React.FocusEvent<HTMLInputElement>,
        pkg: string
    ) => {
        const currentPackageIndex = packageListItems.findIndex(p => p.packageName === pkg || p.packageId === pkg);

        if (
            currentPackageIndex === -1 ||
            packageListItems.length === 0 ||
            currentPackageIndex === packageListItems.length - 1
        ) {
            return;
        }

        const nextPackage =
            currentPackageIndex === packageListItems.length - 1
                ? packageListItems[0]
                : packageListItems[currentPackageIndex + 1];

        if (openPanelKeys.includes(nextPackage.packageId ?? nextPackage.packageName)) {
            return;
        }

        setOpenPanelKeys(prev => [...prev, nextPackage.packageId ?? nextPackage.packageName]);
    };

    const getBaselineCellDisplayContent = (topic: TestRunTopic) => {
        if (topic.modifiedBaseline) {
            return (
                <span style={{ whiteSpace: 'pre-wrap' }}>
                    <strong>Original value: </strong>
                    {topic.baseline}
                    <br />
                    <strong>New value: </strong>
                    {topic.modifiedBaseline}
                </span>
            );
        }

        return <span style={{ whiteSpace: 'pre-wrap' }}>{topic.baseline}</span>;
    };

    const getBaselineCellContent = (topic: TestRunTopic, tabIndex: number, isLast: boolean) => {
        if (!isEditModeOn) {
            return getBaselineCellDisplayContent(topic);
        }

        const focusHandler = isLast
            ? (e: React.FocusEvent<HTMLTextAreaElement>) => handleFocus(e, topic.packageId ?? topic.packageName)
            : undefined;

        const blurHandler = (e: React.FocusEvent<HTMLTextAreaElement>) => {
            if (topic.modifiedBaseline === e.target.value) {
                return;
            }

            if (topic.baseline === e.target.value && !topic.modifiedBaseline) {
                return;
            }

            if (!topic.baselineId) {
                return;
            }

            store.updateBaselineFromTestRun(topic.baselineId, e.target.value);
        };

        return (
            <div>
                <TextArea
                    tabIndex={tabIndex}
                    defaultValue={topic.modifiedBaseline ?? topic.baseline}
                    onFocus={focusHandler}
                    onBlur={blurHandler}
                    autoSize
                />
            </div>
        );
    };

    const getBaselineFuzzyCellDisplayContent = (topic: TestRunTopic) => {
        if (topic.modifiedBaselineFuzzy) {
            return (
                <span style={{ whiteSpace: 'pre-wrap' }}>
                    <strong>Original value: </strong>
                    {topic.baselineFuzzy}
                    <br />
                    <strong>New value: </strong>
                    {topic.modifiedBaselineFuzzy}
                </span>
            );
        }

        return (
            <span style={{ whiteSpace: 'pre-wrap' }}>
                {topic.baselineFuzzy != null ? `${topic.baselineFuzzy}%` : 'N/A'}
            </span>
        );
    };

    const getBaselineFuzzyCellContent = (topic: TestRunTopic, tabIndex: number, isLast: boolean) => {
        if (!isEditModeOn) {
            return getBaselineFuzzyCellDisplayContent(topic);
        }

        const focusHandler = isLast
            ? (e: React.FocusEvent<HTMLInputElement>) => handleFocus(e, topic.packageId ?? topic.packageName)
            : undefined;

        const blurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
            const numericValue = parseInt(e.target.value, 10);

            if (isNaN(numericValue)) {
                return;
            }
            if (topic.modifiedBaselineFuzzy === numericValue) {
                return;
            }

            if (topic.baselineFuzzy === numericValue && !topic.modifiedBaselineFuzzy) {
                return;
            }

            if (!topic.baselineId) {
                return;
            }

            store.updateBaselineFuzzyFromTestRun(topic.baselineId, numericValue);
        };

        return (
            <div>
                <InputNumber
                    tabIndex={tabIndex}
                    defaultValue={topic.modifiedBaselineFuzzy ?? topic.baselineFuzzy}
                    onFocus={focusHandler}
                    onBlur={blurHandler}
                    min={0}
                    max={100}
                />
            </div>
        );
    };

    const runContentForPackage = (packageName: string, packageId: string | null) => {
        if (!store.currentTestRunResults) {
            return null;
        }

        const packageIndex = packageListItems.findIndex(p => p.packageId === packageId);

        const columns: ColumnType<TestRunTopic>[] = [
            {
                title: 'Topic',
                dataIndex: 'name',
                key: 'name',
                width: '25%'
            },
            {
                title: 'Baseline',
                dataIndex: 'baseline',
                key: 'baseline',
                width: '35%',
                render: (_: string, topic: TestRunTopic, index: number) =>
                    getBaselineCellContent(topic, topics.length * packageIndex + index + 1, index === topics.length - 1)
            },
            {
                title: 'Result',
                dataIndex: 'result',
                key: 'result',
                width: '40%',
                render: (result: string, record: TestRunTopic) => {
                    const color = record.fuzzy >= record.baselineFuzzy ? 'black' : 'red';
                    return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ color: color, whiteSpace: 'pre-wrap', flex: '1 1' }}>{result}</div>
                            {getRunResultButtons(record)}
                        </div>
                    );
                }
            },
            {
                title: 'Baseline Fuzzy %',
                dataIndex: 'baselineFuzzy',
                key: 'baselineFuzzy',
                width: 140,
                render: (_: string, topic: TestRunTopic, index: number) =>
                    getBaselineFuzzyCellContent(
                        topic,
                        topics.length * packageIndex + index + 1,
                        index === topics.length - 1
                    )
            },
            {
                title: 'Result Fuzzy %',
                dataIndex: 'fuzzy',
                key: 'fuzzy',
                width: 125,
                render: (fuzzy: number) => (fuzzy != null ? `${fuzzy}%` : 'N/A')
            }
        ];

        let topics: TestRunTopic[];

        // If packageId is present, use it to filter topics, otherwise use packageName. For backwards compatibility.
        const allResultsHavePackageId = store.currentTestRunResults.topics.every(t => t.packageId != null);
        if (allResultsHavePackageId && packageId != null) {
            topics = store.currentTestRunResults.topics
                .filter(t => t.packageId === packageId)
                .sort((a, b) => a.name.localeCompare(b.name));
        } else {
            topics = store.currentTestRunResults.topics
                .filter(t => t.packageName === packageName)
                .sort((a, b) => a.name.localeCompare(b.name));
        }

        return (
            <Table
                tableLayout="fixed"
                columns={columns}
                dataSource={topics}
                pagination={false}
                rowKey={(record: TestRunTopic) => record.packageName + record.name}
            />
        );
    };

    const getRunPanelHeader = (packageName: string) => {
        if (!store.currentTestRunResults) {
            return packageName;
        }

        const hasFailedtopics = store.currentTestRunResults.topics
            .filter(t => t.packageName === packageName)
            .some(t => t.fuzzy < t.baselineFuzzy);

        if (hasFailedtopics) {
            return <span style={{ color: 'red', fontWeight: 500 }}>{packageName}</span>;
        }

        return <span style={{ fontWeight: 500 }}>{packageName}</span>;
    };

    const runsContent = () => {
        return (
            <>
                {store.currentTestRunResults?.error && (
                    <Alert
                        style={{ marginBottom: 24 }}
                        message="Error occurred during test project run"
                        description={store.currentTestRunResults.error}
                        type="error"
                        showIcon
                    />
                )}
                <Collapse
                    className="collapse-with-sticky-header darker"
                    activeKey={openPanelKeys}
                    onChange={(keys: string[]) => setOpenPanelKeys(keys)}
                >
                    {packageListItems.map(p => (
                        <Collapse.Panel
                            key={p.packageId ?? p.packageName}
                            header={getRunPanelHeader(p.packageName)}
                            extra={
                                <TestProjectPackageActions
                                    packageId={p.packageId}
                                    packageName={p.packageName}
                                    store={store}
                                />
                            }
                        >
                            {runContentForPackage(p.packageName, p.packageId)}
                        </Collapse.Panel>
                    ))}
                </Collapse>
            </>
        );
    };

    return (
        <Layout className="screen-size test-project-dashboard" style={{ ...{ height: '100%', background: 'white' } }}>
            <LayoutHeader
                title={getTitle()}
                buttons={[
                    <Button
                        key="test-projects-baselines-go-to-list"
                        data-id="button-go-to-list"
                        className="light"
                        size="large"
                        onClick={store.goBackToList}
                    >
                        Go to list
                    </Button>,
                    <Button
                        key="test-projects-baselines-go-to-dashboard"
                        data-id="button-go-to-dashboard"
                        className="light"
                        size="large"
                        onClick={store.goToDashboard}
                    >
                        Go to dashboard
                    </Button>
                ]}
            />
            <Layout>
                <div className="test-project-actions-wrapper">
                    <div className="test-projects-actions">
                        <Button style={{ margin: '8px 0' }} onClick={() => setIsEditModeOn(prev => !prev)}>
                            {isEditModeOn ? 'Disable Baseline Edit' : 'Enable Baseline Edit'}
                        </Button>
                    </div>

                    <TestProjectSortingActions
                        fields={Constants.PACKAGE_SORT_FIELDS}
                        fieldName={packageSortField}
                        setFieldName={setPackageSortField}
                        setSortingDirection={setPackageSortDirection}
                        sortingDirection={packageSortDirection}
                        storageKey={Constants.PACKAGE_SORT_DIRECTION_STORAGE_KEY}
                    />
                </div>
                <Layout.Content style={{ maxHeight: 'calc(100vh - 100px)', overflow: 'auto' }}>
                    {store.testResultIsLoading ? loadingContent : runsContent()}
                </Layout.Content>
            </Layout>
        </Layout>
    );
};

export default observer(TestProjectRunResultList);

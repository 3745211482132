import appClient from '../../common/services/AppClient';
import {
    DataCrafterTemplate,
    CreateDataCrafterTemplateRequest,
    UpdateDataCrafterTemplateRequest,
    ChangeDataCrafterTemplateStatusRequest,
    PackageColumnsResponse,
    DataCrafterTemplateColumn,
    TemplateScriptResponse
} from '../types';
import security from '../../common/services/SecurityService';

interface CompileProgress {
    step: number;
    total_steps: number;
    message: string;
    status: string;
    duration: number;
    step_details?: {
        model_output?: string;
        observations?: string;
        action_output?: string | null;
        tool_calls?: Array<any>;
    };
}

interface CompileStreamResponse {
    code?: string;
    step?: number;
    total_steps?: number;
    message?: string;
    status?: string;
    duration?: number;
    step_details?: CompileProgress['step_details'];
}

interface CompileStreamOptions {
    onProgress?: (progress: CompileProgress) => void;
    onComplete?: (code: string) => void;
    onError?: (error: Error) => void;
}

export default class DataCrafterTemplatesService {
    // No empty constructor needed since we're not initializing any properties

    // Create a new output file template
    async createTemplate(
        projectId: string,
        data: CreateDataCrafterTemplateRequest
    ): Promise<DataCrafterTemplate | null> {
        try {
            const response = await appClient.post<DataCrafterTemplate | null>(
                process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/data-crafter-templates`,
                data
            );
            return response.unwrapOr(null);
        } catch (error) {
            console.error('Error creating template:', error);
            throw error;
        }
    }

    // Retrieve a list of output file templates
    async getTemplates(projectId: string): Promise<DataCrafterTemplate[]> {
        try {
            const response = await appClient.get<DataCrafterTemplate[]>(
                process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/data-crafter-templates`
            );
            return response.unwrapOr([]);
        } catch (error) {
            console.error('Error fetching templates:', error);
            throw error;
        }
    }

    // Retrieve a single output file template by ID
    async getTemplateById(templateId: string, projectId: string): Promise<unknown> {
        try {
            const response = await appClient.get<unknown>(
                process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/data-crafter-templates/${templateId}`
            );
            return response.unwrapOr(null);
        } catch (error) {
            console.error('Error fetching template:', error);
            throw error;
        }
    }

    // Update an existing output file template
    async updateTemplate(
        projectId: string,
        templateId: string,
        data: UpdateDataCrafterTemplateRequest
    ): Promise<DataCrafterTemplate | null> {
        try {
            const response = await appClient.update<DataCrafterTemplate | null>(
                process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/data-crafter-templates/${templateId}`,
                data
            );
            return response.unwrapOr(null);
        } catch (error) {
            console.error('Error updating template:', error);
            throw error;
        }
    }

    // Change the status of an output file template
    async changeTemplateStatus(
        projectId: string,
        templateId: string,
        data: ChangeDataCrafterTemplateStatusRequest
    ): Promise<DataCrafterTemplate | null> {
        try {
            const response = await appClient.update<DataCrafterTemplate | null>(
                process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/data-crafter-templates/${templateId}/status`,
                data
            );
            return response.unwrapOr(null);
        } catch (error) {
            console.error('Error changing template status:', error);
            throw error;
        }
    }

    // Delete an output file template
    async deleteTemplate(projectId: string, templateId: string): Promise<boolean> {
        try {
            const response = await appClient.delete<boolean>(
                process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/data-crafter-templates/${templateId}`
            );
            return response.unwrapOr(false);
        } catch (error) {
            console.error('Error deleting template:', error);
            throw error;
        }
    }

    async fetchPackagesColumns(packageIds: string[]): Promise<PackageColumnsResponse> {
        const url = process.env.REACT_APP_DATACRAFTER_URL + 'excel';
        const response = await appClient.post<PackageColumnsResponse>(url, {
            packageIds
        });
        return response.unwrapOr({} as PackageColumnsResponse);
    }

    async duplicateTemplate(
        projectId: string,
        templateId: string,
        newName: string
    ): Promise<DataCrafterTemplate | null> {
        try {
            const response = await appClient.post<DataCrafterTemplate | null>(
                process.env.REACT_APP_MANAGE_URL +
                    `projects/${projectId}/data-crafter-templates/${templateId}/duplicate`,
                { newName }
            );
            return response.unwrapOr(null);
        } catch (error) {
            console.error('Error duplicating template:', error);
            throw error;
        }
    }

    async compileTemplateScript(
        inputPackageIds: string[],
        outputPackageId: string,
        outputColumns: DataCrafterTemplateColumn[],
        plannerModelId: string | null,
        coderModelId: string | null
    ): Promise<TemplateScriptResponse> {
        const url = process.env.REACT_APP_DATACRAFTER_URL + 'excel/script/compile';
        const response = await appClient.post<TemplateScriptResponse>(url, {
            inputPackageIds,
            outputPackageId,
            columns: outputColumns,
            plannerModelId,
            coderModelId
        });

        if (response.isErr()) {
            throw new Error(response.error.text);
        }

        return response.unwrapOr({} as TemplateScriptResponse);
    }

    async runTemplateScript(inputPackageIds: string[], outputPackageId: string, code: string): Promise<Blob> {
        try {
            const response = await security.invoke(async token => {
                const response = await fetch(process.env.REACT_APP_DATACRAFTER_URL + 'excel/script/run', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        inputPackageIds,
                        outputPackageId,
                        code
                    })
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                return await response.blob();
            });

            return response;
        } catch (error) {
            console.error('Error running template script:', error);
            throw error;
        }
    }

    async compileTemplateScriptStream(
        inputPackageIds: string[],
        outputPackageId: string,
        outputColumns: DataCrafterTemplateColumn[],
        plannerModelId: string | null,
        coderModelId: string | null,
        options: CompileStreamOptions
    ): Promise<void> {
        const url = process.env.REACT_APP_DATACRAFTER_URL + 'excel/script/compile/stream';

        const requestData = {
            inputPackageIds,
            outputPackageId,
            columns: outputColumns,
            plannerModelId,
            coderModelId
        };

        await appClient.createStreamingRequest<CompileStreamResponse>(url, requestData, {
            onMessage: data => {
                if (data.code) {
                    options.onComplete?.(data.code);
                } else if (data.step) {
                    options.onProgress?.(data as CompileProgress);
                }
            },
            onError: error => {
                options.onError?.(error);
            }
        });
    }
}

import { observable, runInAction, action, computed } from 'mobx';
import { ProjectsRootVisualStore, RouterStore, UsersStore } from '../../common/stores';
import PromptTemplateService from '../services/PromptTemplatesService';
import message from 'antd/lib/message';
import { PromptTemplatesNavigation } from '../routes';
import UpdatePromptTemplateRequest from '../types/requests/UpdatePromptTemplateRequest';
import { PromptTemplate, PromptTemplatesRouterParams } from '../types';
import { Utils } from '../../common/services/Utils';

interface TemplatesRouterParams extends PromptTemplatesRouterParams {}

export default class PromptTemplateStore {
    @observable
    promptTemplates: PromptTemplate[] = [];

    @computed
    get currentProject() {
        return this.projectsRootStore.currentProject;
    }
    @observable
    isLoadingTemplates: boolean = false;

    @observable
    selectedTemplateId: string | null = null;

    @observable
    templateToDuplicate: string | null = null;

    @observable
    isDuplicating: boolean = false;

    @computed
    get selectedTemplate() {
        return this.promptTemplates.find(f => f.id === this.selectedTemplateId);
    }

    @computed
    get sortedPromptTemplates() {
        return [...this.promptTemplates].sort((a, b) => Utils.safeDateStringCompare(b.createdTime, a.createdTime));
    }

    @computed
    get selectedTemplateTitle() {
        return this.selectedTemplate?.title;
    }

    @computed
    get sourceTemplateName(): string | null {
        if (!this.templateToDuplicate) {
            return null;
        }
        const template = this.promptTemplates.find(t => t.id === this.templateToDuplicate);
        return template ? template.title : null;
    }

    constructor(
        private projectsRootStore: ProjectsRootVisualStore,
        private routerStore: RouterStore,
        private usersStore: UsersStore,
        private service: PromptTemplateService
    ) {}

    @action.bound
    async loadPromptTemplates() {
        if (!this.currentProject) {
            return;
        }
        try {
            runInAction(() => {
                this.isLoadingTemplates = true;
            });

            const data = await this.service.getPromptTemplates(this.currentProject.id);

            runInAction(() => {
                this.promptTemplates = data;
            });
        } catch (err) {
            console.error(err);
            message.error(err);
        } finally {
            runInAction(() => {
                this.isLoadingTemplates = false;
            });
        }
    }

    @action.bound
    async createTemplate(title: string, description: string, body: string, structuredOutput: string) {
        if (!this.currentProject) {
            return;
        }
        try {
            const result = await this.service.createPromptTemplate({
                title: title,
                body: body,
                description: description,
                projectId: this.currentProject.id,
                structuredOutput: structuredOutput
            });

            if (result) {
                message.success('Prompt template created successfully');
                await this.loadPromptTemplates();
                this.navigateToList();
            } else {
                message.error('Failed to create template');
            }
        } catch (err) {
            console.error(err);
            message.error(err);
        } finally {
            runInAction(() => {
                this.isLoadingTemplates = false;
            });
        }
    }

    @action.bound
    async editTemplate(title: string, description: string, body: string, structuredOutput: string): Promise<boolean> {
        if (!this.currentProject || this.selectedTemplateId === null) {
            return false;
        }

        try {
            const request: UpdatePromptTemplateRequest = {
                title: title,
                body: body,
                description: description,
                structuredOutput: structuredOutput
            };

            const result = await this.service.updatePromptTemplate(
                this.currentProject.id,
                this.selectedTemplateId,
                request
            );

            if (result) {
                message.success('Prompt template updated successfully');
                await this.loadPromptTemplates();
                this.navigateToList();
                return true;
            } else {
                message.error('Failed to update template');
                return false;
            }
        } catch (err) {
            console.error(err);
            message.error(err);
            return false;
        } finally {
            runInAction(() => {
                this.isLoadingTemplates = false;
            });
        }
    }

    @action.bound
    async deletePromptTemplate(id: string) {
        if (!this.currentProject) {
            return;
        }

        try {
            const resp = await this.service.deletePromptTemplate(this.currentProject.id, id);

            if (!resp.isOk()) {
                message.error(resp.error);
                console.log(resp.error);
                return;
            }

            message.success('Prompt template deleted');
            await this.loadPromptTemplates();
        } catch (err) {
            console.error(err);
            message.error('Failed to delete prompt template');
        }
    }

    @action.bound
    setSelectedTemplate(params: TemplatesRouterParams) {
        this.selectedTemplateId = params.templateId;
    }

    @action.bound
    setTemplateToDuplicate(templateId: string | null) {
        this.templateToDuplicate = templateId;
    }

    @action.bound
    async duplicateTemplate(newName: string): Promise<boolean> {
        if (!this.currentProject || !this.templateToDuplicate) {
            return false;
        }

        try {
            runInAction(() => {
                this.isDuplicating = true;
            });

            const result = await this.service.duplicateTemplate(
                this.currentProject.id,
                this.templateToDuplicate,
                newName
            );

            if (result) {
                message.success('Prompt template duplicated successfully');
                await this.loadPromptTemplates();
                return true;
            } else {
                message.error('Failed to duplicate prompt template');
                return false;
            }
        } catch (error) {
            console.error('Failed to duplicate template:', error);
            message.error('Failed to duplicate template');
            return false;
        } finally {
            runInAction(() => {
                this.isDuplicating = false;
                this.templateToDuplicate = null;
            });
        }
    }

    navigateToCreateTemplate() {
        if (!this.currentProject) {
            return;
        }

        this.routerStore.push(
            PromptTemplatesNavigation.CreatePromptTemplatesScreen.replace(':projectId', this.currentProject.id)
        );
    }

    navigateToList() {
        if (!this.currentProject) {
            return;
        }

        this.routerStore.push(
            PromptTemplatesNavigation.PromptTemplatesPage.replace(':projectId', this.currentProject.id)
        );
    }

    navigateToEditTemplate(templateId: string) {
        if (!this.currentProject) {
            return;
        }

        this.routerStore.push(
            PromptTemplatesNavigation.EditPromptTemplateScreen.replace(':projectId', this.currentProject.id).replace(
                ':templateId',
                templateId
            )
        );
    }

    getUserNameById(id: string) {
        return this.usersStore.getUserNameById(id);
    }

    dispose() {
        runInAction(() => {
            this.promptTemplates = [];
        });
    }
}

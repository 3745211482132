import * as React from 'react';
import { Input, Select, Form, Row, Col, FormInstance, InputNumber } from 'antd';
import { inject } from 'mobx-react';
import { RuleModelSelectWrapped } from './RuleModelSelect';
import { RuleLabelsSelectWrapped } from './RuleLabelsSelect';
import { InferenceRule } from '../models';
import { RuleEditVisualStore } from '../stores';
import { STORE_RULE_EDIT } from '../constants';
import { INFERENCE_MODEL_TYPES } from './ParametersModelEditor';
import { SelectWhenScroll } from '../../common/components/SelectWhenScroll';
import RuleEditGeneralSettings from './RuleEditGeneralSettings';
import RuleType from '../types/RuleType';
import { observer } from 'mobx-react-lite';
import { FieldData } from 'rc-field-form/lib/interface';

type Props = {
    store: RuleEditVisualStore;
    selectType?: (type: RuleType) => void;
    form: FormInstance;
};

export const RuleEditInferenceRuleForm: React.FC<Props> = ({ store, selectType, form }) => {
    const rule = store.editableRule as InferenceRule;
    const [selectedModelId, setSelectedModelId] = React.useState(rule.modelId);
    React.useEffect(() => {
        form.setFieldsValue(getInitialValues());
        if (!rule.id) {
            form.setFieldsValue({ blockType: 'LINE_BLOCK', pageRange: '0-4', labels: [] });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, rule]);

    const getInitialValues = () => {
        return {
            name: rule.name,
            tag: rule.tag,
            blockType: rule.blockType,
            pageRange: rule.pageRange,
            modelId: rule.modelId,
            labels: rule.labels,
            priority: rule.priority,
            description: rule.description,
            overridePriority: rule.overridePriority,
            threshold: rule.threshold
        };
    };

    const handleFieldsChange = (changedFields: FieldData[]) => {
        const modelIdChange = changedFields.find(f => f.name[0] === 'modelId');
        if (modelIdChange) {
            setSelectedModelId(modelIdChange.value);
        }
    };

    return (
        <>
            {store.error.map((e, i) => (
                <h4 key={e + i} style={{ color: 'red' }}>
                    {e}
                </h4>
            ))}
            <Form
                form={form}
                layout="vertical"
                className="rule-edit-form"
                data-id="rule-edit-form"
                onFieldsChange={handleFieldsChange}
            >
                <RuleEditGeneralSettings store={store} selectType={selectType} form={form} />
                <Row gutter={16}>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label="Block Type"
                            name="blockType"
                            initialValue="LINE_BLOCK"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input block type!'
                                }
                            ]}
                            data-id="rule-block-type-select"
                        >
                            <SelectWhenScroll virtual={false}>
                                <Select.Option value="LINE_BLOCK">LINE</Select.Option>
                                <Select.Option value="TEXTBOX_BLOCK">TEXTBOX</Select.Option>
                                <Select.Option value="TABLE_BLOCK">TABLE</Select.Option>
                                <Select.Option value="CELL_BLOCK">CELL</Select.Option>
                                <Select.Option value="HORIZONTAL_LINE_BLOCK">HORIZONTAL</Select.Option>
                                <Select.Option value="CLUSTER_BLOCK">CLUSTER</Select.Option>
                                <Select.Option value="HORIZONTAL_MULTILINE_BLOCK">HORIZONTAL_MULTILINE</Select.Option>
                                <Select.Option value="PARAGRAPH_BLOCK">PARAGRAPH</Select.Option>
                                <Select.Option value="PAGE_BLOCK">PAGE</Select.Option>
                                <Select.Option value="WORD_BLOCK">WORD</Select.Option>
                                <Select.Option value="DOCUMENT_TABLE_BLOCK">DOCUMENT_TABLE</Select.Option>
                                <Select.Option value="FORM_ITEM_BLOCK">FORM_ITEM_BLOCK</Select.Option>
                                <Select.Option value="SMART_BLOCK">SMART</Select.Option>
                                <Select.Option value="INTERPRETATION_BLOCK">INTERPRETATION</Select.Option>
                                <Select.Option value="DOCUMENT_BLOCK">DOCUMENT</Select.Option>
                            </SelectWhenScroll>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label="Page range"
                            name="pageRange"
                            initialValue="0-4"
                            rules={[
                                {
                                    pattern: new RegExp('^[0-9]*([,-][0-9]*)*$'),
                                    message: 'Please input valid page range! (Numbers, hyphens and commas only)'
                                }
                            ]}
                            data-id="rule-page-range-select"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item label="Threshold" name="threshold" data-id="rule-threshold-select">
                            <InputNumber max={100} min={0} />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label="Model"
                            name="modelId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input model id!'
                                }
                            ]}
                            data-id="rule-model-select"
                        >
                            <RuleModelSelectWrapped
                                type={INFERENCE_MODEL_TYPES}
                                service={store.modelService}
                                projectId={store.currentProject ? store.currentProject.id : undefined}
                            />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label="Labels"
                            name="labels"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input labels!'
                                }
                            ]}
                            data-id="rule-labels-select"
                        >
                            <RuleLabelsSelectWrapped service={store.modelService} selectedModelId={selectedModelId} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default inject(STORE_RULE_EDIT)(observer(RuleEditInferenceRuleForm));

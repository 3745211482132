import * as React from 'react';
import { Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { PushpinOutlined } from '@ant-design/icons';
import { Project } from '../../modules/common/models';
import { RouterStore, UserProfileStore } from '../../modules/common/stores';
import './ProjectPinToggle.less';

interface Props {
    project: Project;
    routerStore: RouterStore;
    userProfileStore: UserProfileStore;
}

const ProjectPinToggle: React.FC<Props> = ({ project, routerStore, userProfileStore }: Props) => {
    if (routerStore.sideCollapsed) {
        return null;
    }

    const onClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.stopPropagation();
        userProfileStore.updatePinnedProjects(project.id);
    };

    const isPinned = userProfileStore.pinnedProjectIds.includes(project.id);

    return (
        <div className={`project-pin-toggle-container ${isPinned ? 'pinned' : ''}`}>
            <Tooltip title={isPinned ? 'Unpin project' : 'Pin project'}>
                <span onClick={onClick}>
                    <PushpinOutlined />
                    {isPinned && <div className="unpin-diagonal-line" />}
                </span>
            </Tooltip>
        </div>
    );
};

export default observer(ProjectPinToggle);

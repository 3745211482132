import { ALPHA_CONFIG_VARIABLES } from '../administration/types/AlphaConfigModel';
import { DefaultAlphaConfiguration } from './types';

export const STORE_PROJECTS = 'projects';

export const STORE_PROJECTS_ROOT_UI = 'projectsRootUI';

export const STORE_ROUTER = 'router';

export const STORE_ERRORS = 'error';

export const STORE_SECURITY = 'security';

export const STORE_ALPHA_CONFIG = 'AlphaConfig';

export const STORE_USERS = 'usersStore';

export const STORE_USER_PROFILE = 'userProfileStore';

export const DefaultAlphaConfigurations: DefaultAlphaConfiguration[] = [
    {
        key: ALPHA_CONFIG_VARIABLES.IotaInputReadonly,
        defaultValue: 'true',
        controlType: 'checkbox'
    },
    {
        key: ALPHA_CONFIG_VARIABLES.OcrParseTablesUsingML,
        defaultValue: 'false',
        controlType: 'checkbox'
    },
    {
        key: ALPHA_CONFIG_VARIABLES.OcrParseCheckboxes,
        defaultValue: 'false',
        controlType: 'checkbox'
    },
    {
        key: ALPHA_CONFIG_VARIABLES.OcrParseTablesForceLattice,
        defaultValue: 'false',
        controlType: 'checkbox'
    },
    {
        key: ALPHA_CONFIG_VARIABLES.OcrEnableRatesTables,
        defaultValue: 'false',
        controlType: 'checkbox'
    },
    {
        key: ALPHA_CONFIG_VARIABLES.OcrEnableAcordForms,
        defaultValue: 'false',
        controlType: 'checkbox'
    },
    {
        key: ALPHA_CONFIG_VARIABLES.OcrRefineTablesWithTabula,
        defaultValue: 'false',
        controlType: 'checkbox'
    },
    {
        key: ALPHA_CONFIG_VARIABLES.OcrParseTablesFastLattice,
        defaultValue: 'false',
        controlType: 'checkbox'
    },
    {
        key: ALPHA_CONFIG_VARIABLES.OcrLang,
        defaultValue: 'eng',
        controlType: 'text'
    },
    {
        key: ALPHA_CONFIG_VARIABLES.OcrParseTablesLatticeLineScale,
        defaultValue: '18',
        controlType: 'text'
    },
    {
        key: ALPHA_CONFIG_VARIABLES.OcrParseTablesMlFixRotation,
        defaultValue: 'false',
        controlType: 'checkbox'
    },
    {
        key: ALPHA_CONFIG_VARIABLES.OcrParseTablesMlMissingLineScale,
        defaultValue: '10',
        controlType: 'text'
    },
    {
        key: ALPHA_CONFIG_VARIABLES.OcrParseTablesDisabled,
        defaultValue: 'false',
        controlType: 'checkbox'
    },
    {
        key: ALPHA_CONFIG_VARIABLES.OcrImagePreprocess,
        defaultValue: '',
        controlType: 'text'
    },
    {
        key: ALPHA_CONFIG_VARIABLES.OcrUseExternalEngine,
        defaultValue: '',
        controlType: 'text'
    },
    {
        key: ALPHA_CONFIG_VARIABLES.OcrTextReplacements,
        defaultValue: '',
        controlType: 'text'
    },
    {
        key: ALPHA_CONFIG_VARIABLES.OcrDisabledFixPagesRotation,
        defaultValue: 'false',
        controlType: 'checkbox'
    },
    {
        key: ALPHA_CONFIG_VARIABLES.OcrIgnoreTextLayer,
        defaultValue: 'false',
        controlType: 'checkbox'
    },
    {
        key: ALPHA_CONFIG_VARIABLES.OcrEnableScannedDocumentsDetector,
        defaultValue: 'false',
        controlType: 'checkbox'
    },
    {
        key: ALPHA_CONFIG_VARIABLES.OcrSkipDuplicatePackageLookup,
        defaultValue: 'false',
        controlType: 'checkbox'
    },
    {
        key: ALPHA_CONFIG_VARIABLES.OcrParseTablesApplyImageProcessing,
        defaultValue: 'false',
        controlType: 'checkbox'
    },
    {
        key: ALPHA_CONFIG_VARIABLES.OcrTablesUseWordsForImageCells,
        defaultValue: 'false',
        controlType: 'checkbox'
    },
    {
        key: ALPHA_CONFIG_VARIABLES.OcrDisableDefaultImagePreprocessing,
        defaultValue: 'false',
        controlType: 'checkbox'
    },
    {
        key: ALPHA_CONFIG_VARIABLES.OcrBlocksCustom,
        defaultValue: '',
        controlType: 'textarea'
    },
    {
        key: ALPHA_CONFIG_VARIABLES.OcrPackageUploadEnabled,
        defaultValue: 'false',
        controlType: 'checkbox'
    }
];

import * as React from 'react';
import ReactDiffViewer from 'react-diff-viewer-continued';
import { observer } from 'mobx-react-lite';
import { Modal, Button } from 'antd';
import { HistoryStore } from '../../stores';
import { HistoryFieldModel } from '../../models';
import { HistoryHelpers } from '../../misc';

interface Props {
    store: HistoryStore;
    historyField: HistoryFieldModel | null;
    onClose: () => void;
}

const HistorySelectedFieldModal: React.FC<Props> = ({ store, historyField, onClose }) => {
    const prettifyJson = (value: unknown) => {
        try {
            return JSON.stringify(value, null, 2);
        } catch (e) {
            console.error('Failed to prettify JSON string', e);
            return '';
        }
    };

    const oldValue = React.useMemo(
        () =>
            historyField?.serialized
                ? prettifyJson(HistoryHelpers.parseJson(historyField?.prevStringValue))
                : historyField?.prevStringValue,
        [historyField?.serialized, historyField?.prevStringValue]
    );

    const newValue = React.useMemo(
        () =>
            historyField?.serialized
                ? prettifyJson(HistoryHelpers.parseJson(historyField?.stringValue))
                : historyField?.stringValue,
        [historyField?.serialized, historyField?.stringValue]
    );

    return (
        <Modal
            key={historyField?.key}
            className="alpha-portal-dialog"
            bodyStyle={{ maxHeight: 'calc(100vh - 310px)' }}
            width={1200}
            visible={!!historyField}
            title={`${HistoryHelpers.formatFieldName(historyField?.name)} changes`}
            onCancel={onClose}
            footer={[
                <Button key="split-view-toggle" onClick={store.toggleDiffSplitView}>
                    {store.diffSplitViewEnabled ? 'Disable' : 'Enable'} split view
                </Button>,
                <Button key="back" type="primary" onClick={onClose}>
                    Close
                </Button>
            ]}
        >
            <ReactDiffViewer splitView={store.diffSplitViewEnabled} oldValue={oldValue} newValue={newValue} />
        </Modal>
    );
};

export default observer(HistorySelectedFieldModal);

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import FormItem from 'antd/lib/form/FormItem';
import { Tooltip, Input, Button, Select, Form, Modal, Upload, message } from 'antd';
import { MLModel } from '../types';
import { MLStorageStores } from '../stores';
import { QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { inject } from 'mobx-react';
import { STORE_PROJECT_ML_MODELS } from '../constants';
import { UploadChangeParam } from 'antd/lib/upload';
import { ReadLabelsFromRevisionResponse } from '../models/ReadLabelsFromRevisionResponse';

type Props = MLStorageStores;

const Option = Select.Option;

export const MLModelForm: React.FC<Props> = ({ projectMLModelsUI: store }) => {
    const mlStore = store!;
    const action = `${process.env.REACT_APP_MANAGE_URL}projects/${store!.currentProject?.id}/ml_models/read-model-labels`;
    const [form] = Form.useForm();
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [isUploading, setIsUploading] = React.useState(false);

    React.useEffect(() => {
        if (mlStore.isAddModelDialogVisible) {
            setIsSubmitting(false);
            setIsUploading(false);
            mlStore.setHeaders();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mlStore.isAddModelDialogVisible]);

    React.useEffect(() => {
        mlStore.getMLModelTypes();
    }, [mlStore]);

    React.useEffect(() => {
        if (mlStore.currentModel) {
            form.setFieldsValue({
                name: mlStore.currentModel.name,
                type: mlStore.currentModel.type,
                labels: mlStore.currentModel.labels,
                device: mlStore.currentModel.device,
                description: mlStore.currentModel.description
            });
        } else {
            form.resetFields();
        }
    }, [mlStore.currentModel, mlStore, form]);

    React.useEffect(() => {
        mlStore.loadProjectMLModels();
    }, [mlStore.currentProject, mlStore]);

    const handleSubmit = async () => {
        form.validateFields()
            .then(async values => {
                setIsSubmitting(true);
                let model: MLModel = new MLModel(
                    values.name,
                    store!.currentProject!.id,
                    values.type,
                    values.labels,
                    '',
                    '',
                    values.device,
                    values.description,
                    store!.currentModel ? store!.currentModel.id : undefined,
                    store!.currentModel ? store!.currentModel.revision : undefined
                );

                const resp = await store!.saveMLModel(model, values.revisionFilePath);
                resp.map(() => {
                    store!.loadProjectMLModels();
                    store!.setIsAddModelDialogVisible(false);
                    form.resetFields();
                });
                setIsSubmitting(false);
            })
            .catch(err => {
                console.log(err);
                setIsSubmitting(false);
            });
    };

    const handleCancel = () => {
        store!.setCurrentMLModel(undefined);
        store!.setIsAddModelDialogVisible(false);
        form.resetFields();
    };

    const onRevisionChange = (info: UploadChangeParam) => {
        const status = info.file.status;
        setIsUploading(status === 'uploading');
        if (status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (status === 'done') {
            const resp = info.file.response as ReadLabelsFromRevisionResponse;
            form.setFieldsValue({ labels: resp.labels });
            form.setFieldsValue({ revisionFilePath: resp.filePath });
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    return (
        <Modal
            title={store!.currentModel ? 'Edit Model' : 'New Model'}
            visible={store!.isAddModelDialogVisible}
            onCancel={handleCancel}
            maskClosable={false}
            destroyOnClose={false}
            closable={false}
            className="alpha-portal-dialog"
            centered
            footer={[
                <Button
                    data-id="ml-model-dialog-cancel"
                    size="large"
                    className="light"
                    key="back"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>,
                <Button
                    loading={isSubmitting}
                    data-id="ml-model-dialog-submit"
                    size="large"
                    key="submit"
                    type="primary"
                    onClick={handleSubmit}
                >
                    {store!.currentModel ? 'Save' : 'Create'}
                </Button>
            ]}
        >
            <Form form={form} layout="vertical" data-id="ml-model-dialog-form">
                <FormItem
                    label={
                        <span>
                            Model name&nbsp;
                            <Tooltip title="ML Model name for alpha">
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </span>
                    }
                    name="name"
                    rules={[{ required: true, message: 'Please input model name!', whitespace: true }]}
                >
                    <Input />
                </FormItem>
                <FormItem
                    label={
                        <span>
                            Model type&nbsp;
                            <Tooltip title="ML Model type for alpha">
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </span>
                    }
                    name="type"
                    rules={[{ required: true, message: 'Please select model type!', whitespace: true }]}
                >
                    <Select>
                        {store!.mlModelTypes &&
                            store!.mlModelTypes.map(t => (
                                <Option key={t.id} value={t.type}>
                                    {t.type}
                                </Option>
                            ))}
                    </Select>
                </FormItem>

                {!mlStore.currentModel && (
                    <FormItem
                        label={
                            <span>
                                Model revision&nbsp;
                                <Tooltip title="Labels can be parsed from revision if there are any">
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </span>
                        }
                        name="revisionFile"
                    >
                        <Upload name="file" maxCount={1} action={action} onChange={onRevisionChange}>
                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload>
                    </FormItem>
                )}
                <FormItem
                    label={
                        <span>
                            Model labels&nbsp;
                            <Tooltip title="ML Model label for alpha">
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </span>
                    }
                    name="labels"
                    initialValue={[]}
                    rules={[{ required: true, message: 'Please input model labels!' }]}
                >
                    <Select mode="tags" loading={isUploading} disabled={isUploading} />
                </FormItem>
                <FormItem
                    label={
                        <span>
                            Model device&nbsp;
                            <Tooltip title="Device which will be used to run model (CPU or GPU)">
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </span>
                    }
                    name="device"
                    initialValue="GPU"
                    rules={[{ required: true, message: 'Please input model labels!' }]}
                >
                    <Select
                        options={[
                            { label: 'CPU', value: 'CPU' },
                            { label: 'GPU', value: 'GPU' }
                        ]}
                    />
                </FormItem>
                <FormItem label={<span>Description&nbsp;</span>} name="description">
                    <Input />
                </FormItem>
                <FormItem name="revisionFilePath" hidden>
                    <Input type="hidden" />
                </FormItem>
            </Form>
        </Modal>
    );
};

export default inject(STORE_PROJECT_ML_MODELS)(observer(MLModelForm));

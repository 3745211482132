import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Layout, Button } from 'antd';
import { Utils } from '../../../common/services/Utils';
import { CreateEditWorkflowDialog, WorkflowsList, WorkflowUploader } from '..';
import { HelpMessage } from '../../../common/components';
import LayoutHeader from '../../../../components/LayoutHeader';
import InstructWorkflowCreateEditStore from '../../stores/InstructWorkflowCreateEditStore';
import InstructWorkflowImportExportStore from '../../stores/InstructWorkflowImportExportStore';

interface Props {
    store: InstructWorkflowCreateEditStore;
    importExportStore: InstructWorkflowImportExportStore;
}

const WorkflowManager: React.FC<Props> = ({ store, importExportStore }) => {
    React.useEffect(() => {
        if (store.project) {
            store.getData();
        }

        return () => store.dispose();
    }, [store, store.project]);

    return (
        <Layout>
            <LayoutHeader
                title="Instruct Workflows"
                subtitle={Utils.getSubtitle(store.project)}
                buttons={[
                    <HelpMessage
                        key="help-message"
                        message="Page for setting up instruct workflows to process user instructions on uploaded documents"
                    />,
                    <WorkflowUploader key="import-workflow" importExportStore={importExportStore} />,
                    <Button
                        key="create-workflow"
                        size="large"
                        type="primary"
                        onClick={() => store.setCreateWorkflowDialogVisible(true)}
                    >
                        Create workflow
                    </Button>
                ]}
            />
            <Layout.Content>
                <CreateEditWorkflowDialog store={store} />
                <WorkflowsList store={store} importExportStore={importExportStore} />
            </Layout.Content>
        </Layout>
    );
};

export default observer(WorkflowManager);

import * as React from 'react';
import ProjectsList from '../components/ProjectsList';
import { inject, observer } from 'mobx-react';
import { RootStores } from '../modules/common/stores';
import { STORE_PROJECTS, STORE_ROUTER, STORE_PROJECTS_ROOT_UI, STORE_USER_PROFILE } from '../modules/common/constants';
import { AuthConsumer } from '../modules/authorization/AuthContext';
import { HasPermission } from '../modules/authorization/components/HasPermission';
import { AppPermissions } from '../modules/authorization/Permissions';
import { STORE_PROJECT_FORM, STORE_PROJECT_IMPORT } from '../modules/project_management/constants';
import { ProjectStores } from '../modules/project_management/stores';
import AddProjectDialog from '../modules/project_management/components/AddProjectDialog';
import ProjectImportDialog from '../modules/project_management/components/ProjectImportDialog';
import SideMenuHeaderControls from './SideMenuHeaderControls';
import { AlphaVersionLabel } from '../modules/common/components';
import { Divider } from 'antd';
import { ProjectsSearch } from '../components';

type Props = RootStores & ProjectStores;

type State = {
    collapsed: boolean;
};

@inject(
    STORE_PROJECTS,
    STORE_PROJECT_FORM,
    STORE_ROUTER,
    STORE_PROJECT_IMPORT,
    STORE_PROJECTS_ROOT_UI,
    STORE_USER_PROFILE
)
@observer
export default class ProjectsSideMenu extends React.Component<Props, State> {
    render() {
        const projectsStore = this.props.projects!;
        const routerStore = this.props.router!;
        const projectImportStore = this.props.projectImport!;
        const projectsVisualStore = this.props.projectsRootUI!;
        const userProfileStore = this.props.userProfileStore!;

        return (
            <>
                <AuthConsumer>
                    {({ permissions }) => (
                        <>
                            <SideMenuHeaderControls routerStore={routerStore} projectsStore={projectsStore} />
                            <ProjectsSearch
                                routerStore={routerStore}
                                projectsStore={projectsStore}
                                projectsVisualStore={projectsVisualStore}
                            />
                            <Divider style={{ marginTop: 0 }} />
                            <ProjectsList
                                routerStore={routerStore}
                                userProfileStore={userProfileStore}
                                permissions={permissions}
                            />
                            <HasPermission
                                permissionClaim={AppPermissions.CanAddEditDeleteAssignProject}
                                yes={() => (
                                    <div className="projects-side-menu-footer">
                                        <AddProjectDialog projectsStore={projectsStore} />
                                        <ProjectImportDialog
                                            store={projectImportStore}
                                            projectsStore={projectsStore}
                                            projectsVisualStore={projectsVisualStore}
                                        />
                                        <AlphaVersionLabel />
                                    </div>
                                )}
                                no={() => (
                                    <div className="projects-side-menu-footer">
                                        <AlphaVersionLabel />
                                    </div>
                                )}
                            />
                        </>
                    )}
                </AuthConsumer>
            </>
        );
    }
}

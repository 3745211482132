import * as React from 'react';
import { Layout, Input, Button, Form, Collapse, Popconfirm, Tooltip } from 'antd';
import LayoutHeader from '../../../components/LayoutHeader';
import { AlphaConfigVisualStore } from '../stores/AlphaConfigVisualStore';
import { observer } from 'mobx-react-lite';
import { useForm } from 'antd/lib/form/Form';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { CloseOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import AlphaSettingCreateDialog from './AlphaSettingCreateDialog';
import { FeatureFlagsTreeRenderer } from '../../common/components';
import { getFeatureFlagFormValue } from '../../common/misc/FeatureFlagsHelpers';

type Props = {
    store: AlphaConfigVisualStore;
};

export const AlphaConfigTable: React.FC<Props> = ({ store }) => {
    const [form] = useForm();
    const handleSaveChanges = () => {
        form.validateFields()
            .then(values => {
                store.handleConfigSave(values);
            })
            .catch(err => {
                console.log(err);
            });
    };

    const [currentEditingSettings, setCurrentEditingSettings] = React.useState<string[]>([]);
    const [settingsCopy, setSettingsCopy] = React.useState<object | undefined>(undefined);

    React.useEffect(() => {
        store
            .getAlphaSettings()
            .then(() => {
                setSettingsCopy(store.alphaSettings?.values);
            })
            .catch(err => {
                console.log(err);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderPrimitiveSettings = (obj: object | undefined = undefined) => {
        if (!obj) {
            return null;
        }

        const keys = Object.keys(obj);
        const primitiveKeys = keys.filter(key => {
            return typeof obj[key] !== 'object';
        });

        return <>{primitiveKeys.map(key => settingEditor(obj, key))}</>;
    };

    const editSetting = (key: string) => {
        setCurrentEditingSettings([...currentEditingSettings, key]);
    };

    const cancelEditSetting = (key: string) => {
        setCurrentEditingSettings(currentEditingSettings.filter(k => k !== key));
        const revertedSettingsCopy = { ...settingsCopy };
        revertedSettingsCopy[key] = store.alphaSettings?.values[key];
        setSettingsCopy(revertedSettingsCopy);
    };

    const saveEditSettings = (key: string) => {
        if (!settingsCopy) {
            return;
        }

        store
            .updateAlphaSettings(key, settingsCopy[key])
            .then(() => {
                setCurrentEditingSettings(currentEditingSettings.filter(k => k !== key));

                if (store.alphaSettings?.values) {
                    store.alphaSettings.values[key] = settingsCopy[key];
                } else if (store.alphaSettings) {
                    store.alphaSettings = {
                        ...store.alphaSettings,
                        values: settingsCopy
                    };
                }
            })
            .catch(() => {
                cancelEditSetting(key);
            });
    };

    const deleteSetting = (key: string) => {
        store.deleteAlphaSettings(key).then(() => {
            if (!store.alphaSettings?.values) {
                return;
            }

            const newSettings = { ...settingsCopy };
            delete newSettings[key];
            setSettingsCopy(newSettings);
        });
    };

    const editableSettingsButtons = (key: string) => {
        return (
            <>
                <Button type="link" icon={<SaveOutlined />} onClick={() => saveEditSettings(key)} />
                <Button type="link" icon={<CloseOutlined />} onClick={() => cancelEditSetting(key)} />
            </>
        );
    };

    const idleSettingsButtons = (key: string) => {
        return (
            <>
                <Button type="link" icon={<i className="alpha-icon md edit-icon" />} onClick={() => editSetting(key)} />
                <Popconfirm title="Are you sure you want to delete this setting?" onConfirm={() => deleteSetting(key)}>
                    <Button type="link" icon={<i className="alpha-icon md delete-icon" />} />
                </Popconfirm>
            </>
        );
    };

    const changeSettingsCopy = (key: string, value: string) => {
        if (settingsCopy) {
            settingsCopy[key] = value;
            setSettingsCopy({ ...settingsCopy });
        }
    };

    const handleKeyPress = (key: string, event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            saveEditSettings(key);
        }
    };

    const settingEditor = (obj: object, key: string) => {
        return (
            <div key={key} className="alpha-setting">
                <div className="alpha-setting-name">
                    <Tooltip title={key}>{key}</Tooltip>
                </div>
                <div className="alpha-setting-value">
                    {currentEditingSettings.includes(key) && settingsCopy ? (
                        <Input
                            defaultValue={settingsCopy[key].toString()}
                            onChange={ev => changeSettingsCopy(key, ev.target.value)}
                            onKeyPress={ev => handleKeyPress(key, ev)}
                        />
                    ) : (
                        <div className="alpha-setting-value-text" onClick={() => editSetting(key)}>
                            {obj[key].toString()}
                        </div>
                    )}
                </div>
                <div className="alpha-setting-actions">
                    {currentEditingSettings.includes(key) ? editableSettingsButtons(key) : idleSettingsButtons(key)}
                </div>
            </div>
        );
    };

    const addSetting = (event: React.MouseEvent) => {
        event.stopPropagation();
        store.setIsNewAlphaSettingModalVisible(true);
    };

    const alphaSettingsExtra = () => {
        return (
            <Button
                type="link"
                size="small"
                icon={<PlusOutlined size={12} />}
                style={{ fontSize: '12px' }}
                onClick={addSetting}
            >
                Add setting
            </Button>
        );
    };

    React.useEffect(() => {
        store.tableDataSource.forEach(item =>
            form.setFieldsValue({ [item.name]: getFeatureFlagFormValue(item.name, item.value) })
        );
    }, [form, store.tableDataSource]);

    const tableRenderer = !store.isLoading ? (
        <Layout className="screen-size" style={{ ...{ height: '100%', background: '#fff' } }}>
            <LayoutHeader
                title="Alpha Configs"
                helpMessage=" "
                buttons={[
                    <Button
                        onClick={handleSaveChanges}
                        key="save-changes"
                        type="primary"
                        size="large"
                        style={{ marginBottom: 10 }}
                    >
                        Save Changes
                    </Button>
                ]}
            />
            <Layout>
                {store.isNewAlphaSettingModalVisible && <AlphaSettingCreateDialog store={store} />}
                <Collapse>
                    <Collapse.Panel header="Feature flags" key="1" forceRender>
                        <Form form={form}>
                            <FeatureFlagsTreeRenderer />
                        </Form>
                    </Collapse.Panel>
                    <Collapse.Panel header="Alpha settings" key="2" extra={alphaSettingsExtra()}>
                        {renderPrimitiveSettings(store.alphaSettings?.values)}
                    </Collapse.Panel>
                </Collapse>
            </Layout>
        </Layout>
    ) : (
        <LoadingIndicator />
    );

    return tableRenderer;
};

export default observer(AlphaConfigTable);

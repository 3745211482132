import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Select } from 'antd';
import PipelineStep from '../../pipeline_base/models/PipelineStep';
import PipelineVisualStore from '../stores/PipelineVisualStore';
import { Parameter } from '../types';
import { SelectWhenScroll } from '../../common/components/SelectWhenScroll';
import Form, { FormInstance } from 'antd/lib/form';
import useDisplayConditions from '../hooks/useDisplayConditions';

const Option = Select.Option;
type Props<T extends PipelineVisualStore<TPipelineStepType>, TPipelineStepType extends string> = {
    param: Parameter;
    form: FormInstance;
    toolTip: JSX.Element;
    step: PipelineStep<TPipelineStepType>;
    projectId: string;
    store: T;
    stepKey: number;
};
export const ParametersDropdownEditor: <
    T extends PipelineVisualStore<TPipelineStepType>,
    TPipelineStepType extends string
>(
    props: Props<T, TPipelineStepType>
) => React.ReactElement<Props<T, TPipelineStepType>> = ({ param, form, toolTip, step, store, stepKey }) => {
    const handleSubmit = async () => {
        await store.validateForm(form, step, stepKey, false);
    };
    if (!store.isFormSubmissionExists(stepKey)) {
        store.setFormSubmision(handleSubmit, stepKey);
    }

    const renderOptions = () => {
        if (Array.isArray(param.values)) {
            return param.values.map((v, i) => (
                <Option value={v} key={i.toString()}>
                    {v}
                </Option>
            ));
        } else {
            var keys = Object.getOwnPropertyNames(param.values);

            return keys.map(k => (
                <Option value={k} key={k}>
                    {param.values[k]}
                </Option>
            ));
        }
    };

    const getInitialValue = () => {
        if (form.getFieldValue(param.name)) {
            return undefined;
        }

        return param.defaultValue;
    };

    const { isVisible } = useDisplayConditions({ param, form, store, step });

    return (
        <div style={!isVisible ? { display: 'none' } : {}}>
            <Form.Item
                label={toolTip}
                name={param.name}
                rules={[
                    {
                        required: !step.isDisabled && param.required,
                        message: 'Please input parameter value'
                    }
                ]}
                initialValue={getInitialValue()}
            >
                <SelectWhenScroll placeholder="Select type" allowClear>
                    {renderOptions()}
                </SelectWhenScroll>
            </Form.Item>
        </div>
    );
};

export default observer(ParametersDropdownEditor);

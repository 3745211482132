import { HistoryRecordDto, HistoryAction } from '../services/types';
import { HistoryHelpers } from '../misc';
import HistoryFieldModel from './HistoryFieldModel';

export default abstract class BaseHistoryRecordModel {
    readonly id: string;
    readonly projectId: string;
    readonly entityId: string;
    readonly entityName: string;
    readonly objectName: string;
    readonly action: HistoryAction;
    readonly fields: HistoryFieldModel[];
    readonly userId: string;
    readonly userName: string;
    readonly createDate: string;
    readonly meta: unknown;
    readonly metaFields: HistoryFieldModel[] = [];

    constructor(data: HistoryRecordDto) {
        this.id = data.id;
        this.projectId = data.projectId;
        this.entityId = data.entityId;
        this.entityName = data.entityName;
        this.objectName = data.objectName;
        this.action = data.action;
        this.fields = data.fields.map(field => new HistoryFieldModel(field));
        this.userId = data.userId;
        this.userName = data.userName;
        this.createDate = data.createDate;
        this.meta = data.meta;

        if (HistoryHelpers.hasNameMeta(this.meta)) {
            this.metaFields.push(new HistoryFieldModel({ name: 'EntityName', value: this.meta.name }));
        }
    }

    abstract get objectLabel(): string;
}

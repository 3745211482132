import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Layout, Button, Form, Input } from 'antd';
import LayoutHeader from '../../../../components/LayoutHeader';
import { Content } from 'antd/lib/layout/layout';
import { Utils } from '../../../common/services/Utils';
import PromptTemplateStore from '../../stores/PromptTemplatesStore';
import TextArea from 'antd/lib/input/TextArea';
import { EditPromptTemplateStore, PropmtDependentPackagesStore } from '../../stores';
import { validateJson } from '../../misc';
import './PromptTemplate.less';
import JsonEditor from './JsonEditor';
import { AppPermissions } from '../../../authorization/Permissions';
import { HasPermission } from '../../../authorization/components/HasPermission';
import ReparseDependentPackagesButton from './ReparseDependentPackagesButton';

type Props = {
    store: PromptTemplateStore | undefined;
    editStore: EditPromptTemplateStore;
    propmtDependentPackagesStore: PropmtDependentPackagesStore;
};

const EditPromptTemplate: React.FC<Props> = ({ store, editStore, propmtDependentPackagesStore }) => {
    const [form] = Form.useForm();

    const [editorValue, setEditorValue] = React.useState<string>('');
    const [jsonIsInvalid, setJsonIsInvalid] = React.useState(false);

    React.useEffect(() => {
        if (store && store.currentProject && store.selectedTemplateId) {
            editStore.initializeWithTemplate(store.selectedTemplateId);
        }

        return () => editStore.dispose();
    }, [store, editStore]);

    React.useEffect(() => {
        if (editStore.selectedTemplate) {
            form.setFieldsValue({
                title: editStore.selectedTemplate.title,
                description: editStore.selectedTemplate.description,
                body: editStore.selectedTemplate.body
            });
            setEditorValue(editStore.selectedTemplate.structuredOutput ?? '');
        }
    }, [form, editStore.selectedTemplate]);

    if (!store || !store.selectedTemplateId) {
        return null;
    }

    const handleValueChange = (value: string) => {
        setJsonIsInvalid(false);
        setEditorValue(value);
    };

    const handleSubmit = async (): Promise<boolean> => {
        try {
            const jsonIsValid = validateJson(editorValue);

            if (!jsonIsValid) {
                setJsonIsInvalid(true);
                return false;
            }
            const values = await form.validateFields();
            return await store.editTemplate(values.title, values.description, values.body, editorValue);
        } catch (error) {
            console.log('Form validation failed');
            return false;
        }
    };

    return (
        <Form form={form} layout="vertical">
            <Layout className="prompt-template-layout">
                <LayoutHeader
                    subtitle={Utils.getSubtitle(store.currentProject)}
                    helpMessage=""
                    title="Edit Prompt Template"
                    buttons={[
                        <HasPermission
                            key="reparse-dependent-packages"
                            entityId={store?.currentProject?.id}
                            permissionClaim={AppPermissions.CanEditProcessDocuments}
                        >
                            <ReparseDependentPackagesButton
                                store={propmtDependentPackagesStore}
                                updatePromptTemplate={handleSubmit}
                            />
                        </HasPermission>,
                        <Button
                            key="prompt-templates-go-back"
                            data-id="button-go-to-list"
                            className="light"
                            size="large"
                            onClick={() => store.navigateToList()}
                        >
                            Go back
                        </Button>,
                        <Button
                            key="prompt-templates-submit"
                            data-id="button-submit"
                            type="primary"
                            size="large"
                            onClick={handleSubmit}
                        >
                            Save Template
                        </Button>
                    ]}
                />
                <Layout>
                    <Content className="prompt-template-content">
                        <div style={{ padding: '24px' }}>
                            <Form.Item
                                name="title"
                                label="Title"
                                rules={[
                                    { required: true, message: 'Please enter a template title' },
                                    { whitespace: true, message: 'Template title cannot be empty' }
                                ]}
                            >
                                <Input
                                    placeholder="Enter template name"
                                    defaultValue={store.selectedTemplate?.title || ''}
                                />
                            </Form.Item>
                            <Form.Item name="description" label="Description">
                                <Input placeholder="Enter template description" />
                            </Form.Item>
                            <Form.Item
                                name="body"
                                label="Body"
                                rules={[
                                    { required: true, message: 'Please enter a template body' },
                                    { whitespace: true, message: 'Template body cannot be empty' }
                                ]}
                            >
                                <TextArea autoSize={{ minRows: 18 }} placeholder="Enter prompt template text" />
                            </Form.Item>
                            <JsonEditor
                                value={editorValue}
                                onChange={handleValueChange}
                                jsonIsInvalid={jsonIsInvalid}
                            />
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </Form>
    );
};

export default observer(EditPromptTemplate);

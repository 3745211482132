import BaseHistoryRecordModel from './BaseHistoryRecordModel';
import { HistoryRecordDto } from '../services/types';

export default class GenericHistoryRecordModel extends BaseHistoryRecordModel {
    constructor(data: HistoryRecordDto) {
        super(data);
    }

    get objectLabel() {
        return `${this.objectName}`;
    }
}

import { FieldData } from 'rc-field-form/lib/interface';

type FieldKeyValue = { key: string; value: string };

export default class FormFieldsUtils {
    public static transform(formFieldsValue: Record<string, unknown>): FieldData[] {
        const transformedFields = Object.entries(formFieldsValue).reduce<Record<string, unknown>>(
            (acc, [field, fieldValue]) => {
                if (this.isFieldArray(fieldValue)) {
                    acc[field] = fieldValue.reduce((transformed: Record<string, string>, { key, value }) => {
                        transformed[key] = value;
                        return transformed;
                    }, {});
                } else {
                    acc[field] = fieldValue;
                }

                return acc;
            },
            {}
        );

        return Object.entries(transformedFields).map(([key, value]) => ({
            name: [key],
            value
        }));
    }

    private static isFieldArray(fieldValue: unknown): fieldValue is FieldKeyValue[] {
        return (
            Array.isArray(fieldValue) &&
            fieldValue.length > 0 &&
            fieldValue.every(item => typeof item === 'object' && item !== null && 'key' in item && 'value' in item)
        );
    }
}

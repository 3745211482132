/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */

import { Table, Button, Modal, Select, Checkbox, Input, Tooltip, Popconfirm, Switch, Menu, Dropdown, Form } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import * as React from 'react';
import { Observer, observer } from 'mobx-react-lite';
import RulesListVisualStore from '../stores/RulesListVisualStore';
import { STORE_RULES_LIST, STORE_RULES_NEW } from '../constants';
import FormItem from 'antd/lib/form/FormItem';
import { RuleTypes } from '../models';
import _ from 'lodash';
import { TagsGroupModel } from '../models/TagsGroupModel';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DragableBodyRow } from './ItemDraggableRow';
import { getFriendlyRuleTypeName, safeStringLocaleCompare } from '../../common/Utils';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import TagRenameDialog from './TagRenameDialog';
import { TagModel } from '../models/TagModel';
import { Utils } from '../../common/services/Utils';
import RulesNewVisualStore from '../stores/RuleNewVisualStore';
import RuleErrorIcon from './RuleErrorIcon';
import TagErrorIcon from './TagErrorIcon';

type Props = {
    [STORE_RULES_LIST]: RulesListVisualStore | undefined;
    [STORE_RULES_NEW]: RulesNewVisualStore | undefined;
    tagsGroup: TagsGroupModel;
};

type PriorityEditState = {
    ruleId: string | undefined;
    priority: number | undefined;
};

const Option = Select.Option;

export const RulesList: React.FC<Props> = ({ RulesListUI: store, RuleNewUI: ruleCreateStore, tagsGroup }) => {
    const [form] = Form.useForm();

    const initialTagRowKeys: string[] | number[] = [];
    const initialRuleRowKeys: string[] = [];

    const [selectedTagRowKeys, setSelectedTagRowKeys] = React.useState(initialTagRowKeys);
    const [selectedRuleRowKeys, setSelectedRuleRowKeys] = React.useState(initialRuleRowKeys);
    const [expandedRowKeys, setExpandedRowKeys] = React.useState(store!.expandedRows);

    const [editPriorityState, setEditPriorityState] = React.useState<PriorityEditState>({
        ruleId: undefined,
        priority: undefined
    });
    const [disabledRules, setDisabledRules] = React.useState(store!.disabledRules);

    React.useEffect(() => {
        if (store?.currentTagGroupId) {
            const groupRow = document.querySelector(`tr[data-row-key="${store.currentTagGroupId}"]`);
            if (groupRow) {
                groupRow.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
            store.setCurrentTagGroupId(null);
        }

        return () => {
            store!.disposer();
        };
    }, [store]);

    const onTagSelectChange = (selectedKeys: string[] | number[]) => {
        store!.handleTagsSelection(selectedKeys);
        setSelectedTagRowKeys(selectedKeys);
        setSelectedRuleRowKeys(_.union(store!.selectedRulesKeys, store!.selectedRulesKeysByTag));
    };

    const onRuleSelectChange = (isChecked: boolean, recordId: string) => {
        store!.handleRulesSelection(isChecked, recordId);

        if (isChecked) {
            setSelectedRuleRowKeys([...selectedRuleRowKeys, recordId]);
        } else {
            setSelectedRuleRowKeys(selectedRuleRowKeys.filter(k => k !== recordId));
        }
    };

    const handleRowOnExpand = (expanded: boolean, record: TagModel) => {
        store!.handleRowOnExpand(expanded, record.id!);
        setExpandedRowKeys(store!.expandedRows.slice());
    };

    const handleSubmit = (e: React.FormEvent<object>) => {
        e.preventDefault();
        form.validateFields()
            .then((values: { tag: string; newTag: string }) => {
                // const vals = form.getFieldsValue();
                store!.copyRule(values);
            })
            .catch(err => {
                console.log(err);
            });
    };

    const sortPriorities = (a: number, b: number) => {
        if (a > b) {
            return 1;
        } else if (a < b) {
            return -1;
        }
        return 0;
    };

    const handleSwitchOnChange = (record: RuleTypes, checked: boolean) => {
        let disabledRulesList = store!.disabledRules;
        if (checked) {
            disabledRulesList = disabledRulesList.filter(d => d !== record.id);
        } else {
            disabledRulesList.push(record.id);
        }
        setDisabledRules(disabledRulesList);
        store!.handleDisabedRuleClick(record);
    };

    const handleEnterPress = async (e: React.KeyboardEvent<HTMLInputElement>, r: RuleTypes) => {
        if (e.key === 'Enter' && editPriorityState.priority) {
            const newPriority = editPriorityState.priority > 100 ? 100 : editPriorityState.priority;
            if (newPriority !== r.priority && r.id) {
                await store!.saveRulePriority(r.id!, newPriority);
            }
            setEditPriorityState({ ruleId: undefined, priority: undefined });
        } else if (e.key === 'Escape') {
            setEditPriorityState({ ruleId: undefined, priority: undefined });
        }
    };

    const handlePriorityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditPriorityState({ ...editPriorityState, priority: Number(e.currentTarget.value) });
    };

    const handlePriorityEditInit = (rule: RuleTypes) => {
        setEditPriorityState({ ruleId: rule.id!, priority: rule.priority });
    };

    const renderPriorityEditor = (r: RuleTypes) => {
        if (r.id === editPriorityState.ruleId) {
            return (
                <HasPermission
                    entityId={store?.currentProject?.id}
                    permissionClaim={AppPermissions.CanEditImportExportRules}
                    yes={() => (
                        <Input
                            data-id-cells="Priority"
                            data-id-type="rule-list"
                            data-id-value={r.priority}
                            min={1}
                            max={100}
                            style={{ width: 70 }}
                            onKeyUp={e => handleEnterPress(e, r)}
                            onChange={handlePriorityChange}
                            value={editPriorityState.priority}
                            autoFocus
                            type="number"
                        />
                    )}
                    no={() => <span style={{ cursor: 'pointer', color: 'blue' }}>{r.priority}</span>}
                />
            );
        } else {
            return (
                <span
                    data-id-cells="Priority"
                    data-id-type="rule-list"
                    data-id-value={r.priority}
                    style={{ cursor: 'pointer', color: 'blue' }}
                    onClick={() => handlePriorityEditInit(r)}
                >
                    {r.priority}
                </span>
            );
        }
    };

    const menu = (record: RuleTypes) => {
        return (
            <Menu data-id-name={record.name} data-id-type="popup-menu-list-root">
                <Menu.Item
                    data-id-name={'Rule'}
                    data-id-type="popup-menu-list-item"
                    key="1"
                    onClick={() => store!.handleCopyRuleClick(record)}
                >
                    Copy Rule
                </Menu.Item>
                <Menu.Item
                    data-id-name={'Steps'}
                    data-id-type="popup-menu-list-item"
                    key="2"
                    onClick={() => store!.handleCopyPipelineClick(record)}
                >
                    Copy Steps
                </Menu.Item>
                <Popconfirm
                    id={`data-id-popconfirm-box-${record.name}`}
                    title={store!.deleteConfirmationText[record.tag]}
                    okText="Yes"
                    onConfirm={() => store!.delete(record)}
                    cancelText="No"
                >
                    <Menu.Item data-id-name={'Delete'} data-id-type="popup-menu-list-item" key="3" danger>
                        Delete
                    </Menu.Item>
                </Popconfirm>
            </Menu>
        );
    };

    const handleRulePinToggle = (record: RuleTypes) => {
        if (!store || !record.id) {
            return;
        }

        if (store.pinnedRules?.ruleIds.includes(record.id)) {
            store.unpinRule(record.id);
        } else {
            store.pinRule(record.id);
        }
    };

    const expandedRowRender = (tag: TagModel) => {
        const expandedColumns: ColumnProps<RuleTypes>[] = [
            {
                key: 'check',
                width: 64,
                render: (__: string, record: RuleTypes) => (
                    <HasPermission
                        entityId={store?.currentProject?.id}
                        permissionClaim={AppPermissions.CanEditImportExportRules}
                    >
                        <Checkbox
                            style={{ marginLeft: 16, paddingRight: 4 }}
                            checked={!!selectedRuleRowKeys.find(s => s === record.id)}
                            onChange={e => onRuleSelectChange(e.target.checked, record.id!)}
                            disabled={!!(selectedTagRowKeys as string[]).find(s => s === tag.name)}
                        />
                    </HasPermission>
                )
            },
            {
                title: 'Rule name',
                key: 'name',
                dataIndex: 'name',
                className: 'rule-name-column',
                sorter: (a: RuleTypes, b: RuleTypes) => safeStringLocaleCompare(a.name, b.name),

                // eslint-disable-next-line @typescript-eslint/no-shadow
                render: (_, record: RuleTypes) => {
                    return {
                        children: record.name,
                        props: {
                            'data-id-value': record.name,
                            'data-id-type': 'rules-list',
                            'data-id-cells': 'Rule name'
                        }
                    };
                }
            },
            {
                title: 'Type',
                key: 'type',
                sorter: (a: RuleTypes, b: RuleTypes) => safeStringLocaleCompare(a.ruleType, b.ruleType),
                render: (__, record: RuleTypes) => {
                    if (record.description && record.description.trim() !== '') {
                        return (
                            <Tooltip title={record.description}>
                                <div
                                    data-id-value={record.description}
                                    data-id-type="rule-list"
                                    data-id-cells="Type"
                                    style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: 230
                                    }}
                                >
                                    {record.description}
                                </div>
                            </Tooltip>
                        );
                    } else {
                        return {
                            children: <div>{getFriendlyRuleTypeName(record.ruleType)}</div>,
                            props: {
                                'data-id-value': record.ruleType,
                                'data-id-type': 'rules-list',
                                'data-id-cells': 'Type'
                            }
                        };
                    }
                }
            },
            {
                title: 'Priority',
                dataIndex: 'priority',
                key: 'priority',
                defaultSortOrder: 'ascend',
                sorter: (a, b) => sortPriorities(a.priority, b.priority),
                width: 100,
                render: (__, record: RuleTypes) => renderPriorityEditor(record)
            },
            {
                title: 'Modified Date',
                dataIndex: 'updateDate',
                key: 'updateDate',
                width: 170,
                sorter: (a: RuleTypes, b: RuleTypes) => Utils.safeDateCompare(a.updateDate, b.updateDate),
                render: (__, record: RuleTypes) => {
                    const val = record.updateDate ? new Date(record.updateDate!).toLocaleString() : '';
                    const isoDate = record.updateDate ? new Date(record.updateDate!).toISOString() : '';
                    return (
                        <div className="operation-label-container" style={{ minWidth: 150 }}>
                            <span className="table-operation" style={{ float: 'right' }}>
                                <HasPermission
                                    entityId={store?.currentProject?.id}
                                    permissionClaim={AppPermissions.CanEditImportExportRules}
                                >
                                    <Observer>
                                        {() => (
                                            <Tooltip
                                                title={
                                                    record.id && store!.pinnedRules?.ruleIds?.includes(record.id)
                                                        ? 'Unpin rule'
                                                        : 'Pin rule'
                                                }
                                            >
                                                <Button
                                                    data-id-cells="Pin rule"
                                                    data-id-value=""
                                                    data-id-type="rule-list"
                                                    type="link"
                                                    size="small"
                                                    onClick={() => handleRulePinToggle(record)}
                                                >
                                                    <i
                                                        className={`alpha-icon lg ${record.id && store!.pinnedRules?.ruleIds?.includes(record.id) ? 'unpin-icon' : 'pin-icon'}`}
                                                    />
                                                </Button>
                                            </Tooltip>
                                        )}
                                    </Observer>
                                    <Tooltip title="Edit rule">
                                        <Button
                                            data-id-cells="Edit rule"
                                            data-id-value=""
                                            data-id-type="rule-list"
                                            type="link"
                                            size="small"
                                            onClick={() => store!.edit(record.id!)}
                                        >
                                            <i className="alpha-icon lg edit-icon" />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="More actions" placement="bottom">
                                        <Dropdown overlay={() => menu(record)} trigger={['click']} placement="topRight">
                                            <Button
                                                data-id-cells="More actions"
                                                data-id-value=""
                                                data-id-type="rule-list"
                                                type="link"
                                                size="small"
                                            >
                                                <i className="alpha-icon lg more-icon" />
                                            </Button>
                                        </Dropdown>
                                    </Tooltip>
                                </HasPermission>
                            </span>
                            <span
                                data-id-cells="Modified Date"
                                data-id-type="rule-list"
                                data-id-value={val}
                                data-id-timestamp={isoDate}
                                className="operation-info-label"
                            >
                                {val}
                            </span>
                        </div>
                    );
                }
            },
            {
                key: 'operation',
                width: 140,
                render: (__, record: RuleTypes) => (
                    <div style={{ textAlign: 'right', paddingRight: 15 }}>
                        <RuleErrorIcon rule={record} />
                        <HasPermission
                            entityId={store?.currentProject?.id}
                            permissionClaim={AppPermissions.CanEditImportExportRules}
                        >
                            <span
                                className="table-operation"
                                style={{ display: 'inline-block', verticalAlign: 'middle' }}
                            >
                                <Observer>
                                    {() => (
                                        <Switch
                                            data-id-cells="Toggle rule"
                                            data-id-value={record.state === 'Enabled'}
                                            data-id-type="rule-list"
                                            checked={record.state === 'Enabled'}
                                            className="sm-switch"
                                            style={{ marginLeft: 30, marginBottom: 11 }}
                                            size="default"
                                            onChange={(checked: boolean) => handleSwitchOnChange(record, checked)}
                                        />
                                    )}
                                </Observer>
                            </span>
                        </HasPermission>
                    </div>
                )
            }
        ];

        return (
            <Observer>
                {() => (
                    <Table
                        className="rules-table-container"
                        size="small"
                        rowKey="id"
                        columns={expandedColumns}
                        dataSource={store!.filteredRules[tag.name]}
                        pagination={false}
                        rowClassName={record =>
                            'rules-table-row' +
                            (store!.selectedRule[record.id!] || '') +
                            (disabledRules.find(d => d === record.id) ? ' disabled-rule' : '')
                        }
                    />
                )}
            </Observer>
        );
    };

    const handleDeleteTag = (tagId: string, groupId: string) => {
        store?.setCurrentTagGroupId(groupId);
        store!.deleteTag(tagId);
    };

    const getTagRulesCountLabel = (tagName: string) => {
        const rulesCount = store!.filteredRules[tagName]?.length ?? 0;

        if (rulesCount === 0) {
            return 'No rules';
        }

        return `${rulesCount} rule${rulesCount > 1 ? 's' : ''}`;
    };

    const columns: ColumnProps<TagModel>[] = [
        {
            title: 'Tag',
            key: 'name',
            dataIndex: 'name',
            render: (__, record) => {
                return {
                    children: <span style={{ fontWeight: 500 }}>{record.name}</span>,
                    props: { 'data-id-type': 'tags-list', 'data-id-cells': 'Tag', 'data-id-value': record.name }
                };
            }
        },
        {
            title: '',
            key: 'tag-operation',
            dataIndex: 'tag-operation',
            render: (__, record) => {
                return (
                    <div className="operation-label-container" style={{ textAlign: 'right' }}>
                        <span className="table-operation" style={{ float: 'right' }}>
                            <HasPermission
                                entityId={store?.currentProject?.id}
                                permissionClaim={AppPermissions.CanEditGroupsTags}
                            >
                                <Tooltip title="Add rule">
                                    <Button
                                        data-id-cells="Add rule"
                                        data-id-value=""
                                        data-id-type="tags-list"
                                        type="link"
                                        onClick={e => {
                                            e.stopPropagation();
                                            ruleCreateStore!.setPreselectedData(record.groupId, record.id!);
                                            ruleCreateStore!.setIsCreateDialogVisible(true);
                                        }}
                                        size="small"
                                    >
                                        <i className="alpha-icon lg plus-icon" />
                                    </Button>
                                </Tooltip>
                            </HasPermission>
                            <HasPermission
                                entityId={store?.currentProject?.id}
                                permissionClaim={AppPermissions.CanEditGroupsTags}
                            >
                                <Tooltip title="Edit tag">
                                    <Button
                                        data-id-cells="Edit tag"
                                        data-id-value=""
                                        data-id-type="tags-list"
                                        type="link"
                                        size="small"
                                        onClick={e => {
                                            e.stopPropagation();
                                            store!.handleTagEditClick(record.name);
                                        }}
                                    >
                                        <i className="alpha-icon lg edit-icon" />
                                    </Button>
                                </Tooltip>
                            </HasPermission>
                            <Tooltip title="Tag preview">
                                <Button
                                    onClick={e => {
                                        e.stopPropagation();
                                        store!.previewTag(record.id!);
                                    }}
                                    type="link"
                                    size="small"
                                    data-id-cells="Tag preview"
                                    data-id-value=""
                                    data-id-type="tags-list"
                                >
                                    <i className="alpha-icon lg play-icon" />
                                </Button>
                            </Tooltip>
                            <HasPermission
                                entityId={store?.currentProject?.id}
                                permissionClaim={AppPermissions.CanEditGroupsTags}
                            >
                                <Popconfirm
                                    id={`data-id-popconfirm-box-${record.name}`}
                                    title={
                                        <div style={{ width: 500 }}>
                                            {store!.getDeleteTagConfirmationText(record.id!)}
                                        </div>
                                    }
                                    okText="Yes"
                                    onConfirm={() => handleDeleteTag(record.id!, record.groupId)}
                                    cancelText="No"
                                    onCancel={e => e!.stopPropagation()}
                                >
                                    <Tooltip title="Delete tag">
                                        <Button
                                            onClick={e => e.stopPropagation()}
                                            data-id-cells="Delete tag"
                                            data-id-type="tag-list"
                                            type="link"
                                            size="small"
                                        >
                                            <i className="alpha-icon lg delete-icon" />
                                        </Button>
                                    </Tooltip>
                                </Popconfirm>
                            </HasPermission>
                            <TagErrorIcon store={store!} tag={record} />
                        </span>
                        <span className="operation-info-label gray">
                            {getTagRulesCountLabel(record.name)}
                            <TagErrorIcon store={store!} tag={record} />
                        </span>
                    </div>
                );
            }
        }
    ];

    const tagsRowComponents = {
        body: {
            row: DragableBodyRow
        }
    };

    const moveTagRow = (dragIndex: number, dropIndex: number, originalGroupId: string) => {
        if (originalGroupId !== tagsGroup.id) {
            return;
        }
        store!.updateTagRowPosition(tagsGroup.id!, dragIndex, dropIndex);
    };

    const getTable = (hasPermission: boolean) => {
        return (
            <Observer>
                {() => (
                    <Table
                        className="components-table-demo-nested rules-list-table"
                        columns={columns}
                        rowKey="id"
                        rowSelection={
                            hasPermission
                                ? { selectedRowKeys: selectedTagRowKeys, onChange: onTagSelectChange }
                                : undefined
                        }
                        pagination={false}
                        expandRowByClick
                        showHeader={false}
                        dataSource={store!.filteredTags
                            .filter(t => t.groupId === tagsGroup.id)
                            .slice()
                            .sort((a, b) => a.position! - b.position!)}
                        expandedRowRender={expandedRowRender}
                        expandedRowKeys={expandedRowKeys}
                        expandIcon={props => (
                            <i
                                className="alpha-icon xs expand-row arrow-expand"
                                onClick={e => {
                                    props.onExpand(props.record, e);
                                }}
                            />
                        )}
                        onExpand={handleRowOnExpand}
                        size="small"
                        rowClassName={record =>
                            store!.expandedRows.includes(record.id!) ? 'expanded tags-table-row' : 'tags-table-row'
                        }
                        onRow={
                            hasPermission
                                ? (record, index) =>
                                      ({
                                          index,
                                          record,
                                          moveTagRow: moveTagRow
                                      }) as any
                                : undefined
                        }
                        components={hasPermission ? tagsRowComponents : undefined}
                    />
                )}
            </Observer>
        );
    };

    const getFormContent = (isNewTagModalVisible: boolean) => {
        return !isNewTagModalVisible ? (
            <Form form={form} layout="vertical" data-id="copy-rule-dialog-form">
                <FormItem
                    label="Tag to copy to"
                    name="tag"
                    rules={[
                        {
                            required: true,
                            message: 'Please select tag!',
                            whitespace: true
                        }
                    ]}
                >
                    <Select onSelect={(key: string) => store!.handleTagsDropdownSelect(key)} showSearch>
                        {[
                            { name: 'CREATE NEW TAG' },
                            ...store!.tags.slice().sort((a, b) => a.name.localeCompare(b.name))
                        ].map((t, i) => {
                            const weight = i === 0 ? 'bold' : 'unset';
                            return (
                                <Option
                                    style={{ fontWeight: weight }}
                                    data-id={`rules-list-copy-rule-tag-${i}`}
                                    value={t.name}
                                    key={t.name}
                                >
                                    {t.name}
                                </Option>
                            );
                        })}
                    </Select>
                </FormItem>
            </Form>
        ) : (
            <Form form={form} layout="vertical" data-id="copy-rule-dialog-form">
                <FormItem
                    label="Tag to copy to"
                    name="newTag"
                    rules={[{ required: true, message: 'Please input tag!', whitespace: true }]}
                >
                    <Input />
                </FormItem>
            </Form>
        );
    };

    return (
        <div>
            <Modal
                title="Copy rule"
                visible={store!.isModalVisible}
                onCancel={() => {
                    store!.setIsModalVisible(false);
                    store!.curentRuleIdForCopy = null;
                }}
                maskClosable={false}
                destroyOnClose
                closable={false}
                className="alpha-portal-dialog"
                centered
                footer={[
                    <Button
                        data-id="copy-rule-dialog-cancel"
                        key="back"
                        size="large"
                        className="light"
                        onClick={() => store!.setIsModalVisible(false)}
                    >
                        Cancel
                    </Button>,
                    <Button
                        data-id="copy-rule-dialog-submit"
                        key="submit"
                        size="large"
                        type="primary"
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                ]}
            >
                {getFormContent(store!.isNewTagFormVisible)}
            </Modal>
            {store?.tagRenameDialog && <TagRenameDialog RulesListUI={store} />}
            <DndProvider backend={HTML5Backend}>
                <HasPermission
                    entityId={store?.currentProject?.id}
                    permissionClaim={AppPermissions.CanEditImportExportRules}
                    yes={() => getTable(true)}
                    no={() => getTable(false)}
                />
            </DndProvider>
        </div>
    );
};

const WrappedRulesList = observer(RulesList);

export default WrappedRulesList;

import axios, { ResponseType } from 'axios';
import security from './SecurityService';
import { Result, ok, err } from 'neverthrow';
import { ErrorMsg } from '../stores/ErrorStore';
// @ts-ignore
import uuid from 'uuid/v1';
import axiosRetry from 'axios-retry';
import { trace, SpanKind, Span, SpanStatusCode } from '@opentelemetry/api';

export interface ResponseData {
    status: number;
    statusText: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
}

export interface ResponseErr {
    data: ErrorMsg | undefined;
    status: number;
    text: string;
}

export interface SSEOptions<T> {
    onMessage?: (data: T) => void;
    onError?: (error: Error) => void;
}

export type ResultApi<T> = Result<T, ResponseErr>;

class AppClient {
    constructor() {
        axios.interceptors.request.use(config =>
            security.invoke(token => {
                config.headers = {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'X-Request-Id': uuid(),
                    ...config.headers
                };
                return Promise.resolve(config);
            })
        );

        const retryableStatusCodes = [408, 429, 502, 503, 504];

        axiosRetry(axios, {
            retries: 5,
            retryDelay: axiosRetry.exponentialDelay,
            retryCondition: error => retryableStatusCodes.includes(error.response?.status ?? 0)
        });
    }

    async get<T>(url: string, responsetype: ResponseType = 'json'): Promise<ResultApi<T>> {
        const parentSpanData = this.getOpenTelemetrySpan('GET', url);
        const { parentSpan, traceId, spanId } = parentSpanData;
        try {
            const resp = await axios.get<T>(url, {
                responseType: responsetype,
                headers: {
                    traceparent: `00-${traceId}-${spanId}-01`
                }
            });

            parentSpan.end(Date.now());
            return ok(resp.data);
        } catch (ex) {
            this.endSpanWithError(parentSpan, ex, url);
            return this.ProcessError(ex);
        }
    }

    async post<T>(url: string, data?: unknown): Promise<ResultApi<T>> {
        const parentSpanData = this.getOpenTelemetrySpan('POST', url);
        const { parentSpan, traceId, spanId } = parentSpanData;
        try {
            const resp = await axios.post<T>(url, data, {
                headers: {
                    traceparent: `00-${traceId}-${spanId}-01`
                }
            });
            parentSpan.end(Date.now());
            return ok(resp.data);
        } catch (ex) {
            this.endSpanWithError(parentSpan, ex, url);
            return this.ProcessError(ex);
        }
    }

    async postRaw(url: string, data?: unknown): Promise<ResponseData> {
        const parentSpanData = this.getOpenTelemetrySpan('POST', url);
        const { parentSpan, traceId, spanId } = parentSpanData;
        try {
            const resp = await axios.post(url, data, {
                headers: {
                    traceparent: `00-${traceId}-${spanId}-01`
                }
            });
            parentSpan.end(Date.now());
            return {
                data: resp.data,
                status: resp.status,
                statusText: resp.statusText
            };
        } catch (ex) {
            this.endSpanWithError(parentSpan, ex, url);
            return this.ProcessErrorRaw(ex);
        }
    }

    async update<T>(url: string, data?: unknown): Promise<ResultApi<T>> {
        const parentSpanData = this.getOpenTelemetrySpan('PUT', url);
        const { parentSpan, traceId, spanId } = parentSpanData;
        try {
            const resp = await axios.put<T>(url, data, {
                headers: {
                    traceparent: `00-${traceId}-${spanId}-01`
                }
            });
            parentSpan.end(Date.now());
            return ok(resp.data);
        } catch (ex) {
            this.endSpanWithError(parentSpan, ex, url);
            return this.ProcessError(ex);
        }
    }

    async updateRaw(url: string, data?: unknown): Promise<ResponseData> {
        const parentSpanData = this.getOpenTelemetrySpan('PUT', url);
        const { parentSpan, traceId, spanId } = parentSpanData;
        try {
            const resp = await axios.put(url, data, {
                headers: {
                    traceparent: `00-${traceId}-${spanId}-01`
                }
            });

            parentSpan.end(Date.now());
            return {
                data: resp.data,
                status: resp.status,
                statusText: resp.statusText
            };
        } catch (ex) {
            this.endSpanWithError(parentSpan, ex, url);
            return this.ProcessErrorRaw(ex);
        }
    }

    async delete<T>(url: string): Promise<ResultApi<T>> {
        const parentSpanData = this.getOpenTelemetrySpan('DELETE', url);
        const { parentSpan, traceId, spanId } = parentSpanData;
        try {
            const resp = await axios.delete<T>(url, {
                headers: {
                    traceparent: `00-${traceId}-${spanId}-01`
                }
            });

            parentSpan.end(Date.now());
            return ok(resp.data);
        } catch (ex) {
            this.endSpanWithError(parentSpan, ex, url);
            return this.ProcessError(ex);
        }
    }

    async deleteRaw(url: string): Promise<ResponseData> {
        const parentSpanData = this.getOpenTelemetrySpan('DELETE', url);
        const { parentSpan, traceId, spanId } = parentSpanData;
        try {
            const resp = await axios.delete(url, {
                headers: { 'Content-Type': 'application/json', traceparent: `00-${traceId}-${spanId}-01` }
            });

            parentSpan.end(Date.now());
            return {
                data: resp.data,
                status: resp.status,
                statusText: resp.statusText
            };
        } catch (ex) {
            this.endSpanWithError(parentSpan, ex, url);
            return this.ProcessErrorRaw(ex);
        }
    }

    async createStreamingRequest<T>(url: string, data: unknown, options: SSEOptions<T>): Promise<void> {
        try {
            await axios({
                method: 'POST',
                url: url,
                data: data,
                responseType: 'text',
                onDownloadProgress: progressEvent => {
                    const response = progressEvent.target.response;
                    if (!response) return;

                    // Split response by lines and process each line
                    const lines = response.split('\n');
                    for (const line of lines) {
                        if (!line.trim()) continue;

                        try {
                            // Handle SSE formatted messages
                            if (line.startsWith('data: ')) {
                                const jsonData = line.substring(6); // Remove 'data: ' prefix
                                const parsedData = JSON.parse(jsonData);

                                // Handle error messages
                                if (parsedData.error) {
                                    options.onError?.(new Error(parsedData.error));
                                    return;
                                }

                                options.onMessage?.(parsedData);
                            } else if (line.startsWith('event: ')) {
                                // Handle event type messages if needed in the future
                                // const eventType = line.substring(7);
                                continue;
                            } else {
                                console.warn('Unexpected SSE message format:', line);
                            }
                        } catch (error) {
                            console.error('Error parsing SSE message:', error);
                            // Don't throw parsing errors to avoid breaking the stream
                            // but log them for debugging
                        }
                    }
                }
            });
        } catch (error) {
            options.onError?.(error as Error);
            throw error;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private endSpanWithError(span: Span, error: any, url: string) {
        span.setStatus({
            code: SpanStatusCode.ERROR,
            message: error.message
        });

        span.recordException(error);

        const { hostname, port } = new URL(url);
        span.setAttributes({
            'http.url': url,
            'http.port': port,
            'net.peer.name': hostname,
            'peer.service': `${hostname}${port ? `:${port}` : ''}`
        });
        span.end();
    }

    private getOpenTelemetrySpan(method: string, url: string) {
        const tracer = trace.getTracer('web_portal');
        const path = new URL(url).pathname;

        // Replace the id with {id} to avoid the excessive operations populating on Jaeger UI
        let spanName = path;
        const idsRegex = new RegExp(/[a-z0-9\b]{20,32}/g);
        const ids = path.match(idsRegex);

        let attributes: { key: string; value: string }[] = [];
        if (ids) {
            ids.forEach((id, i) => {
                spanName = spanName.replace(id, `id${i}`);
                attributes.push({ key: `id${i}`, value: id });
            });
        }

        const parentSpan = tracer.startSpan(method + ': ' + spanName, {
            kind: SpanKind.CLIENT,
            startTime: Date.now()
        });
        const traceId = parentSpan.spanContext().traceId;
        const spanId = parentSpan.spanContext().spanId;

        attributes.forEach(attr => {
            parentSpan.setAttribute(attr.key, attr.value);
        });

        return {
            traceId,
            spanId,
            parentSpan
        };
    }

    private ProcessErrorRaw(ex: unknown): ResponseData {
        if (axios.isAxiosError(ex)) {
            if (ex.response?.headers['content-type'] === 'application/json') {
                return {
                    statusText: ex.response!.statusText,
                    status: ex.response!.status,
                    data: ex.response?.data
                };
            }

            if (ex.response) {
                return {
                    statusText: ex.response!.statusText,
                    status: ex.response!.status,
                    data: {
                        title: ex.response?.data ?? ex.response?.statusText,
                        details: '',
                        status: -1,
                        type: 'unknown',
                        stackTrace: ''
                    }
                };
            }

            return {
                statusText: ex.message,
                status: -1,
                data: {
                    title: ex.message,
                    details: ex.stack ?? '',
                    status: -1,
                    type: 'NETWORK_ERROR',
                    stackTrace: ''
                }
            };
        }

        return {
            statusText: 'unknown',
            status: -1,
            data: undefined
        };
    }

    private ProcessError<T>(ex: unknown): ResultApi<T> {
        if (axios.isAxiosError(ex)) {
            if (ex.response?.headers['content-type'] === 'application/json') {
                return err({
                    text: ex.response!.statusText,
                    status: ex.response!.status,
                    data: ex.response?.data
                });
            }

            if (ex.response) {
                return err({
                    text: ex.response!.statusText,
                    status: ex.response!.status,
                    data: {
                        title: ex.response?.data ?? ex.response?.statusText,
                        detail: '',
                        status: -1,
                        type: 'unknown',
                        stackTrace: ''
                    }
                });
            }

            return err({
                text: ex.message,
                status: -1,
                data: {
                    title: ex.message,
                    detail: ex.stack ?? '',
                    status: -1,
                    type: 'NETWORK_ERROR',
                    stackTrace: ''
                }
            });
        }

        return err({
            text: 'unknown',
            status: -1,
            data: {
                title: 'unknown',
                detail: '',
                status: -1,
                type: 'unknown',
                stackTrace: ''
            }
        });
    }
}

const appClient = new AppClient();

export default appClient;

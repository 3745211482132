import * as React from 'react';
import { monitorRouterMatch, ProtectedRoute } from '../../common/components';
import PromptTemplatesPage from '../screens/PromptTemplatesPage';
import CreatePromptTemplateScreen from '../screens/CreatePromptTemplateScreen';
import EditPromptTemplateScreen from '../screens/EditPromptTemplateScreen';
import { STORE_PROMPT_TEMPLATES } from '../stores/constants';

export const PromptTemplatesNavigation = {
    PromptTemplatesPage: '/projects/:projectId/prompt-templates',
    CreatePromptTemplatesScreen: '/projects/:projectId/prompt-templates/create',
    EditPromptTemplateScreen: '/projects/:projectId/prompt-templates/:templateId/edit'
};

export default () => {
    return (
        <>
            <ProtectedRoute
                exact
                component={PromptTemplatesPage}
                path={PromptTemplatesNavigation.PromptTemplatesPage}
            />
            <ProtectedRoute
                component={CreatePromptTemplateScreen}
                path={PromptTemplatesNavigation.CreatePromptTemplatesScreen}
            />
            <ProtectedRoute
                component={monitorRouterMatch(STORE_PROMPT_TEMPLATES, (s, m) =>
                    s[STORE_PROMPT_TEMPLATES].setSelectedTemplate(m!.params)
                )(EditPromptTemplateScreen)}
                path={PromptTemplatesNavigation.EditPromptTemplateScreen}
            />
        </>
    );
};

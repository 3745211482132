import { RootStores } from '../../common/stores';
import { STORE_PROJECTS_ROOT_UI } from '../../common/constants';
import { STORE_INSTRUCT_WORKFLOW, STORE_INSTRUCT_WORKFLOW_IMPORT_EXPORT } from './constants';
import InstructWorkflowCreateEditStore from './InstructWorkflowCreateEditStore';
import InstructWorkflowImportExportStore from './InstructWorkflowImportExportStore';
import InstructWorkflowService from '../services/InstructWorkflowService';

export { default as InstructWorkflowCreateEditStore } from './InstructWorkflowCreateEditStore';
export { default as InstructWorkflowImportExportStore } from './InstructWorkflowImportExportStore';
export { default as InstructWorkflowParametersStore } from './InstructWorkflowParametersStore';

export function injectInstructWorkflowStores(obj: RootStores) {
    const service = new InstructWorkflowService();

    obj[STORE_INSTRUCT_WORKFLOW] = new InstructWorkflowCreateEditStore(obj[STORE_PROJECTS_ROOT_UI], service);
    obj[STORE_INSTRUCT_WORKFLOW_IMPORT_EXPORT] = new InstructWorkflowImportExportStore(
        obj[STORE_PROJECTS_ROOT_UI],
        obj[STORE_INSTRUCT_WORKFLOW],
        service
    );
}

export type InstructWorkflowStores = {
    [STORE_INSTRUCT_WORKFLOW]?: InstructWorkflowCreateEditStore;
    [STORE_INSTRUCT_WORKFLOW_IMPORT_EXPORT]?: InstructWorkflowImportExportStore;
};

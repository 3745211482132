import { observer } from 'mobx-react-lite';
import * as React from 'react';
import PromptTemplateStore from '../stores/PromptTemplatesStore';
import { Button, Layout, Popconfirm, Space, Table } from 'antd';
import LayoutHeader from '../../../components/LayoutHeader';
import { CopyOutlined, DeleteOutlined, EditOutlined, ExportOutlined, PlusOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import { Utils } from '../../common/services/Utils';
import { PromptTemplate } from '../types';
import './PromptTemplateList.less';
import PromptTemplateImportExportStore from '../stores/PromptTemplateImportExportStore';
import PromptTemplateUploader from './PromptTemplateUploader/PromptTemplateUploader';
import DuplicatePromptTemplateDialog from './DuplicateTemplateDialog/DuplicatePromptTemplateDialog';
type Props = {
    store: PromptTemplateStore;
    importExportStore: PromptTemplateImportExportStore;
};

const PromptTemplateList: React.FC<Props> = ({ store, importExportStore }) => {
    React.useEffect(() => {
        if (store.currentProject) {
            store.loadPromptTemplates();
        }
    }, [store, store.currentProject]);

    const handleDuplicate = (templateId: string) => {
        store.setTemplateToDuplicate(templateId);
    };

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            sorter: (a: PromptTemplate, b: PromptTemplate) => a.title.localeCompare(b.title)
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (description: string) => description || ''
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            render: (updatedBy: string) => store.getUserNameById(updatedBy)
        },
        {
            title: 'Last Modified',
            dataIndex: 'updatedTime',
            key: 'updatedTime',
            render: (date: string | null) => (date ? Utils.formatDateStringShort(date) : '-'),
            sorter: (a: PromptTemplate, b: PromptTemplate) => Utils.safeDateStringCompare(a.updatedTime, b.updatedTime)
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_: unknown, record: PromptTemplate) => (
                <Space size="middle">
                    <Button
                        type="text"
                        icon={<EditOutlined />}
                        onClick={() => store.navigateToEditTemplate(record.id!)}
                    />
                    <Button
                        type="text"
                        icon={<ExportOutlined />}
                        onClick={() => importExportStore.exportTemplate(record.id!)}
                    />
                    <Button type="text" icon={<CopyOutlined />} onClick={() => handleDuplicate(record.id!)} />
                    <Popconfirm
                        title={`Are you sure you want to delete the template "${record.title}"?`}
                        onConfirm={() => store.deletePromptTemplate(record.id)}
                        okText="Delete"
                        cancelText="Cancel"
                        okButtonProps={{ danger: true }}
                    >
                        <Button type="text" icon={<DeleteOutlined />} danger title="Delete template" />
                    </Popconfirm>
                </Space>
            )
        }
    ];

    return (
        <Layout style={{ height: '100%', background: 'white' }}>
            <LayoutHeader
                subtitle={Utils.getSubtitle(store.currentProject)}
                title="Prompt Templates"
                buttons={[
                    <PromptTemplateUploader key="import-prompt-template" importExportStore={importExportStore} />,

                    <Button
                        key="create-prompt-template"
                        data-id="button-create-data-crafter-template"
                        type="primary"
                        icon={<PlusOutlined />}
                        size="large"
                        onClick={() => store.navigateToCreateTemplate()}
                    >
                        Create Template
                    </Button>
                ]}
            />
            <Layout className="prompt-templates-list">
                <Content style={{ maxHeight: 'calc(100vh - 70px)', overflowY: 'auto' }}>
                    <div className="content-wrapper">
                        <Table
                            dataSource={store.sortedPromptTemplates}
                            columns={columns}
                            loading={store.isLoadingTemplates}
                            rowKey="id"
                            pagination={false}
                        />
                    </div>
                </Content>
            </Layout>
            <DuplicatePromptTemplateDialog store={store} />
        </Layout>
    );
};

export default observer(PromptTemplateList);

/* eslint-disable @typescript-eslint/member-ordering */
import { observable, action } from 'mobx';
import { Subject } from 'rxjs';
import { FieldData } from 'rc-field-form/lib/interface';
import { OnHitEventArgs } from 'react-drag-drop-container';
import { CurrentUiState } from './RulesPipelineVisualStore';
import { EditorModel, CompileDummyCodeType } from '../types';
import { PipelineStep } from '../models';
import { Project } from '../../common/models';
import { CodeSnippet, CodeSnippetGroup } from '../../administration/models';
import { FormInstance } from 'antd/lib/form';
import { ResultApi } from '../../common/services/AppClient';
import PipelineStepType from '../types/PipelineStepType';

export type UserDefinedParams = {
    [key: string]: string;
};

type UserDefinedEmptyParams = {
    [key: number]: string[];
};

type SubmissionDict = {
    [key: number]: { submit: () => void; isValidationFailed: boolean };
};

type FormFieldsSubject = { stepInternalId: string } & Partial<{
    initialValues: Record<string, unknown>;
    changedFields: FieldData[];
}>;

export default abstract class PipelineVisualStore<TPipelineStepType extends string> {
    isLoading: boolean;

    get pipeline(): PipelineStep<TPipelineStepType>[] | undefined {
        return undefined;
    }

    swapPipelineStepIndex: number;

    editorModel: EditorModel<TPipelineStepType>;

    description: CurrentUiState = {};

    get types(): string[] {
        return [];
    }

    pipelineFormSubmissions: SubmissionDict = {};

    @observable
    emptyParametersArr: UserDefinedEmptyParams = {};

    @observable
    codeSnippets: CodeSnippet[] = [];

    @observable
    codeSnippetGroups: CodeSnippetGroup[] = [];

    formFieldsSubject: Subject<FormFieldsSubject> = new Subject();

    pipelineStepValuesSubject: Subject<Record<string, unknown>> = new Subject();

    setFormSubmision(pipelineStepSubmit: () => Promise<void>, stepKey: number) {
        this.pipelineFormSubmissions[stepKey] = { submit: pipelineStepSubmit, isValidationFailed: false };
    }

    setValidationFailed(stepKey: number, isFailed: boolean) {
        this.pipelineFormSubmissions[stepKey]!.isValidationFailed = isFailed;
    }

    removeFormSubmission(stepKey: number) {
        delete this.pipelineFormSubmissions[stepKey];
    }

    @action
    addEmptyParamForUserDefinedCard(stepKey: number) {
        let arr = this.emptyParametersArr[stepKey];
        if (!arr) {
            arr = [];
        }
        arr.push('');
        this.emptyParametersArr[stepKey] = arr;
    }

    @action
    removeEmptyParamForUserDefinedCard(stepKey: number) {
        this.emptyParametersArr[stepKey] = [];
    }

    async submitPipelineForms() {
        const promises = Object.values(this.pipelineFormSubmissions).map(s => s.submit());
        await Promise.all(promises);
    }

    isValidationFailed() {
        return !!Object.values(this.pipelineFormSubmissions).find(x => x.isValidationFailed === true);
    }

    abstract getEditorModels(): void;

    abstract loadCodeSnippets(): void;

    abstract loadCodeSnippetGroups(): void;

    abstract swapPipeLineSteps(e: OnHitEventArgs, index: number): void;

    abstract setName(step: PipelineStep<TPipelineStepType>, value: string): void;

    abstract setDescription(stepKey: number, type: TPipelineStepType): void;

    abstract setType(step: PipelineStep<TPipelineStepType>, value: string): void;

    abstract removeStep(step: PipelineStep<TPipelineStepType>): void;

    abstract addParameters(
        params: UserDefinedParams | Object,
        step: PipelineStep<TPipelineStepType>,
        isUserDefined: boolean
    ): void;

    abstract newStep(): void;

    abstract get currentProject(): Project | null;

    abstract validateForm(
        form: FormInstance,
        step: PipelineStep<TPipelineStepType>,
        stepKey: number,
        isUserDefined: boolean
    ): Promise<void>;

    abstract isFormSubmissionExists(key: number): boolean;

    abstract executeBusinessRuleCode(code: string, compileDummyCodeType?: CompileDummyCodeType): void;

    abstract executeBusinessRuleQuery(connectionId: string, query: string): Promise<ResultApi<unknown>>;

    abstract validateRegexPatternValue(
        value: string,
        regex: string,
        stepType: PipelineStepType,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        parameters: Map<string, any>
    ): Promise<string>;
}

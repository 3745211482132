import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Form } from 'antd';
import PipelineStep from '../../pipeline_base/models/PipelineStep';
import PipelineVisualStore from '../stores/PipelineVisualStore';
import { Parameter } from '../types';
import { FormInstance } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import useDisplayConditions from '../hooks/useDisplayConditions';

type Props<T extends PipelineVisualStore<TPipelineStepType>, TPipelineStepType extends string> = {
    param: Parameter;
    form: FormInstance;
    toolTip: JSX.Element;
    step: PipelineStep<TPipelineStepType>;
    projectId: string;
    store: T;
    stepKey: number;
};
export const ParametersTextAreaEditor: <
    T extends PipelineVisualStore<TPipelineStepType>,
    TPipelineStepType extends string
>(
    props: Props<T, TPipelineStepType>
) => React.ReactElement<Props<T, TPipelineStepType>> = ({ param, form, toolTip, step, store, stepKey }) => {
    const handleSubmit = async () => {
        await store.validateForm(form, step, stepKey, false);
    };
    if (!store.isFormSubmissionExists(stepKey)) {
        store.setFormSubmision(handleSubmit, stepKey);
    }

    const { isVisible } = useDisplayConditions({ param, form, store, step });

    return (
        <div style={!isVisible ? { display: 'none' } : {}}>
            <Form.Item
                label={toolTip}
                name={param.name}
                rules={[
                    {
                        required: !step.isDisabled && param.required,
                        message: 'Please input parameter value'
                    }
                ]}
                initialValue={param.defaultValue}
            >
                <TextArea autoSize={{ minRows: 4 }} placeholder={param.type === 'number' ? '0,1' : 'value'} />
            </Form.Item>
        </div>
    );
};

export default observer(ParametersTextAreaEditor);

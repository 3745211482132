import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { DataCrafterTemplateFormStore } from '../../stores';
import { DataCrafterTemplateColumn } from '../../types';
import { AutoCompleteTextArea } from '../../../common/components';

interface Props {
    createStore: DataCrafterTemplateFormStore;
    templateColumn: DataCrafterTemplateColumn;
}

const MappingRuleInput: React.FC<Props> = ({ createStore, templateColumn }: Props) => {
    const options = React.useMemo(
        () => createStore.inputColumns.map(DataCrafterTemplateFormStore.getColumnLabelAndValue),
        [createStore.inputColumns]
    );

    return (
        <AutoCompleteTextArea
            options={options}
            value={templateColumn.mappingRule}
            onChange={value => templateColumn.setMappingRule(value)}
        />
    );
};

export default observer(MappingRuleInput);

import { SecurityService as security } from '../../common/services';
import { MLModelTypesResult, MLModelsResult, MLModel, MLModelType, MLModelRule } from '../types';
import { gql } from 'apollo-boost';
import { execQuery } from '../../common/services/DataService';
import appClient from '../../common/services/AppClient';
import { Utils } from '../../common/services/Utils';

export default class MLService {
    async getAllMLModels(): Promise<MLModel[]> {
        const result = await execQuery<MLModelsResult>({
            query: gql`
                query getMLModels {
                    getMLModels {
                        id
                        name
                        projectId
                        labels
                        type
                        createDate
                        updateDate
                        device
                        description
                        revision {
                            createDate
                            storagePath
                            isActive
                        }
                    }
                }
            `,
            fetchPolicy: 'network-only'
        });
        if (result.errors) {
            console.log(result.errors);
            throw result.errors[0];
        }

        return result.data.getMLModels || [];
    }

    async getMLModelsForProject(projectId: string): Promise<MLModel[]> {
        const result = await execQuery<MLModelsResult>({
            query: gql`
                query getMLModels($projectId: String!) {
                    getMLModels(projectId: $projectId) {
                        id
                        name
                        projectId
                        labels
                        type
                        createDate
                        updateDate
                        device
                        description
                        revision {
                            createDate
                            storagePath
                            isActive
                            fileSizeKilobytes
                            manifestJson
                        }
                    }
                }
            `,
            variables: {
                projectId: projectId
            },
            fetchPolicy: 'network-only'
        });
        if (result.errors) {
            console.log(result.errors);
            throw result.errors[0];
        }

        return result.data.getMLModels || [];
    }

    async getMLModelTypes(): Promise<MLModelType[]> {
        const result = await execQuery<MLModelTypesResult>({
            query: gql`
                query getMLModelTypes {
                    getMLModelTypes {
                        id
                        type
                    }
                }
            `,
            fetchPolicy: 'network-only'
        });
        if (result.errors) {
            console.log(result.errors);
            throw result.errors[0];
        }

        return result.data.getMLModelTypes || [];
    }

    addMLModel(model: MLModel, revisionFilePath: string | undefined) {
        const formData = new FormData();
        formData.append('type', model.type);
        formData.append('name', model.name);
        formData.append('projectId', model.projectId);
        formData.append('labels', JSON.stringify(model.labels));
        formData.append('device', model.device);
        if (revisionFilePath) {
            // @ts-ignore
            formData.append('revisionFilePath', revisionFilePath);
        }

        const url = process.env.REACT_APP_MANAGE_URL + `projects/${model.projectId}/ml_models`;
        return appClient.post(url, formData);
    }

    updateMLModel(model: MLModel) {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${model.projectId}/ml_models/${model.id}`;
        return appClient.update(url, model);
    }

    updateRevisionState(model: MLModel, storagePath: string, isActive: boolean) {
        const request = {
            modelId: model.id!,
            storagePath,
            isActive: isActive
        };
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${model.projectId}/ml_models/${model.id}/change_state`;
        return appClient.post(url, request);
    }

    uploadRevision(formData: FormData, model: MLModel) {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${model.projectId}/ml_models/${model.id}/upload`;
        return appClient.post(url, formData);
    }

    deleteRevision(model: MLModel, storagePath: string) {
        const url =
            process.env.REACT_APP_MANAGE_URL +
            `projects/${model.projectId}/ml_models/${model.id}/${encodeURIComponent(storagePath)}`;
        return appClient.delete(url);
    }

    deleteMLModel(model: MLModel) {
        const url = process.env.REACT_APP_MANAGE_URL + `projects/${model.projectId}/ml_models/${model.id}`;
        return appClient.delete(url);
    }

    async downloadRevision(model: MLModel, storagePath: string) {
        security.invoke(token => {
            let t = '?access_token=' + encodeURIComponent(token);
            const url =
                process.env.REACT_APP_MANAGE_URL +
                `projects/${model.projectId}/ml_models/${model.id}/${encodeURIComponent(storagePath)}${t}`;
            window.open(url);
            return Promise.resolve();
        });
    }

    async importMlModel(data: FormData, projectId: string) {
        let url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/ml_models/import`;
        const resp = await appClient.post<unknown | undefined>(url, data);
        return resp;
    }

    async exportMlModel(projectId: string, modelId: string) {
        const mapForm = document.createElement('form');
        mapForm.setAttribute('id', 'mlModelPostForm');
        mapForm.target = '_blank';
        mapForm.method = 'POST';

        await security.invoke(token => {
            let t = '?access_token=' + encodeURIComponent(token);
            const url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/ml_models/${modelId}/export${t}`;
            Utils.downloadFile(url, mapForm, 'mlModelPostForm');
            return Promise.resolve();
        });
    }

    async getModelRules(projectId: string, modelId: string) {
        let url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/ml_models/${modelId}/rules`;
        const resp = await appClient.get<MLModelRule[] | undefined>(url);
        return resp;
    }

    async updateManifest(projectId: string, modelId: string, manifest: string) {
        let url = process.env.REACT_APP_MANAGE_URL + `projects/${projectId}/ml_models/${modelId}/manifest`;

        const request = {
            manifest: manifest
        };

        const resp = await appClient.post<unknown | undefined>(url, request);
        return resp;
    }

    getAuthToken() {
        return security.invoke(token => {
            return Promise.resolve(token);
        });
    }
}

/* eslint-disable @typescript-eslint/member-ordering */
import { observable, computed, action } from 'mobx';
import PackageLine from './PackageLine';
import ChangedPackageLine from './ChangedPackageLine';
import Project from './Project';
import PackageState from './PackageState';
import PackageLineState from './PackageLineState';
import { PackageSource } from './PackageSource';
import FeatureFlags from './FeatureFlags';

export default class Package {
    @observable
    id: string;

    @observable
    name: string;

    @observable
    lines: PackageLine[] = [];

    size: number;

    operationState: string[];

    uploadDate: Date | null;

    @observable
    indexDate: Date | null;

    sortingDate: Date;

    @observable
    initialState: PackageState = PackageState.Ready;

    @observable
    serverState: PackageState = PackageState.Ready;

    @observable
    userTags: string[] = [];

    @observable
    featureFlags: FeatureFlags = new FeatureFlags();

    @observable
    isProtected: boolean = false;

    contentType: string;

    source: PackageSource | null;

    @computed
    get changedLines() {
        return this.lines.filter(l => l.state !== PackageLineState.Unmodified).map(l => new ChangedPackageLine(l));
    }

    @computed
    get state() {
        const changedLines = this.lines.filter(l => l.state !== PackageLineState.Unmodified);

        if (changedLines.find(x => x.state === PackageLineState.ChangedLabel)) {
            return PackageState.ChangedLabels;
        }

        return this.initialState;
    }

    constructor(
        public project: Project,
        id: string,
        name: string,
        contentType: string = 'application/pdf',
        state: PackageState = PackageState.Ready,
        public readonly documentPath: string | null = null,
        operationState: string[] = [],
        tags: string[] = [],
        source: PackageSource | null = null,
        uploadDate: Date | null = null,
        indexDate: Date | null = null,
        public error: string | null = null,
        featureFlags: FeatureFlags = new FeatureFlags(),
        isProtected = false
    ) {
        this.id = id;
        this.name = name;
        this.initialState = state;
        this.operationState = operationState;
        this.serverState = state;
        this.userTags = tags;
        this.uploadDate = uploadDate;
        this.indexDate = indexDate;
        this.contentType = contentType;
        this.source = source;
        this.sortingDate = this.indexDate ? this.indexDate : this.uploadDate!;
        this.featureFlags = featureFlags;
        this.isProtected = isProtected;
    }

    @action
    updatePackageStateResult(state: PackageState) {
        this.serverState = state;
    }

    @action
    updatePackageProtection(isProtected: boolean) {
        this.isProtected = isProtected;
    }

    @action
    updateIndexDate(indexDate: Date) {
        this.indexDate = indexDate;
    }

    @action
    updateFeatureFlags(featureFlags: FeatureFlags | null) {
        this.featureFlags = featureFlags ?? new FeatureFlags();
    }

    @action
    resetAllChanges() {
        this.changedLines.forEach(l => l.line.reset());
    }

    @action
    setUserTags(tags: string[]) {
        this.userTags = tags;
    }
}

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Collapse, Checkbox, Form, Spin } from 'antd';
import { DefaultWorkflowParameter } from '../../../instruct_workflows/types';
import WorkflowParametersList from './WorkflowParametersList';
import './WorkflowParametersRenderer.less';

interface Props {
    loading: boolean;
    fieldClassName?: string;
    hideFieldTooltip?: boolean;
    overridenWorkflowParametersName: string;
    overrideWorkflowParameterName: string;
    defaultWorkflowParameters: DefaultWorkflowParameter[];
}

const WorkflowParametersRenderer: React.FC<Props> = ({
    loading,
    fieldClassName,
    hideFieldTooltip,
    overridenWorkflowParametersName,
    overrideWorkflowParameterName,
    defaultWorkflowParameters
}: Props) => {
    const [openPanelKeys, setOpenPanelKeys] = React.useState<string[]>([]);

    const renderOverrideParametersCheckbox = () => (
        <div onClick={e => e.stopPropagation()}>
            <Form.Item name={overrideWorkflowParameterName} valuePropName="checked">
                <Checkbox style={{ marginLeft: 12 }} />
            </Form.Item>
        </div>
    );

    return (
        <div className="workflow-parameters-editor-container">
            <Collapse
                activeKey={openPanelKeys}
                onChange={key => setOpenPanelKeys(key as string[])}
                style={{ marginBottom: 18 }}
            >
                <Collapse.Panel
                    key="1"
                    header="Workflow parameters"
                    extra={renderOverrideParametersCheckbox()}
                    forceRender
                >
                    {loading ? (
                        <div className="loader-container">
                            <Spin size="small" />
                        </div>
                    ) : (
                        <WorkflowParametersList
                            fieldClassName={fieldClassName}
                            hideFieldTooltip={hideFieldTooltip}
                            overridenWorkflowParametersName={overridenWorkflowParametersName}
                            overrideWorkflowParameterName={overrideWorkflowParameterName}
                            defaultWorkflowParameters={defaultWorkflowParameters}
                        />
                    )}
                </Collapse.Panel>
            </Collapse>
        </div>
    );
};

export default observer(WorkflowParametersRenderer);

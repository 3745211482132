/* eslint-disable max-len */
import { Layout } from 'antd';
import _ from 'lodash';
import { IReactionDisposer, reaction } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { TreeItem } from 'react-sortable-tree-patch-react-17';
import SplitterLayout from 'react-splitter-layout';
import { Subscription } from 'rxjs';
import { Project } from '../../../common/models';
import { addNewLinesInCopyBuffer, removeAddNewLinesInCopyBuffer } from '../../../common/Utils';
import { STORE_RULES_PREVIEW } from '../../constants';
import { RulePreviewVisualStore } from '../../stores';
import { PreviewIndexToken } from '../../types';
import { createTreeItem } from './TreeBuilder';
import TreeViewContainer from './TreeViewContainer';
import PackagePreviewRunControls from './PackagePreviewRunControls';
import ResultsPreview from './ResultsPreview';
import RowPreviewModal from './RowPreviewModal';
import './RulePreviewForm.less';

type Props = { [STORE_RULES_PREVIEW]: RulePreviewVisualStore } & {
    style?: React.CSSProperties;
    project?: Project | null;
    renderTreeViewOnly?: boolean;
};

class RulePreviewForm extends React.Component<
    Props,
    {
        selectedRowData: { rowId: number | undefined; pkgId: string | undefined };
        selectedRowContent: string | JSX.Element | undefined;
        showRowPreviewDialog: boolean;
        treeData: TreeItem[];
        expandedNodes: string[];
        collapsedNodes: string[];
        searchFocusIndex: number | undefined;
        searchFoundCount: number;
        dialogWidth: string | number;
        selectedRowType: string;
        reactions: IReactionDisposer[];
    }
> {
    state = {
        selectedRowData: { rowId: undefined, pkgId: undefined },
        selectedRowContent: undefined,
        showRowPreviewDialog: false,
        treeData: [] as TreeItem[],
        expandedNodes: [],
        collapsedNodes: [],
        searchFocusIndex: undefined,
        searchFoundCount: 0,
        dialogWidth: 768,
        selectedRowType: '',
        reactions: [] as IReactionDisposer[]
    };

    resizeHandler: () => void;

    highlightBlockSub: Subscription;

    constructor(props: Readonly<Props>) {
        super(props);
        this.resizeHandler = _.debounce(this.handleResize.bind(this), 100);
        this.checkIfNodeIsExpanded = this.checkIfNodeIsExpanded.bind(this);
        this.searchCallback = this.searchCallback.bind(this);
        this.onSplitterDragEnd = this.onSplitterDragEnd.bind(this);
        this.onPageWithBlockLoadHandler = this.onPageWithBlockLoadHandler.bind(this);
        this.handleFirstPaneResize = this.handleFirstPaneResize.bind(this);
        this.handlesecondPaneResize = this.handlesecondPaneResize.bind(this);
        this.setTreeData = this.setTreeData.bind(this);
        this.checkIfNodeIsCollapsed = this.checkIfNodeIsCollapsed.bind(this);
        this.findRootNode = this.findRootNode.bind(this);
        this.setExpandedCollapsedNodes = this.setExpandedCollapsedNodes.bind(this);
        this.highlightBlock = this.highlightBlock.bind(this);
        this.openRowPreviewDialog = this.openRowPreviewDialog.bind(this);
    }

    componentDidMount() {
        addNewLinesInCopyBuffer();
        window.addEventListener('resize', this.resizeHandler);
        const store = this.props.RulePreviewUI!;
        this.highlightBlockSub = store.highlightBlockRedrawSubject.subscribe(this.onPageWithBlockLoadHandler);

        const previewResultsReaction = reaction(
            () => store.filteredPreviewResults,
            filteredPreviewResults => {
                if (!filteredPreviewResults || !filteredPreviewResults.length) {
                    return this.setTreeData([]);
                }

                const treeData = store.filteredPreviewResults.map(result =>
                    createTreeItem(
                        result,
                        store,
                        // TODO: refactor to use only store
                        this.props.project,
                        this.state.selectedRowData,
                        this.checkIfNodeIsCollapsed,
                        this.checkIfNodeIsExpanded,
                        this.openRowPreviewDialog
                    )
                );

                this.setTreeData(treeData);
            }
        );

        this.setState({ reactions: [previewResultsReaction] });
    }

    componentWillUnmount() {
        this.state.reactions.forEach(disposer => disposer());

        this.setState({
            treeData: [],
            expandedNodes: [],
            collapsedNodes: [],
            showRowPreviewDialog: false,
            selectedRowContent: undefined,
            selectedRowData: { rowId: undefined, pkgId: undefined }
        });
        const store = this.props.RulePreviewUI!;
        store.reset();
        removeAddNewLinesInCopyBuffer();
        window.removeEventListener('resize', this.resizeHandler);
        if (this.highlightBlockSub) {
            this.highlightBlockSub.unsubscribe();
        }
    }

    openRowPreviewDialog(content: string, resultTokens: PreviewIndexToken[]) {
        const type = resultTokens.length > 0 ? resultTokens[resultTokens.length - 1].token.type : 'Text';
        const dialogWidth: string | number = type === 'Table' ? '90%' : 768;
        this.setState({
            showRowPreviewDialog: true,
            selectedRowContent: content,
            dialogWidth,
            selectedRowType: type
        });
    }

    handleResize() {
        if (this.state.selectedRowData.pkgId && this.state.selectedRowData.rowId) {
            const store = this.props.RulePreviewUI!;

            reaction(
                () => store.pageCoordinates,
                (pc, r) => {
                    if (
                        pc &&
                        pc.length > 0 &&
                        (!store.selectedPackage || this.state.selectedRowData.pkgId === store.selectedPackage?.id)
                    ) {
                        this.highlightBlock(
                            this.state.selectedRowData.rowId!,
                            this.state.selectedRowData.pkgId!,
                            true,
                            false
                        );
                        r.dispose();
                    }
                }
            );
        }
    }

    checkIfNodeIsExpanded(nodeId: string) {
        return !!this.state.expandedNodes.find(n => n === nodeId);
    }

    checkIfNodeIsCollapsed(nodeId: string) {
        return !!this.state.collapsedNodes.find(n => n === nodeId);
    }

    highlightBlock(rowId: number, pkgId: string, selected: boolean | undefined, scrollToPos: boolean = true) {
        const store = this.props.RulePreviewUI!;
        store.highlightBlock(rowId, pkgId, selected, scrollToPos);
        this.setState({
            selectedRowData: selected ? { rowId: rowId, pkgId: pkgId } : { rowId: undefined, pkgId: undefined }
        });
    }

    searchCallback(matches: object[]) {
        this.setState({
            searchFoundCount: matches.length,
            searchFocusIndex:
                matches.length > 0 && this.state.searchFocusIndex !== null
                    ? this.state.searchFocusIndex! % matches.length
                    : 0
        });
    }

    onSplitterDragEnd(val?: number) {
        this.resizeHandler();
        window.dispatchEvent(new Event('resize'));
        if (val) {
            this.handlesecondPaneResize(val!);
        }
    }

    onPageWithBlockLoadHandler() {
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
            this.resizeHandler();
        }, 500);
    }

    handleFirstPaneResize(val: number) {
        sessionStorage.setItem('firstPanelWidth', val.toString());
    }

    handlesecondPaneResize(val: number) {
        sessionStorage.setItem('secondPanelWidth', val.toString());
    }

    setTreeData(data: TreeItem[]) {
        this.setState({ treeData: data });
    }

    setExpandedCollapsedNodes(expandedNodes: string[], collapsedNodes: string[]) {
        this.setState({ expandedNodes: expandedNodes, collapsedNodes: collapsedNodes });
    }

    findRootNode(dataId: string): TreeItem | undefined {
        return this.state.treeData.find(d => d.title?.props['data-id'] === dataId);
    }

    render() {
        const store = this.props.RulePreviewUI!;

        // TODO: refactor and make TreeViewContainer a separate reusable component
        if (this.props.renderTreeViewOnly) {
            return (
                <TreeViewContainer
                    store={store}
                    treeData={this.state.treeData}
                    selectedRowContent={this.state.selectedRowContent}
                    selectedRowType={this.state.selectedRowType}
                    searchFocusIndex={this.state.searchFocusIndex}
                    collapsedNodes={this.state.collapsedNodes}
                    expandedNodes={this.state.expandedNodes}
                    searchCallback={this.searchCallback}
                    setTreeData={this.setTreeData}
                    highlightBlock={this.highlightBlock}
                    setExpandedCollapsedNodes={this.setExpandedCollapsedNodes}
                    findRootNode={this.findRootNode}
                />
            );
        }

        return (
            <Layout className="screen-size rule-preview" style={{ ...{ height: '100%', ...this.props.style } }}>
                <SplitterLayout
                    percentage
                    secondaryInitialSize={Number(sessionStorage.getItem('secondPanelWidth')) || 25}
                    onDragEnd={this.onSplitterDragEnd}
                >
                    <SplitterLayout
                        percentage
                        primaryIndex={1}
                        secondaryInitialSize={Number(sessionStorage.getItem('firstPanelWidth')) || 25}
                        onSecondaryPaneSizeChange={this.handleFirstPaneResize}
                        onDragEnd={this.onSplitterDragEnd}
                    >
                        <PackagePreviewRunControls store={store} />
                        <TreeViewContainer
                            store={store}
                            treeData={this.state.treeData}
                            selectedRowContent={this.state.selectedRowContent}
                            selectedRowType={this.state.selectedRowType}
                            searchFocusIndex={this.state.searchFocusIndex}
                            collapsedNodes={this.state.collapsedNodes}
                            expandedNodes={this.state.expandedNodes}
                            searchCallback={this.searchCallback}
                            setTreeData={this.setTreeData}
                            highlightBlock={this.highlightBlock}
                            setExpandedCollapsedNodes={this.setExpandedCollapsedNodes}
                            findRootNode={this.findRootNode}
                        />
                    </SplitterLayout>
                    <ResultsPreview store={store} />
                </SplitterLayout>
                <RowPreviewModal
                    isVisible={this.state.showRowPreviewDialog}
                    content={this.state.selectedRowContent}
                    type={this.state.selectedRowType}
                    dialogWidth={this.state.dialogWidth}
                    onClose={() => this.setState({ showRowPreviewDialog: false })}
                />
            </Layout>
        );
    }
}

export default observer(RulePreviewForm);

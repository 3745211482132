/* eslint-disable @typescript-eslint/member-ordering */
import { action, computed, observable, runInAction } from 'mobx';
import { GlobalAdministrationService } from '../service/GlobalAdministrationService';
import { ErrorStore } from '../../../modules/common/stores';
import type { UserModel } from '../types/UserModel';
import { RoleModel } from '../types/RoleModel';
import { message } from 'antd';
import { UserProfileStore } from '../../../modules/common/stores';
import { SearchUsersAutoCompleteSourceItem } from '../../common/types';
import security from '../../common/services/SecurityService';
import { UsersGroup } from '../types/GroupModel';
import permissions from '../../authorization/Permissions';
import { CustomRole } from '../types';
import { ResponseData } from '../../common/services/AppClient';

export enum LoginTypes {
    internalUser = 'Internal User',
    domainUser = 'Domain User'
}

type Group = {
    users: UserModel[];
    usersGroup: UsersGroup;
};

export default class UsersManagerStore {
    usersPageSize: number = 20;

    @observable
    usersTotalCount: number = 0;

    @observable
    usersCurrentPage = 1;

    @observable
    isLoading: boolean = false;

    @observable
    showLoadMoreFooter: boolean = true;

    @observable
    users: UserModel[] = [];

    @observable
    selectedUser: UserModel | null;

    @observable
    selectedProjectId: string | null;

    @observable
    roles: RoleModel[];

    @observable
    isAddUserDialogVisible: boolean;

    @observable
    isEditUserDialogVisible: boolean;

    @observable
    resetPasswordDialogVisible: boolean;

    @observable
    searchTerm: string = '';

    @observable
    autocompleteSource: SearchUsersAutoCompleteSourceItem[] = [];

    userProfileStore: UserProfileStore;

    @observable
    currentUserId: string;

    @observable
    userGroups: Group[];

    @observable
    userSearchString: string = '';

    @observable
    tableIsLoading: boolean = false;

    @observable
    isUserRoleEditDialogVisible: boolean = false;

    @observable
    customRoles: CustomRole[] = [];

    @observable
    loadingCustomRoles: boolean = false;

    @observable
    customRoleDialogVisible: boolean = false;

    @observable
    customRoleDialogModel: CustomRole | undefined = undefined;

    @observable
    isLoadingUsersCount: boolean = false;

    private searchForAutocompleteCallServer: (s: string) => Promise<void>;

    @computed
    get canDeleteUser() {
        return (
            !!this.selectedUser && this.selectedUser.userName !== 'admin' && this.selectedUser.id !== this.currentUserId
        );
    }

    @computed
    get userGroupSelectValues() {
        const emptyGroup: Group = { users: [], usersGroup: { id: '', name: 'Ungrouped users', path: '' } };
        return this.userGroups ? [emptyGroup, ...this.userGroups] : [emptyGroup];
    }

    @computed
    get filteredUsers() {
        if (this.userSearchString.trim() !== '') {
            const searchStringLower = this.userSearchString.toLowerCase();
            return this.users.filter(
                u =>
                    (u.firstName && u.firstName.toLowerCase().includes(searchStringLower)) ||
                    (u.lastName && u.lastName.toLowerCase().includes(searchStringLower)) ||
                    (u.email && u.email.toLowerCase().includes(searchStringLower)) ||
                    (u.roles &&
                        u.roles
                            .map(r => r.name)
                            .join(', ')
                            .toLowerCase()
                            .includes(searchStringLower)) ||
                    (u.groups &&
                        u.groups
                            .map(g => this.getGroupNameById(g))
                            .join(', ')
                            .toLowerCase()
                            .includes(searchStringLower))
            );
        }

        return this.users;
    }

    @computed
    get sortedRoles() {
        const sortedRoleNames =
            this.roles && Object.keys(permissions).filter(y => this!.roles.find(x => x.name === y)!);
        return sortedRoleNames ? sortedRoleNames.map(x => this.roles.find(y => y.name === x)!) : [];
    }

    constructor(
        private service: GlobalAdministrationService,
        private errorStore: ErrorStore
    ) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        security.inst.loadUserInfo().then((r: any) => {
            this.currentUserId = r.sub;
        });
    }

    @action.bound
    setUsersCurrentPage(page: number) {
        this.usersCurrentPage = page;
    }

    @action.bound
    setUsersTotalCount(count: number) {
        this.usersTotalCount = count;
    }

    @action.bound
    setUserSearchString(searchString: string) {
        this.userSearchString = searchString;
    }

    @action.bound
    async performSearch(searchString: string) {
        this.userSearchString = searchString;
        this.users = [];
        this.setUsersCurrentPage(1);
        this.getUsersCount();
        await this.getUsers();
    }

    @action.bound
    setCustomRoleDialogVisible(visible: boolean) {
        this.customRoleDialogVisible = visible;
    }

    @action.bound
    setCustomRoleDialogModel(model: CustomRole | undefined) {
        this.customRoleDialogModel = model;
    }

    @action.bound
    setSelectedProjectId(selectedProjectId: string | null) {
        this.selectedProjectId = selectedProjectId;
    }

    @action.bound
    setIsUserRoleEditDialogVisible(isVisible: boolean) {
        this.isUserRoleEditDialogVisible = isVisible;

        if (!isVisible) {
            this.setSelectedProjectId(null);
        }
    }

    @action.bound
    setLoadingCustomRoles(loading: boolean) {
        this.loadingCustomRoles = loading;
    }

    @action.bound
    async getCustomRoles() {
        try {
            this.setLoadingCustomRoles(true);
            var roles = await this.service.getCustomRoles();
            runInAction(() => {
                this.customRoles = roles;
            });
        } catch (ex) {
            console.error(ex);
        } finally {
            this.setLoadingCustomRoles(false);
        }
    }

    @action.bound
    setIsLoadingUsersCount(isLoading: boolean) {
        this.isLoadingUsersCount = isLoading;
    }

    @action.bound
    async getUsersCount() {
        try {
            this.setIsLoadingUsersCount(true);
            this.setUsersTotalCount(await this.service.getUserCount(this.userSearchString));
        } catch (err) {
            console.error(err);
        } finally {
            this.setIsLoadingUsersCount(false);
        }
    }

    @action.bound
    async saveCustomRole(name: string, rolePermissions: string[], id?: string) {
        try {
            let resp: ResponseData;
            if (id) {
                resp = await this.service.updateCustomRole(id, name, rolePermissions);
            } else {
                resp = await this.service.createCustomRole(name, rolePermissions);
            }

            if (resp.status === 200) {
                this.setCustomRoleDialogVisible(false);
                this.setCustomRoleDialogModel(undefined);
                const msg = `Role ${id ? 'updated' : 'created'} successfully`;
                message.success(msg);
                await this.getCustomRoles();
            } else {
                console.log(resp.status, resp.statusText, resp.data);
                const msg = `Error during role ${id ? 'update' : 'creation'}`;
                message.error(msg);
            }
        } catch (ex) {
            console.error(ex);
        }
    }

    @action.bound
    async deleteCustomRole(roleId: string) {
        try {
            const resp = await this.service.deleteCustomRole(roleId);

            if (resp.status === 200) {
                runInAction(() => {
                    this.customRoles = this.customRoles.filter(r => r.id !== roleId);
                });
                const msg = 'Role deleted successfully';
                message.success(msg);
            } else {
                console.log(resp.status, resp.statusText, resp.data);
                const msg = 'Error during role deletion';
                message.error(msg);
            }
        } catch (ex) {
            console.error(ex);
        }
    }

    @action.bound
    async handlePageChange(page: number) {
        this.setUsersCurrentPage(page);
        this.users = [];
        await this.getUsers();
    }

    @action
    async getUsers() {
        this.isLoading = true;
        const offset = this.usersPageSize * (this.usersCurrentPage - 1);
        const users = await this.service.getAppUsersPage(offset, this.usersPageSize, this.userSearchString);
        this.users = users;
        if (users.length > 0) {
            this.selectedUser = users[0];
        }

        this.showLoadMoreFooter = users.length === this.usersPageSize;

        this.isLoading = false;
    }

    @action.bound
    async getGroups() {
        this.userGroups = await this.service.getGroups();
    }

    @action.bound
    getGroupNameById(id: string) {
        return this.userGroups?.find(g => g.usersGroup.id === id)?.usersGroup.name || '';
    }

    @action
    async init() {
        this.isLoading = true;
        this.getRoles();
        this.getGroups();
        this.isLoading = false;
    }

    @action
    async getRoles() {
        const roles = await this.service.getRoles();
        this.roles = roles;
    }

    @action
    setIsAddUserDialogVisible(isVisible: boolean) {
        this.isAddUserDialogVisible = isVisible;
    }

    @action
    setIsEditUserDialogVisible(isVisible: boolean) {
        this.isEditUserDialogVisible = isVisible;
    }

    @action
    setResetPasswordDialogVisible(isVisible: boolean) {
        this.resetPasswordDialogVisible = isVisible;
    }

    @action.bound
    async handleNodeSelection(selectedKey: string | null) {
        if (!selectedKey) {
            return;
        }
        this.selectedUser = this.users.find(u => u.id === selectedKey)!;
    }

    @action.bound
    searchForAutocomplete(term: string) {
        this.searchTerm = term;
        if (this.searchTerm) {
            return this.searchForAutocompleteCallServer(this.searchTerm);
        }
        return;
    }

    @action.bound
    async handleUserSelect(userId: string) {
        console.log(userId);
    }

    @action
    async createUpdateUser(userData: UserModel, isUpdate?: boolean) {
        if (isUpdate) {
            this.tableIsLoading = true;
            userData.id = this.selectedUser!.id!;
        }
        let resp = null;
        if (userData.isDomainUser) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            resp = await this.service.updateDomainUser(userData.userName, userData.roles as any, userData.group);
        } else {
            resp = await this.service.createUpdateUser(userData);
        }
        if (resp.status === 409) {
            const res = resp.data;
            message.error(res.title);
            if (isUpdate) {
                this.setIsEditUserDialogVisible(true);
            }
            return;
        } else if (resp.status !== 200) {
            this.tableIsLoading = false;
        } else {
            const isActionSuccessfull = resp.data;
            if (!isActionSuccessfull) {
                message.error('There were problems with your request, please report to administrator');
                return;
            }
            if (isUpdate) {
                this.handlePageChange(1);
                message.success('User has been updated');
            } else {
                await this.getUsers();
                message.success('New user has been created');
            }
            this.selectedUser = userData.isDomainUser
                ? this.users.find(u => u.id === userData.userName)!
                : this.users.find(u => u.userName === userData.userName)!;
        }

        this.tableIsLoading = false;
        this.setIsAddUserDialogVisible(false);
    }

    @action.bound
    async deleteUser() {
        this.isLoading = true;
        const resp = await this.service.deleteUser(this.selectedUser!.id!, this.selectedUser!.isDomainUser);
        if (resp.status === 200) {
            const isActionSuccessfull = resp.data;
            if (!isActionSuccessfull) {
                message.error('There were problems deleting user, please report to administrator');
                return;
            }
            this.setIsEditUserDialogVisible(false);
            this.handlePageChange(this.usersCurrentPage);
            this.selectedUser = null;
            message.success('User has been deleted');
        }
        this.isLoading = false;
    }

    async resetPassword(password: string) {
        const resp = await this.service.resetPassword(password, this.selectedUser!.id!);
        if (resp.status === 200) {
            const isPasswordReset = await resp.data;
            if (isPasswordReset) {
                message.success('Password has been successfully reset');
            } else {
                message.error('There were problems resetting your password, please report to administrator');
            }
        }
        this.setResetPasswordDialogVisible(false);
    }

    @action.bound
    async changePassword(newPassword: string, oldPassword: string, userId: string | null) {
        try {
            const resp = await this.service.changePassword(newPassword, oldPassword, userId);
            if (resp.status === 200) {
                this.setResetPasswordDialogVisible(false);
                message.success('Password has been successfully reset.');
            } else if (resp.status === 401) {
                message.error('Old password is incorrect.');
            } else {
                message.error('There were problems resetting your password, please report to administrator');
            }
        } catch (err) {
            this.errorStore.addBasicError(err);
        }
    }

    @action.bound
    async updateObjectRoleAssignments(userId: string, objectId: string, objectType: string, roles: string[]) {
        try {
            const resp = await this.service.changeObjectRoleAssignments(userId, objectId, objectType, roles);
            if (resp.status === 200) {
                this.setIsUserRoleEditDialogVisible(false);
                message.success('Roles have been successfully updated.');
            } else {
                message.error('There were problems during role update, please report to administrator');
            }
        } catch (err) {
            this.errorStore.addBasicError(err);
        }
    }
}

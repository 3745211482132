import { observable, action, computed } from 'mobx';
import { message } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { ProjectsRootVisualStore } from '../../common/stores';
import { InstructWorkflowImportDto, WorkflowImportFormValues } from '../types';
import InstructWorkflowService from '../services/InstructWorkflowService';
import InstructWorkflowCreateEditStore from './InstructWorkflowCreateEditStore';

interface ImportFormValues extends WorkflowImportFormValues {}

export default class InstructWorkflowImportExportStore {
    @observable
    workflowImportData: InstructWorkflowImportDto | null = null;

    @observable
    importDialogVisible: boolean = false;

    @observable
    isCommiting: boolean = false;

    @computed
    get project() {
        return this.projectsRootStore.currentProject;
    }

    @computed
    get importAction() {
        return `${process.env.REACT_APP_MANAGE_URL}projects/${this.project?.id}/instruct-workflows/import`;
    }

    constructor(
        readonly projectsRootStore: ProjectsRootVisualStore,
        readonly instructWorkflowStore: InstructWorkflowCreateEditStore,
        readonly service: InstructWorkflowService
    ) {}

    @action.bound
    setWorkflowImportData(workflowImportData: InstructWorkflowImportDto | null) {
        this.workflowImportData = workflowImportData;
    }

    @action.bound
    setImportDialogVisible(importDialogVisible: boolean) {
        this.importDialogVisible = importDialogVisible;
    }

    @action.bound
    setIsCommiting(isCommiting: boolean) {
        this.isCommiting = isCommiting;
    }

    @action.bound
    onImportChange({ file }: UploadChangeParam) {
        const status = file.status;

        if (status === 'error') {
            message.error(`${file.name} file upload failed.`);
            return;
        }

        if (status !== 'done') {
            return;
        }

        message.success(`${file.name} file uploaded successfully.`);

        this.setWorkflowImportData(file.response);

        if (this.workflowImportData?.mapperTableData.length) {
            this.setImportDialogVisible(true);
        } else {
            this.commitImportedWorkflow();
        }
    }

    @action.bound
    async commitImportedWorkflow(formValues?: ImportFormValues) {
        if (!this.project || !this.workflowImportData) {
            return;
        }

        try {
            this.setIsCommiting(true);

            const resp = await this.service.commitImportedWorkflow(
                this.project.id,
                this.workflowImportData.filePath,
                formValues ? formValues.mlModels : null
            );

            if (!resp.isOk()) {
                message.error(`Failed to import instruct workflow. Error: ${resp.error.text}`);
                return;
            }

            message.success('Instruct workflow imported successfully');

            resp.map(workflow => this.instructWorkflowStore.addWorkflow(workflow));

            this.dispose();
        } finally {
            this.setIsCommiting(false);
        }
    }

    async exportWorkflow(workflowId: string) {
        if (this.project) {
            await this.service.exportWorkflow(this.project.id, workflowId);
        }
    }

    dispose() {
        this.setImportDialogVisible(false);
        this.setIsCommiting(false);
        this.setWorkflowImportData(null);
    }
}

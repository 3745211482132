import BaseHistoryRecordModel from '../BaseHistoryRecordModel';
import { HistoryRecordDto } from '../../services/types';

export default class TestProjectHistoryRecordModel extends BaseHistoryRecordModel {
    constructor(data: HistoryRecordDto) {
        super(data);
    }

    get objectLabel() {
        return 'Test Project';
    }
}

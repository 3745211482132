import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Divider } from 'antd';
import { WorkflowTextInputs } from '..';
import InstructWorkflowModel from '../../model/InstructWorkflowModel';
import InstructWorkflowCreateEditStore from '../../stores/InstructWorkflowCreateEditStore';
import WorkflowInput from './WorkflowInput';
import './WorkflowInputs.less';

interface Props {
    store: InstructWorkflowCreateEditStore;
    workflow: InstructWorkflowModel;
}

const WorkflowInputs: React.FC<Props> = ({ store, workflow }) => {
    if (!workflow.workflowType || !workflow.workflowType.inputs.length) {
        return null;
    }

    return (
        <div className="workflow-inputs">
            <Divider orientation="left">Inputs</Divider>

            {workflow.workflowType.registeredInputs.map(input => (
                <WorkflowInput key={input.id} store={store} workflow={workflow} input={input} />
            ))}

            <WorkflowTextInputs workflow={workflow} />
        </div>
    );
};

export default observer(WorkflowInputs);

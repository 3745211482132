import { ParameterKeyValue } from '../types';

export default class ParameterUtils {
    static isKeyValueArray(value: unknown): value is ParameterKeyValue[] {
        return Array.isArray(value) && value.every(item => item?.key !== undefined && item?.value !== undefined);
    }

    static isStringRecord(value: unknown): value is Record<string, string> {
        return (
            typeof value === 'object' &&
            value !== null &&
            !Array.isArray(value) &&
            Object.keys(value).every(key => typeof (value as Record<string, string>)[key] === 'string')
        );
    }

    static transformToObject(arr: ParameterKeyValue[] = []): Record<string, string> {
        return arr.reduce<Record<string, string>>((acc, item) => {
            acc[item.key] = item.value;
            return acc;
        }, {});
    }

    static transformToArray(obj: Record<string, string> = {}): ParameterKeyValue[] {
        return Object.entries(obj).map(([key, value]) => ({ key, value }));
    }
}

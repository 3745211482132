import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Form } from 'antd';
import { PropmtDependentPackagesStore } from '../../stores';
import './ReparseDependentPackagesButton.less';

interface Props {
    store: PropmtDependentPackagesStore;
    updatePromptTemplate: () => Promise<boolean>;
}

const ReparseDependentPackagesButton: React.FC<Props> = ({ store, updatePromptTemplate }: Props) => {
    const label = React.useMemo(() => {
        if (store.isLoading) {
            return 'Loading Dependent Documents...';
        }

        if (!store.dependentPackageCount) {
            return 'No Dependent Documents Found';
        }

        return `Reparse ${store.dependentPackageCount} Dependent ${
            store.dependentPackageCount === 1 ? 'Document' : 'Documents'
        }`;
    }, [store.isLoading, store.dependentPackageCount]);

    const handleReparse = () => {
        // We leave the page on reparse button click, store will be disposed, so we need to keep package IDs
        const dependentPackageIds = [...store.dependentPackageIds];

        updatePromptTemplate().then(templateUpdatedSuccesfully => {
            if (templateUpdatedSuccesfully) {
                setTimeout(() => {
                    store.reparseDependentPackages(dependentPackageIds);
                }, 1000);
            }
        });
    };

    React.useEffect(() => {
        if (store.selectedTemplate) {
            store.getDependentPackages();
        }

        return () => store.dispose();
    }, [store, store.selectedTemplate]);

    return (
        <Form.Item shouldUpdate noStyle>
            {({ getFieldsError }) => {
                const formHasErrors = getFieldsError().some(({ errors }) => errors.length > 0);
                const disabled = formHasErrors || store.reparseDisabled;
                return (
                    <Button
                        className={`reparse-dependent-packages-button ${disabled ? 'disabled' : 'enabled'}`}
                        key="reparse-dependent-packages"
                        data-id="button-reparse-dependent-packages"
                        size="large"
                        onClick={handleReparse}
                        disabled={disabled}
                    >
                        {label}
                    </Button>
                );
            }}
        </Form.Item>
    );
};

export default observer(ReparseDependentPackagesButton);

import BaseHistoryRecordModel from '../BaseHistoryRecordModel';
import { HistoryRecordDto } from '../../services/types';

export default class RefDataTableHistoryRecordModel extends BaseHistoryRecordModel {
    constructor(data: HistoryRecordDto) {
        super(data);
    }

    get objectLabel() {
        return 'Reference Data Table';
    }
}

import BaseHistoryRecordModel from '../BaseHistoryRecordModel';
import { HistoryRecordDto } from '../../services/types';

export default class ProjectFieldSectionHistoryRecordModel extends BaseHistoryRecordModel {
    constructor(data: HistoryRecordDto) {
        super(data);
    }

    get objectLabel() {
        return 'Project Field Section';
    }
}

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Button, Form } from 'antd';
import { UpdatePackagesFeatureFlagsStore } from '../../stores';
import {
    FeatureFlagsOverride,
    setFormFeatureFlags,
    setFormFeatureFlagsDefaults,
    transformFeatureFlagsAsRecord
} from '../../../common/misc/FeatureFlagsHelpers';
import { FeatureFlagsRenderer } from '../../../common/components';

const formId = 'update-packages-feature-flags-form';

interface Props {
    store: UpdatePackagesFeatureFlagsStore;
}

const UpdatePackagesFeatureFlagsModal: React.FC<Props> = ({ store }: Props) => {
    const [form] = Form.useForm();

    const [featureFlagsOverride, setFeatureFlagsOverride] = React.useState<FeatureFlagsOverride>({
        overrideFeatureFlags: false,
        overrideImageFeatureFlags: false,
        overrideTextFeatureFlags: false
    });

    const handleClose = () => {
        store.setModalVisible(false);
    };

    const handleSubmit = () => {
        store.updatePackagesFeatureFlags(transformFeatureFlagsAsRecord(form, featureFlagsOverride));
        handleClose();
    };

    React.useEffect(() => {
        if (store.modalVisible && store.projectFeatureFlags) {
            setFeatureFlagsOverride({
                ...setFormFeatureFlags(form, store.projectFeatureFlags)
            });
        } else {
            setFeatureFlagsOverride({
                ...setFormFeatureFlagsDefaults(form)
            });
        }
    }, [form, store.modalVisible, store.projectFeatureFlags]);

    return (
        <Modal
            className="alpha-portal-dialog"
            title="Override Feature Flags"
            visible={store.modalVisible}
            onCancel={handleClose}
            maskClosable={false}
            closable={false}
            width={600}
            destroyOnClose
            centered
            footer={[
                <Button key="close" size="large" className="light" onClick={handleClose}>
                    Cancel
                </Button>,
                <Button key="submit" size="large" type="primary" htmlType="submit" form={formId}>
                    Override
                </Button>
            ]}
        >
            <Form id={formId} form={form} onFinish={handleSubmit}>
                <FeatureFlagsRenderer
                    featureFlagsOverride={featureFlagsOverride}
                    setFeatureFlagsOverride={setFeatureFlagsOverride}
                />
            </Form>
        </Modal>
    );
};

export default observer(UpdatePackagesFeatureFlagsModal);

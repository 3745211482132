import * as React from 'react';
import { Observer, observer } from 'mobx-react-lite';
import { Layout, Table, Button, Space, Tag, Popconfirm } from 'antd';
import { EditOutlined, CopyOutlined, InboxOutlined, PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { DataCrafterTemplatesVisualStore } from '../../stores';
import { DataCrafterTemplate, DataCrafterTemplateStatus } from '../../types';
import LayoutHeader from '../../../../components/LayoutHeader';
import { Content } from 'antd/lib/layout/layout';
import { Utils } from '../../../common/services/Utils';
import './DataCrafterTemplatesList.less';
import { DuplicateTemplateDialog } from '..';
import { SortOrder } from 'antd/lib/table/interface';

type Props = {
    store: DataCrafterTemplatesVisualStore;
};

const DataCrafterTemplatesList: React.FC<Props> = ({ store }) => {
    React.useEffect(() => {
        if (store.currentProject) {
            store.loadTemplates();
        }
    }, [store, store.currentProject]);

    const getStatusColor = (status: DataCrafterTemplateStatus) => {
        switch (status) {
            case DataCrafterTemplateStatus.Active:
                return 'green';
            case DataCrafterTemplateStatus.Draft:
                return 'gold';
            case DataCrafterTemplateStatus.Archived:
                return 'default';
            default:
                return 'default';
        }
    };

    const handleStatusClick = async (template: DataCrafterTemplate) => {
        const newStatus =
            template.status === DataCrafterTemplateStatus.Draft
                ? DataCrafterTemplateStatus.Active
                : DataCrafterTemplateStatus.Draft;

        await store.changeTemplateStatus(template.id, newStatus);
    };

    const handleDuplicate = (templateId: string) => {
        store.setTemplateToDuplicate(templateId);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a: DataCrafterTemplate, b: DataCrafterTemplate) => a.name.localeCompare(b.name)
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status: DataCrafterTemplateStatus, record: DataCrafterTemplate) => {
                return (
                    <Observer>
                        {() => (
                            <Tag
                                color={getStatusColor(status)}
                                style={{
                                    cursor: store.changingStatusTemplateIds.includes(record.id)
                                        ? 'not-allowed'
                                        : 'pointer'
                                }}
                                onClick={
                                    store.changingStatusTemplateIds.includes(record.id)
                                        ? undefined
                                        : () => handleStatusClick(record)
                                }
                            >
                                {status}
                                {store.changingStatusTemplateIds.includes(record.id) && <SyncOutlined spin />}
                            </Tag>
                        )}
                    </Observer>
                );
            },
            filters: Object.values(DataCrafterTemplateStatus)
                .filter(s => s !== DataCrafterTemplateStatus.Archived)
                .map(status => ({
                    text: status,
                    value: status
                })),
            onFilter: (value: string, record: DataCrafterTemplate) => record.status === value
        },
        {
            title: 'Agent Model',
            dataIndex: 'plannerModelName',
            key: 'plannerModelName',
            render: (plannerModelName?: string) => plannerModelName || 'Not specified'
        },
        {
            title: 'Coding Model',
            dataIndex: 'coderModelName',
            key: 'coderModelName',
            render: (coderModelName?: string) => coderModelName || 'Not specified'
        },
        {
            title: 'Created By',
            dataIndex: 'createdBy',
            key: 'createdBy',
            render: (createdBy: string) => store.getUserNameById(createdBy)
        },
        {
            title: 'Last Updated',
            dataIndex: 'updatedTime',
            key: 'lastUpdated',
            defaultSortOrder: 'descend' as SortOrder,
            render: (updatedTime: string | null, record: DataCrafterTemplate) => {
                if (updatedTime) {
                    return Utils.formatDateStringShort(updatedTime);
                }
                return Utils.formatDateStringShort(record.createdTime);
            },
            sorter: (a: DataCrafterTemplate, b: DataCrafterTemplate) => {
                const aTime = a.updatedTime || a.createdTime;
                const bTime = b.updatedTime || b.createdTime;
                return Utils.safeDateStringCompare(aTime, bTime);
            }
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_: unknown, record: DataCrafterTemplate) => (
                <Space size="middle">
                    <Button
                        type="text"
                        icon={<EditOutlined />}
                        onClick={() => store.navigateToEditTemplate(record.id)}
                    />
                    <Button type="text" icon={<CopyOutlined />} onClick={() => handleDuplicate(record.id)} />
                    <Popconfirm
                        title={`Are you sure you want to archive the template "${record.name}"?`}
                        onConfirm={() => store.archiveTemplate(record.id)}
                        okText="Archive"
                        cancelText="Cancel"
                        okButtonProps={{ danger: true }}
                    >
                        <Button type="text" icon={<InboxOutlined />} danger title="Archive template" />
                    </Popconfirm>
                </Space>
            )
        }
    ];

    return (
        <Layout className="data-crafter-templates-list" style={{ height: '100%', background: 'white' }}>
            <LayoutHeader
                subtitle={Utils.getSubtitle(store.currentProject)}
                helpMessage=""
                title="Data Crafter Templates"
                buttons={[
                    <Button
                        key="create-data-crafter-template"
                        data-id="button-create-data-crafter-template"
                        type="primary"
                        icon={<PlusOutlined />}
                        size="large"
                        onClick={() => store.navigateToCreateTemplate()}
                    >
                        Create Template
                    </Button>
                ]}
            />
            <Layout>
                <Content style={{ maxHeight: 'calc(100vh - 70px)', overflowY: 'auto' }}>
                    <div className="content-wrapper">
                        <Table
                            dataSource={store.templates}
                            columns={columns}
                            loading={store.isLoadingTemplates}
                            rowKey="id"
                            pagination={false}
                        />
                    </div>
                </Content>
            </Layout>
            <DuplicateTemplateDialog store={store} />
        </Layout>
    );
};

export default observer(DataCrafterTemplatesList);

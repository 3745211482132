import * as React from 'react';
import { runInAction } from 'mobx';
import { observer, useLocalStore } from 'mobx-react-lite';
import { Form, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { FieldData } from 'rc-field-form/lib/interface';
import { Parameter } from '../../types';
import { InstructWorkflowDto, WorkflowOutputSchemeDto } from '../../../instruct_workflows/types';
import InstructWorkflowService from '../../../instruct_workflows/services/InstructWorkflowService';
import PipelineStep from '../../../pipeline_base/models/PipelineStep';
import PipelineVisualStore from '../../stores/PipelineVisualStore';
import './ParametersInstructWorkflowSelect.less';

type Props<T extends PipelineVisualStore<TPipelineStepType>, TPipelineStepType extends string> = {
    param: Parameter;
    form: FormInstance;
    toolTip: JSX.Element;
    step: PipelineStep<TPipelineStepType>;
    projectId: string;
    store: T;
    stepKey: number;
};

const ParametersInstructWorkflowSelect: <T extends PipelineVisualStore<S>, S extends string>(
    props: Props<T, S>
) => React.ReactElement<Props<T, S>> = props => {
    const { param, form, toolTip, step, store, stepKey } = props;

    const handleSubmit = async () => {
        await store.validateForm(form, step, stepKey, false);
    };

    if (!store.isFormSubmissionExists(stepKey)) {
        store.setFormSubmision(handleSubmit, stepKey);
    }

    const localState = useLocalStore(() => ({
        loading: false,
        service: new InstructWorkflowService(),
        workflows: new Array<InstructWorkflowDto>(),
        async load() {
            if (!store.currentProject) {
                return;
            }

            runInAction(() => {
                localState.loading = true;
            });

            const workflows = await this.service.getWorkflows(store.currentProject.id);

            runInAction(() => {
                localState.workflows = workflows;
                localState.loading = false;
            });
        }
    }));

    const [selectedWorkflowId, setSelectedWorkflowId] = React.useState<string>();

    const workflow = React.useMemo(
        () => localState.workflows.find(f => f.id === selectedWorkflowId),
        [localState.workflows, selectedWorkflowId]
    );

    const provideOutputSchemes = React.useCallback(
        (outputSchemes: WorkflowOutputSchemeDto[]) => {
            store.pipelineStepValuesSubject.next({ [stepKey]: { outputSchemes } });
        },
        [store, stepKey]
    );

    const handleInitialValues = React.useCallback(
        (values: Record<string, unknown>) => {
            setSelectedWorkflowId(values[param.name] as string | undefined);
        },
        [param]
    );

    const handleChangedFields = React.useCallback(
        (changedFields: FieldData[]) => {
            const field = changedFields.find(f => Array.isArray(f.name) && f.name[0] === param.name);

            if (field) {
                setSelectedWorkflowId(field.value);
            }
        },
        [param]
    );

    React.useEffect(() => {
        const subject = store.formFieldsSubject.subscribe(({ stepInternalId, initialValues, changedFields }) => {
            if (stepInternalId !== step.internalId) {
                return;
            }

            if (initialValues) {
                return handleInitialValues(initialValues);
            }

            if (changedFields) {
                return handleChangedFields(changedFields);
            }
        });

        return () => subject.unsubscribe();
    }, [store, step, handleChangedFields, handleInitialValues]);

    React.useEffect(() => {
        if (!localState.loading && workflow && workflow.outputSchemes.length) {
            provideOutputSchemes(workflow.outputSchemes);
        }
    }, [localState.loading, workflow, provideOutputSchemes]);

    React.useEffect(() => {
        localState.load();
    }, [localState]);

    return (
        <Form.Item
            label={toolTip}
            name={param.name}
            initialValue={param.defaultValue}
            rules={[
                {
                    required: !step.isDisabled && param.required,
                    message: 'Please select instruct workflow'
                }
            ]}
        >
            <Select
                className={`instruct-workflow-select ${localState.loading ? 'loading' : ''}`}
                placeholder="Select instruct workflow"
                loading={localState.loading}
                allowClear
            >
                {localState.workflows.map(f => (
                    <Select.Option key={f.id} value={f.id}>
                        {f.name}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );
};

export default observer(ParametersInstructWorkflowSelect);

import ErrorStore from './ErrorStore';
import ProjectsStore from './ProjectsStore';
import UsersStore from './UsersStore';
import UserProfileStore from './UserProfileStore';
import { ProjectsService, UsersService, UserProfileService } from '../services';
import { RouterStore } from './RouterStore';
import { createBrowserHistory } from 'history';
import ProjectsRootVisualStore from './ProjectsRootVisualStore';
import {
    STORE_PROJECTS,
    STORE_PROJECTS_ROOT_UI,
    STORE_ROUTER,
    STORE_ERRORS,
    STORE_ALPHA_CONFIG,
    STORE_USERS,
    STORE_USER_PROFILE
} from '../constants';
import { AlphaConfigVisualStore } from '../../../modules/administration/stores/AlphaConfigVisualStore';
import { GlobalAdministrationService } from '../../../modules/administration/service/GlobalAdministrationService';

export { default as ErrorStore } from './ErrorStore';
export { default as ProjectsRootVisualStore } from './ProjectsRootVisualStore';
export { default as ProjectsStore } from './ProjectsStore';
export { default as RouterStore } from './RouterStore';
export { default as UsersStore } from './UsersStore';
export { default as UserProfileStore } from './UserProfileStore';

export type RootStores = {
    [STORE_PROJECTS]: ProjectsStore;
    [STORE_PROJECTS_ROOT_UI]: ProjectsRootVisualStore;
    [STORE_ROUTER]: RouterStore;
    [STORE_ERRORS]: ErrorStore;
    [STORE_USERS]: UsersStore;
    [STORE_USER_PROFILE]: UserProfileStore;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & { [index: string]: any };

export function injectRootStores(obj: RootStores) {
    const history = createBrowserHistory({
        basename: '/'
    });

    const service = new ProjectsService();
    const routerStore = new RouterStore(history);
    const errorStore = new ErrorStore();
    const adminService = new GlobalAdministrationService();

    const userProfileStore = new UserProfileStore(routerStore, new UserProfileService(), adminService, errorStore);

    const projectsStore = new ProjectsStore(service, routerStore, errorStore, userProfileStore);
    const projectsVisualStore = new ProjectsRootVisualStore(routerStore, projectsStore, errorStore, service);
    const auditConfigVisualStore = new AlphaConfigVisualStore(adminService, obj[STORE_ERRORS]);
    const usersStore = new UsersStore(new UsersService());

    obj[STORE_USER_PROFILE] = userProfileStore;
    obj[STORE_PROJECTS] = projectsStore;
    obj[STORE_PROJECTS_ROOT_UI] = projectsVisualStore;
    obj[STORE_ROUTER] = routerStore;
    obj[STORE_ERRORS] = errorStore;
    obj[STORE_ALPHA_CONFIG] = auditConfigVisualStore;
    obj[STORE_USERS] = usersStore;

    return { history };
}

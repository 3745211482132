import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Typography, Button } from 'antd';
import ReactJson from 'react-json-view';
import PydanticSchemaGeneratorStore from '../../stores/PydanticSchemaGeneratorStore';
import './JsonSchemaViewer.less';

interface Props {
    store: PydanticSchemaGeneratorStore;
}

const JsonSchemaViewer: React.FC<Props> = ({ store }) => {
    return (
        <div className="json-schema">
            <div className="header">
                <Typography.Title level={5}>JSON Schema</Typography.Title>
                <Button onClick={() => store.copyGeneratedJsonSchema()} disabled={!store.generatedJsonSchema}>
                    Copy JSON
                </Button>
            </div>

            <div className="json-view-container">
                {store.error && <span className="error">{store.error}</span>}

                {store.generatedJsonSchema && (
                    <ReactJson
                        name={false}
                        src={store.generatedJsonSchema}
                        displayDataTypes={false}
                        enableClipboard={false}
                    />
                )}
            </div>
        </div>
    );
};

export default observer(JsonSchemaViewer);

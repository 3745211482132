import BaseHistoryRecordModel from '../BaseHistoryRecordModel';
import { HistoryRecordDto } from '../../services/types';

export default class RefDataConnHistoryRecordModel extends BaseHistoryRecordModel {
    constructor(data: HistoryRecordDto) {
        super(data);
    }

    get objectLabel() {
        return 'RD Connection';
    }
}

import * as React from 'react';
import { Tooltip, Form } from 'antd';
import { observer } from 'mobx-react-lite';
import PipelineVisualStore from '../../rules/stores/PipelineVisualStore';
import { StepModel, Parameter } from '../../rules/types';
import { Registry } from '../../rules/components/EditorsRegistry';
import PipelineStep from '../models/PipelineStep';
import { FieldData } from 'rc-field-form/lib/interface';
import { STORE_RULE_EDIT } from '../../rules/constants';
import { inject } from 'mobx-react';
import { RulesStores } from '../../rules/stores';
import { ParameterUtils } from '../../rules/misc';

type Props<T extends PipelineVisualStore<TPipelineStepType>, TPipelineStepType extends string> = RulesStores & {
    store: T;
    step: PipelineStep<TPipelineStepType>;
    stepKey: number;
    stepModel: StepModel;
    projectId: string;
};

export enum WidgetType {
    Dropdown = 'dropdown',
    Input = 'input',
    Checkbox = 'checkbox',
    InferenceModelSelect = 'inferenceModelSelect',
    LabelsSelect = 'labelsSelect',
    TextArea = 'textArea'
}

export const ParametersEditor: <T extends PipelineVisualStore<TPipelineStepType>, TPipelineStepType extends string>(
    props: Props<T, TPipelineStepType>
) => React.ReactElement<Props<T, TPipelineStepType>> = ({ step, store, stepKey, stepModel, projectId, RuleEditUI }) => {
    const [form] = Form.useForm();

    const getDefaultValues = React.useCallback(() => {
        const obj: Record<string, unknown> = {};
        stepModel.parameters.forEach(param => {
            obj[param.name] = ParameterUtils.isStringRecord(param.defaultValue)
                ? ParameterUtils.transformToArray(param.defaultValue)
                : param.defaultValue;
        });
        return obj;
    }, [stepModel]);

    const getInitialValues = React.useCallback(() => {
        const keys = step.parameterKeys;
        const obj = getDefaultValues();
        keys.forEach(key => {
            const value = step.getParameterValues(key);
            obj[key] = ParameterUtils.isStringRecord(value) ? ParameterUtils.transformToArray(value) : value;
        });
        return obj;
    }, [step, getDefaultValues]);

    React.useEffect(() => {
        const initialValues = getInitialValues();
        form.setFieldsValue(initialValues);
        store.formFieldsSubject.next({ stepInternalId: step.internalId, initialValues });
    }, [store, step, form, getInitialValues]);

    const getEditor = (param: Parameter, key: number) => {
        const Editor = Registry[param.widgetType]!;

        if (!Editor) {
            console.error(`Editor '${param.widgetType}' is not registered`);
            return null;
        }

        return (
            <Editor
                dataId={`pipeline-edit-step-${param.widgetType}-${stepKey}`}
                key={key}
                param={param}
                form={form}
                toolTip={getToolTip(param)}
                store={store}
                step={step}
                projectId={projectId}
                stepKey={stepKey}
            />
        );
    };

    const getToolTip = (param: Parameter) => (
        <div style={{ display: 'inline' }}>
            {`${param.friendlyName}  `}
            <Tooltip title={param.description}>
                <i className="alpha-icon md question-icon" style={{ verticalAlign: 'middle' }} />
            </Tooltip>
        </div>
    );

    const handleFieldsChange = (changedFields: FieldData[]) => {
        if (!changedFields.length) {
            return;
        }

        const allValues = form.getFieldsValue(true);
        const obj = Object.assign({});

        changedFields.forEach(field => {
            if (Array.isArray(field.name) && field.name.length === 1) {
                obj[field.name[0]] = field.value;
            }

            if (Array.isArray(field.name) && field.name.length === 3) {
                obj[field.name[0]] = allValues[field.name[0]];
            }
        });

        store.addParameters(obj, step, false);
        RuleEditUI?.setIsRuleEdited(true);

        if (RuleEditUI?.isPreviewPoppedOut) {
            RuleEditUI?.refreshRuleInStorage();
        }

        store.formFieldsSubject.next({ stepInternalId: step.internalId, changedFields });
    };

    return (
        <Form form={form} initialValues={getDefaultValues()} onFieldsChange={handleFieldsChange}>
            {(stepModel.parameters || []).map((param: Parameter, index: number) => {
                return getEditor(param, index);
            })}
        </Form>
    );
};

export default inject(STORE_RULE_EDIT)(observer(ParametersEditor));

import { observable, computed, action, runInAction } from 'mobx';
import { uniq } from 'lodash';
import { InstructWorkflowTypeDto, InstructWorkflowDto, DefaultWorkflowParameter } from '../types';
import { OverriddenWorkflowParameters, ParameterKeyValue } from '../../rules/types';
import InstructWorkflowService from '../services/InstructWorkflowService';

export default class InstructWorkflowParametersStore {
    @observable
    loading: boolean = false;

    @observable
    workflowTypes: InstructWorkflowTypeDto[] = [];

    @observable
    workflows: InstructWorkflowDto[] = [];

    @observable
    selectedWorkflowId: string | undefined = undefined;

    service: InstructWorkflowService = new InstructWorkflowService();

    constructor(private readonly projectId?: string) {}

    @action
    setSelectedWorkflowId(selectedWorkflowId: unknown) {
        this.selectedWorkflowId = typeof selectedWorkflowId === 'string' ? selectedWorkflowId : undefined;
    }

    @action
    async load() {
        this.loading = true;

        if (!this.projectId) {
            return;
        }

        const [workflowTypes, workflows] = await Promise.all([
            this.service.getWorkflowTypes(this.projectId),
            this.service.getWorkflows(this.projectId)
        ]);

        runInAction(() => {
            this.workflows = workflows;
            this.workflowTypes = workflowTypes;
            this.loading = false;
        });
    }

    getDefaultWorkflowParameterValue(key: string) {
        return this.defaultWorkflowParameters.find(p => p.key === key)?.value ?? '';
    }

    getMergedWorkflowParameters(overriddenWorkflowParameters: OverriddenWorkflowParameters = {}): ParameterKeyValue[] {
        const uniqueKeys = uniq([
            ...this.defaultWorkflowParameters.map(p => p.key),
            ...Object.keys(overriddenWorkflowParameters)
        ]);

        return uniqueKeys.map(key => ({
            key,
            value: overriddenWorkflowParameters[key] ?? this.getDefaultWorkflowParameterValue(key)
        }));
    }

    @computed
    get workflow() {
        return this.workflows.find(w => w.id === this.selectedWorkflowId);
    }

    @computed
    get workflowType() {
        return this.workflowTypes.find(t => t.id === this.workflow?.workflowTypeId);
    }

    @computed
    get defaultWorkflowParameters(): DefaultWorkflowParameter[] {
        const workflow = this.workflow;
        const workflowType = this.workflowType;

        if (!workflow || !workflowType) {
            return [];
        }

        return workflowType.inputs
            .filter(i => i.control === 'Text')
            .map(inputType => ({
                key: inputType.id,
                value: workflow.inputValues.find(input => input.inputId === inputType.id)?.value ?? null,
                description: inputType.description
            }));
    }
}

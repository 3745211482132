import * as React from 'react';
import { observer, useLocalStore } from 'mobx-react-lite';
import { FormInstance } from 'antd';
import { FieldData } from 'rc-field-form/lib/interface';
import { Parameter, ParameterKeyValue } from '../../types';
import { PipelineVisualStore } from '../../stores';
import { InstructWorkflowParametersStore } from '../../../instruct_workflows/stores';
import { ParameterUtils } from '../../misc';
import PipelineStep from '../../../pipeline_base/models/PipelineStep';
import WorkflowParametersRenderer from '../WorkflowParametersRenderer/WorkflowParametersRenderer';
import './OverridableWorkflowParametersEditor.less';

const instructWorkflowIdParameterName = 'instruct_workflow_id';
const overrideWorkflowParameterName = 'override_workflow_parameters';

type Props<T extends PipelineVisualStore<TPipelineStepType>, TPipelineStepType extends string> = {
    param: Parameter;
    form: FormInstance;
    toolTip: JSX.Element;
    step: PipelineStep<TPipelineStepType>;
    projectId: string;
    store: T;
    stepKey: number;
};

export const OverridableWorkflowParametersEditor: <T extends PipelineVisualStore<S>, S extends string>(
    props: Props<T, S>
) => React.ReactElement<Props<T, S>> = ({ form, param, store, step, stepKey }) => {
    const localStore = useLocalStore(() => ({
        instructWorkflowStore: new InstructWorkflowParametersStore(store.currentProject?.id),
        initialWorkflowParameters: undefined as ParameterKeyValue[] | undefined,
        setInitialWorkflowParameters: (initialWorkflowParameters: ParameterKeyValue[] | undefined) => {
            localStore.initialWorkflowParameters = initialWorkflowParameters;
        }
    }));

    const handleSubmit = async () => await store.validateForm(form, step, stepKey, false);

    if (!store.isFormSubmissionExists(stepKey)) {
        store.setFormSubmision(handleSubmit, stepKey);
    }

    const handleInitialValues = React.useCallback(
        (initialValues: Record<string, unknown>) => {
            localStore.instructWorkflowStore.setSelectedWorkflowId(initialValues[instructWorkflowIdParameterName]);

            const initialWorkflowParameters = initialValues[param.name];

            localStore.setInitialWorkflowParameters(
                ParameterUtils.isKeyValueArray(initialWorkflowParameters) ? initialWorkflowParameters : []
            );
        },
        [localStore, param.name]
    );

    const handleChangedFields = React.useCallback(
        (changedFields: FieldData[]) => {
            const workflowField = changedFields.find(
                f => Array.isArray(f.name) && f.name[0] === instructWorkflowIdParameterName
            );

            if (workflowField && workflowField.value !== localStore.instructWorkflowStore.selectedWorkflowId) {
                localStore.instructWorkflowStore.setSelectedWorkflowId(workflowField.value);

                const workflowParameters = {
                    [param.name]: localStore.instructWorkflowStore.getMergedWorkflowParameters({})
                };

                form.setFieldsValue(workflowParameters);
            }
        },
        [form, localStore, param.name]
    );

    const setInitialWorkflowParameters = React.useCallback(
        (initialWorkflowParameters: ParameterKeyValue[]) => {
            const workflowParameters = {
                [param.name]: localStore.instructWorkflowStore.getMergedWorkflowParameters(
                    ParameterUtils.transformToObject(initialWorkflowParameters)
                )
            };

            form.setFieldsValue(workflowParameters);
        },
        [form, param.name, localStore.instructWorkflowStore]
    );

    React.useEffect(() => {
        const subject = store.formFieldsSubject.subscribe(({ stepInternalId, initialValues, changedFields }) => {
            if (stepInternalId !== step.internalId) {
                return;
            }

            if (initialValues) {
                return handleInitialValues(initialValues);
            }

            if (changedFields) {
                return handleChangedFields(changedFields);
            }
        });

        return () => subject.unsubscribe();
    }, [store, step, localStore, handleChangedFields, handleInitialValues]);

    React.useEffect(() => {
        if (!localStore.instructWorkflowStore.loading && localStore.initialWorkflowParameters !== undefined) {
            setInitialWorkflowParameters(localStore.initialWorkflowParameters);
        }
    }, [localStore.initialWorkflowParameters, localStore.instructWorkflowStore.loading, setInitialWorkflowParameters]);

    React.useEffect(() => {
        localStore.instructWorkflowStore.load();
    }, [localStore.instructWorkflowStore]);

    return (
        <WorkflowParametersRenderer
            fieldClassName="compact"
            loading={localStore.instructWorkflowStore.loading}
            overridenWorkflowParametersName={param.name}
            overrideWorkflowParameterName={overrideWorkflowParameterName}
            defaultWorkflowParameters={localStore.instructWorkflowStore.defaultWorkflowParameters}
        />
    );
};

export default observer(OverridableWorkflowParametersEditor);

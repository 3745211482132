export const validateJson = (editorValue: string) => {
    if (editorValue.trim() === '') {
        return true;
    }

    // Regex to validate json schema
    return /^[\],:{}\s]*$/.test(
        editorValue
            // eslint-disable-next-line no-useless-escape
            .replace(/\\["\\\/bfnrtu]/g, '@')
            // eslint-disable-next-line no-useless-escape
            .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']')
            .replace(/(?:^|:|,)(?:\s*\[)+/g, '')
    );
};

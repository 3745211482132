import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { groupBy } from 'lodash';
import { Spin, Tabs, Select, Button, message } from 'antd';
import { CopyOutlined, FileOutlined } from '@ant-design/icons';
import { DataCrafterTemplateFormStore } from '../../stores';
import './InputPackageColumnsSelector.less';

type Props = {
    createStore: DataCrafterTemplateFormStore;
};

const InputPackageColumnsSelector: React.FC<Props> = ({ createStore }) => {
    const handleCopy = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
            message.success('Column name copied to clipboard');
        });
    };

    const tabPanes = React.useMemo(() => {
        const inputColumns = createStore.selectedInputPackageId
            ? createStore.inputColumnGroups[createStore.selectedInputPackageId]
            : undefined;

        if (!inputColumns) {
            return [];
        }

        const worksheets = groupBy(inputColumns, 'worksheetName');

        return Object.entries(worksheets).map(([worksheetName, columns]) => (
            <Tabs.TabPane
                key={worksheetName}
                tab={
                    <span className="worksheet-tab-label">
                        <FileOutlined />
                        {worksheetName}
                    </span>
                }
            >
                {columns.map(column => (
                    <div key={column.key} className="input-column-container">
                        <div className="input-column-content">
                            <span>{column.name}</span>
                            <span className="header-coordinates">({column.headerCoordinates})</span>
                            <Button
                                type="text"
                                size="small"
                                icon={<CopyOutlined />}
                                onClick={e => {
                                    e.stopPropagation();
                                    handleCopy(DataCrafterTemplateFormStore.getColumnLabelAndValue(column).value);
                                }}
                            />
                        </div>
                    </div>
                ))}
            </Tabs.TabPane>
        ));
    }, [createStore.selectedInputPackageId, createStore.inputColumnGroups]);

    const options = React.useMemo(
        () =>
            Object.entries(createStore.inputColumnGroups).map(([packageId]) => {
                const pkg = createStore.packages.find(p => p.id === packageId);
                return {
                    label: pkg?.name || packageId,
                    value: packageId
                };
            }),
        [createStore.inputColumnGroups, createStore.packages]
    );

    return (
        <div className="input-package-columns-selector">
            <div className="input-package-columns-container">
                <span className="input-package-columns-label">Input Package Columns</span>
                <Select
                    value={createStore.selectedInputPackageId}
                    onChange={createStore.setSelectedInputPackageId}
                    disabled={createStore.isLoadingInputColumns || !options.length}
                    options={options}
                    showSearch
                    filterOption={(input, option) =>
                        (option?.label?.toString() || '').toLowerCase().includes(input.toLowerCase())
                    }
                />
            </div>

            <Spin spinning={createStore.isLoadingInputColumns} style={{ marginTop: 16 }}>
                {tabPanes.length > 0 && <Tabs className="input-columns">{tabPanes}</Tabs>}
            </Spin>
        </div>
    );
};

export default observer(InputPackageColumnsSelector);

import * as React from 'react';
import { inject, observer } from 'mobx-react';
import CreatePromptTemplate from '../components/CreatePromptTemplate/CreatePromptTemplate';
import { PromptTemplateStores } from '../stores';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import { STORE_PROMPT_TEMPLATES } from '../stores/constants';

const CreatePromptTemplateScreen: React.FC<PromptTemplateStores> = ({ promptTemplatesUI: store }) => {
    return (
        <HasPermission permissionClaim={AppPermissions.CanManagePromptTemplates}>
            <CreatePromptTemplate store={store!} />
        </HasPermission>
    );
};

export default inject(STORE_PROMPT_TEMPLATES)(observer(CreatePromptTemplateScreen));

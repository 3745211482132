import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { HasPermission } from '../../authorization/components/HasPermission';
import { AppPermissions } from '../../authorization/Permissions';
import { PromptTemplateStores } from '../stores';
import { STORE_PROMPT_TEMPLATES, STORE_PROMPT_TEMPLATES_IMPORT_EXPORT } from '../stores/constants';
import PromptTemplateList from '../components/PromptTemplateList';

const PromptTemplatesPage: React.FC<PromptTemplateStores> = ({
    promptTemplatesUI: store,
    promptTemplatesImportExportUI: importExportStore
}) => {
    if (!store || !importExportStore) {
        return null;
    }
    return (
        <HasPermission permissionClaim={AppPermissions.CanManagePromptTemplates}>
            <PromptTemplateList store={store} importExportStore={importExportStore} />
        </HasPermission>
    );
};

export default inject(STORE_PROMPT_TEMPLATES, STORE_PROMPT_TEMPLATES_IMPORT_EXPORT)(observer(PromptTemplatesPage));

import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Button } from 'antd';
import ReactJson from 'react-json-view';

type Props = {
    modelName: string | undefined;
    manifest: string | undefined;
    isVisible: boolean;
    handleClose: () => void;
    handleEditManifest: (updatedManifest: string) => void;
    isSubmittingManifest: boolean;
};

const PreviewManifestDialog: React.FC<Props> = ({
    modelName,
    manifest,
    isVisible,
    handleClose,
    handleEditManifest,
    isSubmittingManifest
}) => {
    // State to track the current manifest JSON
    const [currentManifest, setCurrentManifest] = React.useState<any>(null);

    // Parse manifest when it changes
    React.useEffect(() => {
        if (manifest) {
            try {
                setCurrentManifest(JSON.parse(manifest));
            } catch {
                setCurrentManifest({ error: 'Invalid JSON' });
            }
        }
    }, [manifest]);

    if (manifest == null) {
        return null;
    }

    const handleEdit = ({ updated_src, existing_src }: { updated_src: unknown; existing_src: unknown }) => {
        const parseNumbersForChanges = (updated: unknown, original: unknown): unknown => {
            if (typeof updated === 'string' && !isNaN(Number(updated)) && typeof original !== 'string') {
                return Number(updated);
            }
            if (updated && typeof updated === 'object' && original && typeof original === 'object') {
                for (const key in updated) {
                    updated[key] = parseNumbersForChanges(updated[key], original[key]);
                }
            }
            return updated;
        };

        const parsedManifest = parseNumbersForChanges(updated_src, existing_src);
        setCurrentManifest(parsedManifest);
        return true;
    };

    // Handle adding new nodes
    const handleAdd = ({ updated_src }: { updated_src: any }) => {
        setCurrentManifest(updated_src);
        return true; // Return true to accept the addition
    };

    // Handle deleting nodes
    const handleDelete = ({ updated_src }: { updated_src: any }) => {
        setCurrentManifest(updated_src);
        return true; // Return true to accept the deletion
    };

    const handleSubmit = () => {
        if (currentManifest) {
            handleEditManifest(JSON.stringify(currentManifest));
        }
    };

    return (
        <Modal
            title={`Manifest of ${modelName}`}
            visible={isVisible}
            className="alpha-portal-dialog two-columns"
            destroyOnClose
            width={800}
            centered
            onCancel={handleClose}
            closable={false}
            maskClosable={false}
            footer={[
                <Button
                    data-id="manifest-preview-dialog-cancel"
                    key="back"
                    onClick={handleClose}
                    size="large"
                    className="light"
                    disabled={isSubmittingManifest}
                >
                    Cancel
                </Button>,
                <Button
                    data-id="manifest-preview-dialog-submit"
                    key="submit"
                    type="primary"
                    size="large"
                    onClick={handleSubmit}
                    loading={isSubmittingManifest}
                    disabled={isSubmittingManifest}
                >
                    Submit
                </Button>
            ]}
        >
            <ReactJson
                src={currentManifest}
                displayDataTypes={false}
                name={false}
                enableClipboard={false}
                onEdit={handleEdit}
                onAdd={handleAdd}
                onDelete={handleDelete}
            />
        </Modal>
    );
};

export default observer(PreviewManifestDialog);

import { observable, action, computed } from 'mobx';
import { message } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { ProjectsRootVisualStore } from '../../common/stores';
import PromptTemplateService from '../services/PromptTemplatesService';
import PromptTemplateStore from './PromptTemplatesStore';

export default class PromptTemplateImportExportStore {
    @observable
    importDialogVisible: boolean = false;

    @computed
    get project() {
        return this.projectsRootStore.currentProject;
    }

    @computed
    get importAction() {
        return `${process.env.REACT_APP_MANAGE_URL}projects/${this.project?.id}/prompt-templates/import`;
    }

    constructor(
        readonly projectsRootStore: ProjectsRootVisualStore,
        readonly promptTemplateStore: PromptTemplateStore,
        readonly service: PromptTemplateService
    ) {}

    @action.bound
    setImportDialogVisible(importDialogVisible: boolean) {
        this.importDialogVisible = importDialogVisible;
    }

    @action.bound
    onImportChange({ file }: UploadChangeParam) {
        const status = file.status;

        if (status === 'error') {
            message.error(`${file.name} file upload failed.`);
            return;
        }

        if (status !== 'done') {
            return;
        }

        message.success(`${file.name} file uploaded successfully.`);
        this.promptTemplateStore.loadPromptTemplates();
    }

    async exportTemplate(templateId: string) {
        if (this.project) {
            await this.service.exportPromptTemplate(this.project.id, templateId);
        }
    }
}

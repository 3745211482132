/* eslint-disable @typescript-eslint/member-ordering */
import security from '../services/SecurityService';
import { RouterStore, ErrorStore } from '.';
import { action, observable, computed } from 'mobx';
import UserProfileService from '../services/UserProfileService';
import UserProfileModel from '../models/UserProfileModel';
import { message } from 'antd';
import { GlobalAdministrationService } from '../../administration/service/GlobalAdministrationService';
import { ProjectPagesNavigation } from '../../project_management/routes';
import UserService from '../services/UserInfoService';
import type { UserInfo } from '../types/UserInfo';
export default class UserProfileStore {
    @observable
    editUserProfileVisible: boolean = false;

    @observable
    resetPasswordDialogVisible: boolean = false;

    @observable
    userProfile: UserInfo;

    @observable
    imageUrl: string;

    @observable
    isInitializing: boolean = false;

    @computed
    get pinnedProjectIds(): string[] {
        return this.userProfile?.pinnedProjectIds || [];
    }

    constructor(
        public routerStore: RouterStore,
        private service: UserProfileService,
        private adminService: GlobalAdministrationService,
        private errorStore: ErrorStore
    ) {
        this.initialize();
    }

    @action.bound
    logout() {
        this.routerStore.push(ProjectPagesNavigation.ProjectsPage);
        security.inst.logout();
    }

    @action.bound
    setEditUserProfileVisible(isVisible: boolean) {
        this.editUserProfileVisible = isVisible;
    }

    @action.bound
    setResetPasswordDialogVisible(isVisible: boolean) {
        this.resetPasswordDialogVisible = isVisible;
    }

    @action.bound
    setIsInitializing(isInitializing: boolean) {
        this.isInitializing = isInitializing;
    }

    @action
    updateUserProfilePinnedProjects(pinnedProjectIds: string[]) {
        this.userProfile = { ...this.userProfile, pinnedProjectIds };
    }

    async initialize() {
        try {
            this.setIsInitializing(true);
            await this.getUserProfile();
        } finally {
            this.setIsInitializing(false);
        }
    }

    async getUserProfile() {
        const resp = await UserService.getUserInfo();
        this.userProfile = resp;
    }

    @action
    async updateUserProfile(model: UserProfileModel) {
        try {
            const formData = new FormData();
            for (let key in model) {
                if (model[key]) {
                    formData.append(key, model[key]);
                }
            }
            var resp = await this.service.updateUserProfile(formData);
            await resp
                .asyncMap(async () => {
                    await this.getUserProfile();
                    this.userProfile.email = model.email;
                    this.userProfile.firstName = model.firstName;
                    this.userProfile.lastName = model.lastName;
                    this.setEditUserProfileVisible(false);
                })
                .mapErr(err => {
                    const errorMsg = err.data?.title.includes('exists')
                        ? 'User with the same email exists'
                        : err.data?.title;
                    message.error(errorMsg);
                });
        } catch (err) {
            this.errorStore.addBasicError(err);
        }
    }

    @action
    async getAvatarIcon(fileId: string) {
        const resp = await this.service.getAvatarIcon(fileId);
        if (resp) {
            this.imageUrl = URL.createObjectURL(resp);
        }
        return resp;
    }

    async updatePinnedProjects(projectId: string) {
        if (!this.userProfile) {
            return;
        }

        const prevPinnedProjectIds = [...this.pinnedProjectIds];

        const pinnedProjectIds = this.pinnedProjectIds.includes(projectId)
            ? this.pinnedProjectIds.filter(id => id !== projectId)
            : [...this.pinnedProjectIds, projectId];

        this.updateUserProfilePinnedProjects(pinnedProjectIds);

        const resp = await this.service.updatePinnedProjects(pinnedProjectIds);

        if (resp.isOk()) {
            return;
        }

        message.error('Failed to update pinned projects');

        this.updateUserProfilePinnedProjects(prevPinnedProjectIds);
    }

    async removeMissingPinnedProjects(allProjectIds: string[]) {
        const pinnedProjectIds = this.pinnedProjectIds.filter(id => allProjectIds.includes(id));

        if (pinnedProjectIds.length === this.pinnedProjectIds.length) {
            return;
        }

        this.updateUserProfilePinnedProjects(pinnedProjectIds);
        await this.service.updatePinnedProjects(pinnedProjectIds);
    }

    async resetPassword(password: string) {
        const resp = await this.adminService.resetPassword(password);
        if (resp.status === 200) {
            const isPasswordReset = await resp.data;
            if (isPasswordReset) {
                message.success('Password has been successfully reset');
            } else {
                message.error('There were problems resetting your password, please report to administrator');
            }
        }
        this.setResetPasswordDialogVisible(false);
    }

    @action.bound
    async changePassword(newPassword: string, oldPassword: string) {
        try {
            const resp = await this.adminService.changePassword(newPassword, oldPassword);
            if (resp.status === 200) {
                this.setResetPasswordDialogVisible(false);
                message.success('Password has been successfully reset.');
            } else if (resp.status === 401) {
                message.error('Old password is incorrect.');
            } else {
                message.error('There were problems resetting your password, please report to administrator');
            }
        } catch (err) {
            this.errorStore.addBasicError(err);
        }
    }
}

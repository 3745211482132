import { Input, Modal, Button, Form } from 'antd';
import { observer } from 'mobx-react-lite';
import { UserProfileStore } from '../../common/stores';
import * as React from 'react';
import UsersManagerStore from '../../administration/stores/UsersManagerStore';
import { AppPermissions } from '../../authorization/Permissions';
import { HasPermission } from '../../authorization/components/HasPermission';

type Props = {
    store: UserProfileStore | UsersManagerStore;
};

const ResetPasswordDialog: React.FC<Props> = ({ store }) => {
    const [form] = Form.useForm();

    const handleSubmit = () => {
        form.validateFields()
            .then(values => {
                if (values.newPassword !== values.confirmPassword) {
                    form.setFieldsValue({
                        confirmPassword: {
                            value: values.confirmPassword,
                            errors: [new Error('Passwords do not match')]
                        }
                    });
                } else {
                    const user = (store as UsersManagerStore).selectedUser;
                    const userId = user ? user.id : null;
                    store.changePassword(values.newPassword, values.oldPassword, userId);
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const compareToFirstPassword = (rule: any, value: any, callback: any) => {
        if (value && value !== form.getFieldValue('newPassword')) {
            callback('Passwords should match!');
        } else {
            callback();
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const compareOldToNewPassword = (rule: any, value: any, callback: any) => {
        if (value && value === form.getFieldValue('oldPassword')) {
            callback('Old password cannot be the same as new password!');
        } else {
            callback();
        }
    };

    const handleCancel = () => {
        store!.setResetPasswordDialogVisible(false);
    };

    const getCurrentUserFullName = () => {
        if (store instanceof UserProfileStore) {
            const { userProfile } = store;
            if (!userProfile) {
                return '';
            }
            let userName =
                userProfile.firstName || userProfile.lastName
                    ? `${userProfile.firstName} ${userProfile.lastName}`
                    : userProfile.email;
            return userName;
        } else if (store instanceof UsersManagerStore) {
            const { selectedUser } = store;
            if (!selectedUser) {
                return '';
            }
            let userName =
                selectedUser.firstName || selectedUser.lastName
                    ? `${selectedUser.firstName} ${selectedUser.lastName}`
                    : selectedUser.email;
            return userName;
        }
        return '';
    };

    return (
        <Modal
            title={`Reset password for ${getCurrentUserFullName()}`}
            className="alpha-portal-dialog"
            visible={store!.resetPasswordDialogVisible}
            onCancel={handleCancel}
            maskClosable={false}
            destroyOnClose
            centered
            footer={[
                <Button
                    className="light"
                    data-id="iota-add-connection-dialog-cancel"
                    key="back"
                    onClick={handleCancel}
                    size="large"
                >
                    Cancel
                </Button>,
                <Button
                    data-id="iota-add-connection-dialog-submit"
                    key="submit"
                    type="primary"
                    onClick={handleSubmit}
                    size="large"
                >
                    Reset Password
                </Button>
            ]}
        >
            <Form form={form} layout="vertical">
                <HasPermission
                    permissionClaim={AppPermissions.CanManageUsers}
                    no={() => (
                        <Form.Item
                            label="Old password"
                            name="oldPassword"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input password!'
                                }
                            ]}
                        >
                            <Input.Password
                                size="large"
                                iconRender={visible =>
                                    visible ? (
                                        <span style={{ height: 22 }}>
                                            <i className="alpha-icon lg eye-visible" />
                                        </span>
                                    ) : (
                                        <span style={{ height: 22 }}>
                                            <i className="alpha-icon lg eye-invisible" />
                                        </span>
                                    )
                                }
                            />
                        </Form.Item>
                    )}
                />
                <Form.Item
                    label="New password"
                    name="newPassword"
                    rules={[
                        {
                            required: true,
                            message: 'Please input password!'
                        },
                        {
                            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/gm,
                            message:
                                'Password should beat least 6 characters long and contain at least one number, upper case and lower case character.'
                        },
                        {
                            validator: compareOldToNewPassword
                        }
                    ]}
                >
                    <Input.Password
                        size="large"
                        iconRender={visible =>
                            visible ? (
                                <span style={{ height: 22 }}>
                                    <i className="alpha-icon lg eye-visible" />
                                </span>
                            ) : (
                                <span style={{ height: 22 }}>
                                    <i className="alpha-icon lg eye-invisible" />
                                </span>
                            )
                        }
                    />
                </Form.Item>
                <Form.Item
                    label="Confirm password"
                    name="confirmPassword"
                    rules={[
                        {
                            required: true,
                            message: 'Please input password!'
                        },
                        {
                            validator: compareToFirstPassword
                        }
                    ]}
                >
                    <Input.Password
                        size="large"
                        iconRender={visible =>
                            visible ? (
                                <span style={{ height: 22 }}>
                                    <i className="alpha-icon lg eye-visible" />
                                </span>
                            ) : (
                                <span style={{ height: 22 }}>
                                    <i className="alpha-icon lg eye-invisible" />
                                </span>
                            )
                        }
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default observer(ResetPasswordDialog);

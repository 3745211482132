import { RootStores } from '../../common/stores';
import { STORE_PROJECTS_ROOT_UI, STORE_ROUTER, STORE_USERS } from '../../common/constants';
import {
    STORE_PROMPT_TEMPLATE_EDIT,
    STORE_PROMPT_TEMPLATES,
    STORE_PROMPT_TEMPLATES_IMPORT_EXPORT,
    STORE_PROMPT_DEPENDENT_PACKAGES
} from './constants';
import { AdministrationService } from '../../project_management/services/AdministrationService';
import PromptTemplateStore from './PromptTemplatesStore';
import EditPromptTemplateStore from './EditPromptTemplatesStore';
import PromptTemplateService from '../services/PromptTemplatesService';
import PromptTemplateImportExportStore from './PromptTemplateImportExportStore';
import PropmtDependentPackagesStore from './PropmtDependentPackagesStore';

export { default as PromptTemplateStore } from './PromptTemplatesStore';
export { default as EditPromptTemplateStore } from './EditPromptTemplatesStore';
export { default as PromptTemplateImportExportStore } from './PromptTemplateImportExportStore';
export { default as PropmtDependentPackagesStore } from './PropmtDependentPackagesStore';

export function injectPromptTemplateStores(obj: RootStores) {
    const service = new PromptTemplateService();
    const administrationService = new AdministrationService();
    obj[STORE_PROMPT_TEMPLATES] = new PromptTemplateStore(
        obj[STORE_PROJECTS_ROOT_UI],
        obj[STORE_ROUTER],
        obj[STORE_USERS],
        service
    );
    obj[STORE_PROMPT_TEMPLATE_EDIT] = new EditPromptTemplateStore(
        obj[STORE_PROJECTS_ROOT_UI],
        obj[STORE_PROMPT_TEMPLATES],
        service
    );
    obj[STORE_PROMPT_TEMPLATES_IMPORT_EXPORT] = new PromptTemplateImportExportStore(
        obj[STORE_PROJECTS_ROOT_UI],
        obj[STORE_PROMPT_TEMPLATES],
        service
    );
    obj[STORE_PROMPT_DEPENDENT_PACKAGES] = new PropmtDependentPackagesStore(
        obj[STORE_PROJECTS_ROOT_UI],
        obj[STORE_PROMPT_TEMPLATE_EDIT],
        service,
        administrationService
    );
}

export type PromptTemplateStores = {
    [STORE_PROMPT_TEMPLATES]?: PromptTemplateStore;
    [STORE_PROMPT_TEMPLATE_EDIT]?: EditPromptTemplateStore;
    [STORE_PROMPT_TEMPLATES_IMPORT_EXPORT]?: PromptTemplateImportExportStore;
    [STORE_PROMPT_DEPENDENT_PACKAGES]?: PropmtDependentPackagesStore;
};

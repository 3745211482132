import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Select, Form } from 'antd';
import InstructWorkflowCreateEditStore from '../../stores/InstructWorkflowCreateEditStore';
import InstructWorkflowModel from '../../model/InstructWorkflowModel';

interface Props {
    store: InstructWorkflowCreateEditStore;
    workflow?: InstructWorkflowModel;
}

const WorkflowTypeSelect: React.FC<Props> = ({ store, workflow }) => {
    return (
        <Form.Item
            label="Workflow type"
            name="workflowType"
            initialValue={workflow && workflow.workflowType ? workflow.workflowType.type : null}
            help={workflow && workflow.workflowType ? workflow.workflowType.description : null}
            rules={[{ required: true, message: 'Please select workflow type!' }]}
        >
            <Select
                loading={store.isLoading}
                placeholder="Select workflow type..."
                options={store.workflowTypeSelectOptions}
                disabled={store.isLoading || !!(workflow && !workflow.isNew)}
                allowClear
            />
        </Form.Item>
    );
};

export default observer(WorkflowTypeSelect);

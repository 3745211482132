import * as React from 'react';
import { runInAction } from 'mobx';
import { observer, useLocalStore } from 'mobx-react-lite';
import { v4 as uuidv4 } from 'uuid';
import { Form, Input, Button, Modal, Select } from 'antd';
import { RulePreviewVisualStore } from '../../stores';
import { PromptTemplate } from '../../../prompt_templates/types';
import SplitterLayout from 'react-splitter-layout';
import PromptTemplateService from '../../../prompt_templates/services/PromptTemplatesService';
import ProjectPackageFilter from '../ProjectPackageFilter';
import RulePreviewForm from '../RulePreviewForm/RulePreviewForm';
import './PromptEditorModal.less';

type PreviewPromptFormValues = {
    previewPromptTemplateId?: string;
    previewPrompt?: string;
};

const formFields: Record<keyof PreviewPromptFormValues, string> = {
    previewPromptTemplateId: 'previewPromptTemplateId',
    previewPrompt: 'previewPrompt'
};

interface Props {
    previewPromptFormId: string;
    modalVisible: boolean;
    projectId: string;
    initialPrompt: string;
    previewStore: RulePreviewVisualStore;
    handleApplyChanges: (prompt: unknown) => void;
    handlePromptPreview: (prompt: unknown) => void;
    handleClose: () => void;
}

const PromptEditorModal: React.FC<Props> = ({
    previewPromptFormId,
    modalVisible,
    projectId,
    initialPrompt,
    previewStore,
    handleApplyChanges,
    handlePromptPreview,
    handleClose
}: Props) => {
    const [previewPromptForm] = Form.useForm<PreviewPromptFormValues>();

    const localStore = useLocalStore(() => ({
        modalKey: uuidv4(),
        promptTemplatesLoading: false,
        promptTemplates: new Array<PromptTemplate>(),
        promptTemplatesService: new PromptTemplateService(),
        innerPanelSize: 30,
        outerPanelSize: 30,
        setModalKey(modalKey: string) {
            localStore.modalKey = modalKey;
        },
        setInnerPanelSize(innerPanelSize: number) {
            localStore.innerPanelSize = innerPanelSize;
        },
        setOuterPanelSize(outerPanelSize: number) {
            localStore.outerPanelSize = outerPanelSize;
        },
        async loadPromptTemplates() {
            runInAction(() => {
                localStore.promptTemplatesLoading = true;
            });

            const promptTemplates = await this.promptTemplatesService.getPromptTemplates(projectId);

            runInAction(() => {
                localStore.promptTemplates = promptTemplates;
                localStore.promptTemplatesLoading = false;
            });
        }
    }));

    const handleValuesChange = (changedValues: PreviewPromptFormValues) => {
        if (!changedValues.previewPromptTemplateId) {
            return;
        }

        const promptTemplate = localStore.promptTemplates.find(t => t.id === changedValues.previewPromptTemplateId);

        if (promptTemplate) {
            previewPromptForm.setFieldsValue({ previewPrompt: promptTemplate.body });
        }
    };

    const initializeEditor = React.useCallback(() => {
        previewPromptForm.setFieldsValue({ [formFields.previewPrompt]: initialPrompt });
    }, [initialPrompt, previewPromptForm]);

    const resetEditor = React.useCallback(() => {
        previewPromptForm.resetFields();
        previewStore.reset();
    }, [previewStore, previewPromptForm]);

    React.useEffect(() => {
        if (modalVisible) {
            initializeEditor();
        } else {
            resetEditor();
        }
    }, [modalVisible, initializeEditor, resetEditor]);

    React.useEffect(() => {
        localStore.loadPromptTemplates();
    }, [localStore]);

    // When switching between tabs rule is cloned and store needs to be recreated, so we need to reset modal key to unmount the components.
    React.useEffect(() => {
        localStore.setModalKey(uuidv4());
    }, [localStore, previewStore]);

    return (
        <Modal
            key={localStore.modalKey}
            title="Edit prompt"
            className="prompt-editor-modal"
            visible={modalVisible}
            maskClosable={false}
            closable={false}
            centered
            footer={[
                <Button key="cancel-button" className="light alpha-dialog-button" onClick={handleClose}>
                    Cancel
                </Button>,
                <Button
                    key="save-button"
                    type="primary"
                    onClick={() => {
                        handleApplyChanges(previewPromptForm.getFieldValue(formFields.previewPrompt));
                    }}
                >
                    Apply changes
                </Button>
            ]}
        >
            <SplitterLayout
                percentage
                secondaryInitialSize={localStore.outerPanelSize}
                onSecondaryPaneSizeChange={localStore.setOuterPanelSize}
            >
                <SplitterLayout
                    percentage
                    primaryIndex={1}
                    secondaryInitialSize={localStore.innerPanelSize}
                    onSecondaryPaneSizeChange={localStore.setInnerPanelSize}
                >
                    <ProjectPackageFilter className="package-filter" RulePreviewUI={previewStore} />

                    <div className="prompt-container">
                        <Form
                            id={previewPromptFormId}
                            layout="vertical"
                            form={previewPromptForm}
                            onValuesChange={handleValuesChange}
                            onFinish={() => {
                                handlePromptPreview(previewPromptForm.getFieldValue(formFields.previewPrompt));
                            }}
                        >
                            <Form.Item label="Prompt template" name={formFields.previewPromptTemplateId}>
                                <Select
                                    placeholder="Select prompt template"
                                    loading={localStore.promptTemplatesLoading}
                                    allowClear
                                >
                                    {localStore.promptTemplates.map(t => (
                                        <Select.Option key={t.id} value={t.id}>
                                            {t.title}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Prompt"
                                name={formFields.previewPrompt}
                                className="preview-prompt-form-item"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input prompt!'
                                    }
                                ]}
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Form>
                        <Button
                            form={previewPromptFormId}
                            className="preview-prompt-button"
                            type="primary"
                            htmlType="submit"
                            disabled={!previewStore.checkedPackages.length || previewStore.isExecuting}
                        >
                            Preview prompt
                        </Button>
                    </div>
                </SplitterLayout>

                <div className="results-container">
                    <RulePreviewForm RulePreviewUI={previewStore} project={previewStore.project} renderTreeViewOnly />
                </div>
            </SplitterLayout>
        </Modal>
    );
};

export default observer(PromptEditorModal);
